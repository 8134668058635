import * as React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Editable from '../../../core/Editable'

export default function OptInCheckbox ({ onOptInChange }) {
  const [isOptIn, setIsOptIn] = React.useState(JSON.parse(localStorage.getItem('optIn')))

  const handleOptInChange = () => {
    setIsOptIn(!isOptIn)
    onOptInChange(isOptIn)
  }

  return (
    <FormControlLabel
      control={<Checkbox value="opt_in" color="primary" onChange={handleOptInChange} checked={isOptIn}/>}
      label={
        <Editable
          translationKey={`users:fields:opt_in`}
          defaultText={"I want to receive updates via email."}
        />}
    />
  )
}