import * as React from 'react'
import Container from '@mui/material/Container'
import { useParams } from 'react-router-dom'
import LoggingRequired from '../../core/LoggingRequired'
import MediaContractorComponent from './MediaContractorComponent'

export default function MediaContractor () {
  let { contractorId } = useParams()

  return (
    <Container component="main" maxWidth="lg" sx={{ marginBottom: '80px' }}>
      <LoggingRequired/>
      <MediaContractorComponent contractorId={contractorId}/>
    </Container>
  )
}