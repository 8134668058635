import Container from '@mui/material/Container'
import React from 'react'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@mui/material'
import Grid from '@mui/material/Grid'
import Editable from './Editable'

function Copyright (props) {
  return (
    <>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link component={RouterLink} color="inherit" to={'/'}>
          Recal
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </>
  )
}

export default function Footer () {

  let i = 0
  return (

    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mb: 3
      }}
    >
      <Grid container sx={{ textAlign: 'center', mt: 2 }}>
        <Grid item xs={12} md={4}>
          <Link component={RouterLink} color="inherit" to={'/legal'}>
            <Editable
              translationKey={`footer:content${++i}`}
              defaultText={'Terms of use'}
            />
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Copyright/>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link component={RouterLink} color="inherit" to={'/contact'}>
            <Editable
              translationKey={`footer:content${++i}`}
              defaultText={'Contact us'}
            />
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}