import * as React from 'react'
import TextField from '@mui/material/TextField'
import { Validate } from 'mui-validate'
import Editable from '../../../core/Editable'

export default function NameField ({ value }) {
  const [updated, setUpdated] = React.useState(value ? value : '')
  const handleChange = (event) => {
    setUpdated(event.target.value)
  }
  let i = 0
  return (
    <Validate name="name" required>
      <TextField
        name="name"
        fullWidth
        id="name"
        label={
          <Editable
            translationKey={`property:name:content${++i}`}
            defaultText={'Give a name to your property'}
          />}
        value={updated}
        onChange={handleChange}
      />
    </Validate>
  )
}