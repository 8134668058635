import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./core/App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as PropTypes from "prop-types";
import Properties from "./components/properties/Properties";
import Messages from "./components/users/Messages";
import Property from "./components/properties/Property";
import SignIn from "./components/users/SignIn";
import SignUp from "./components/users/SignUp";
import Pricing from "./components/home/Pricing";
import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./core/Footer";
import Header from "./core/Header";
import Profile from "./components/users/Profile";
import Contact from "./components/home/Contact";
import AddProperty from "./components/properties/AddProperty";
import MediaProperty from "./components/properties/MediaProperty";
import Calendar from "./components/properties/Calendar";
import Error404 from "./core/Error404";
import Thread from "./components/users/Thread";
import Demo from "./components/home/Demo";
import Functionalities from "./components/home/Functionalities";
import { IntlProvider } from "react-intl";
import ForgotPassword from "./components/users/ForgotPassword";
import ResetPassword from "./components/users/ResetPassword";
import Contractors from "./components/contractors/Contractors";
import AddContractor from "./components/contractors/AddContractor";
import Contractor from "./components/contractors/Contractor";
import MediaContractor from "./components/contractors/MediaContractor";
import Brochure from "./components/properties/Brochure";
import Cookie from "./core/Cookie";
import Legal from "./core/Legal";
import Faq from "./core/Faq";
import Privacy from "./core/Privacy";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Switch(props) {
  return null;
}

function Skeleton() {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const theme = createTheme({
    typography: {
      fontFamily: "Inter",
    },
    palette: {
      primary: {
        light: "#85E5C4",
        main: "#3E9389",
        dark: "black",
      },
      secondary: {
        light: "#85E5C4",
        main: "#6BC8AD",
        dark: "#4AA390",
      },
      background: {
        default: "#FCFCFC",
      },
      error: {
        main: "#000000",
      },
      info: {
        main: "#5a5a5a",
      },
      success: {
        main: "#007871",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>RECAL - Share your property availability calendars</title>
        <meta
          name="description"
          content="FREE tool to manage your bookings and promote your property with style!"
        />
        <meta
          property="og:title"
          content="RECAL - Share your property availability calendars"
        />
        <meta
          property="og:description"
          content="FREE tool to manage your bookings and promote your property with style!"
        />
        <meta
          property="og:image"
          content="https://recal-properties2.s3.eu-north-1.amazonaws.com/recal-logo.png"
        />
        <meta property="og:url" content="https://www.recal-rent.com" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.recal-rent.com" />
        <meta
          property="twitter:title"
          content="RECAL - Share your property availability calendars"
        />
        <meta
          property="twitter:description"
          content="FREE tool to manage your bookings and promote your property with style!"
        />
        <meta
          property="twitter:image"
          content="https://recal-properties2.s3.eu-north-1.amazonaws.com/recal-logo.png"
        />
      </Helmet>
      <IntlProvider locale="en">
        <BrowserRouter>
          <React.Fragment>
            <GlobalStyles
              styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
            />
            <CssBaseline />
            <Header />

            <Routes>
              <Route exact path="/" element={<App />} />
              <Route exact path="/demo" element={<Demo />} />
              <Route
                exact
                path="/functionalities"
                element={<Functionalities />}
              />
              <Route
                exact
                path="/sign-in"
                element={<SignIn setToken={setToken} />}
              />
              <Route exact path="/sign-up" element={<SignUp />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/pricing" element={<Pricing />} />
              <Route exact path="/privacy" element={<Privacy />} />
              <Route exact path="/faq" element={<Faq />} />
              <Route exact path="/legal" element={<Legal />} />
              <Route exact path="/cookie" element={<Cookie />} />
              <Route exact path="/contact" element={<Contact />} />

              <Route exact path="/properties" element={<Properties />} />
              <Route exact path="/add-property" element={<AddProperty />} />
              <Route
                path="/edit-property/:propertyId/:propertyName"
                element={<AddProperty />}
              />
              <Route
                path="/media-property/:propertyId/:propertyName"
                element={<MediaProperty />}
              />
              <Route
                path="/property/:propertyId/:propertyName"
                element={<Property />}
              />
              <Route
                path="/brochure/:propertyId/:propertyName"
                element={<Brochure />}
              />

              <Route exact path="/services" element={<Contractors />} />
              <Route exact path="/add-contractor" element={<AddContractor />} />
              <Route
                path="/edit-contractor/:contractorId"
                element={<AddContractor />}
              />
              <Route
                path="/media-contractor/:contractorId"
                element={<MediaContractor />}
              />
              <Route
                path="/contractor/:contractorId"
                element={<Contractor />}
              />

              <Route exact path="/messages" element={<Messages />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route
                exact
                path="/new-thread/:recipientId"
                element={<Thread />}
              />
              <Route exact path="/thread/:threadId" element={<Thread />} />

              <Route path="*" element={<Error404 />} />
            </Routes>

            <Footer />
          </React.Fragment>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  );
}

Switch.propTypes = { children: PropTypes.node };
root.render(<Skeleton />);
