import * as React from "react";
import { useState } from "react";
import { Validate } from "mui-validate";
import TextField from "@mui/material/TextField";
import Editable from "../../../core/Editable";

export default function FirstNameField({ profile }) {
  const [value, setValue] = useState(localStorage.getItem("firstname"));
  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };
  return (
    <Validate name="firstname">
      <TextField
        name="first_name"
        required
        fullWidth
        id="firstName"
        label={
          <Editable
            translationKey={`users:fields:first_name`}
            defaultText={"First Name"}
          />
        }
        autoComplete="given-name"
        value={profile ? value : undefined}
        onChange={handleChange}
      />
    </Validate>
  );
}
