export default function extractFormDataToObject(event){
  const formData = new FormData(event.currentTarget)
  const data = {}
  const names = []
  // If you need to touch this horror, I'm truly sorry and hope I'm not the one reading this message.
  for (let p of formData) {
    if (p[0].includes('[')) {
      let value = p[0].substring(
        p[0].indexOf('[') + 1,
        p[0].lastIndexOf(']')
      )
      let key = p[0].substring(0, p[0].indexOf('['))
      if (key in data) {
        data[key].push(value)
      } else {
        data[key] = [value]
      }
    } else if (names.includes(p[0])) {
      if (Array.isArray(data[p[0]])) {
        data[p[0]].push(p[1])
      } else {
        if (data[p[0]] === p[1]) {
          continue
        }
        data[p[0]] = [data[p[0]], p[1]]
      }
    } else {
      data[p[0]] = p[1]
      names.push(p[0])
    }
  }
  return data
}