import React, { useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import BrochurePdf from "./pdf/BrochurePdf";
import Container from "@mui/material/Container";
import { getData, getProperty } from "../../core/client";
import { BASE_IMAGE, slugify } from "../../core/default";
import { Link as RouterLink, Navigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Editable from "../../core/Editable";
import FullLoader from "../../core/FullLoader";
import PropertyMap from "./PropertyMap";
import Box from "@mui/material/Box";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

export default function Brochure() {
  const [property, setProperty] = React.useState();
  const [font, setFont] = React.useState("Roboto");
  const [anonymous, setAnonymous] = React.useState(false);
  const [watermark, setWatermark] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mapImage, setMapImage] = React.useState();
  const [options, setOptions] = React.useState(() => []);
  let { propertyId } = useParams();
  let { propertyName } = useParams();

  setTimeout(() => {
    setLoaded(true);
    window.scrollTo(0, 0);
  }, 4000);

  const handleOptions = (event, newOptions) => {
    setOptions(newOptions);
  };

  const regenerateBrochure = () => {
    setWatermark(!options.includes("removeLogo"));
    setAnonymous(options.includes("anonymous"));
    setFont(options.includes("alternativeFont") ? "Bodoni" : "Roboto");
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  useEffect(() => {
    getProperty(propertyId).then((data) => {
      if (data.media.length === 0) {
        data.media = [
          {
            url: BASE_IMAGE,
          },
        ];
      }
      let property = data;
      getData(`/users/info/${property.owner_id}`).then((response) => {
        property.owner = response.data;
        setProperty(property);
      });
    });
  }, [propertyId]);

  let i = 0;
  let j = 0;
  let k = 0;
  return (
    <>
      {property && propertyName !== slugify(property.name) && (
        <Navigate replace to={`/properties`} />
      )}
      {!loaded && property && property.media.length > 3 && (
        <FullLoader
          message={
            <Editable
              translationKey={`brochure:loader:content${++j}`}
              defaultText={
                "Please wait while RECAL is generating your brochure..."
              }
            />
          }
        />
      )}
      {!loaded && property && property.media.length > 3 && (
        <Box sx={{ width: "1280px", height: "720px" }}>
          <PropertyMap
            longitude={property.longitude}
            latitude={property.latitude}
            mapStyle={"street"}
            preserveDrawingBuffer={true}
            setMapImage={setMapImage}
          />
        </Box>
      )}
      <Container component="main" maxWidth="xl" sx={{ height: "110vh", my: 5 }}>
        {property && (
          <Button
            variant={"outlined"}
            sx={{ mr: "10px", float: "left" }}
            size="small"
            component={RouterLink}
            to={`/property/${propertyId}/${slugify(property.name)}`}
          >
            <Editable
              translationKey={`brochure:content${++i}`}
              defaultText={"Back"}
            />
          </Button>
        )}
        <Box sx={{ textAlign: "center", mb: "10px" }}>
          <ToggleButtonGroup
            value={options}
            onChange={handleOptions}
            aria-label="brochure options"
            size={"small"}
          >
            <ToggleButton value="anonymous" aria-label="anonymous">
              <Editable
                translationKey={`brochure:content${++i}`}
                defaultText={"Make my brochure anonymous"}
              />
            </ToggleButton>
            <ToggleButton value="removeLogo" aria-label="removeLogo">
              <Editable
                translationKey={`brochure:content${++i}`}
                defaultText={"Remove the RECAL watermark"}
              />
            </ToggleButton>
            <ToggleButton value="alternativeFont" aria-label="alternativeFont">
              <Editable
                translationKey={`brochure:content${++i}`}
                defaultText={"Use an alternative font"}
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ textAlign: "center", my: "10px" }}>
          <LoadingButton
            sx={{ my: "10px" }}
            loading={loading}
            loadingPosition="start"
            size={"small"}
            startIcon={<AutoFixHighIcon />}
            variant="contained"
            onClick={regenerateBrochure}
          >
            <Editable
              translationKey={`brochure:content${++i}`}
              defaultText={"Regenerate brochure"}
            />
          </LoadingButton>
        </Box>
        {property && property.media.length > 3 && mapImage && !loading && (
          <PDFViewer
            style={{
              width: "100%",
              height: "90vh",
            }}
            rerender={loading.toString()}
          >
            <BrochurePdf
              property={property}
              mapImage={mapImage}
              font={font}
              watermark={watermark}
              anonymous={anonymous}
            />
          </PDFViewer>
        )}
        {property && property.media.length <= 3 && (
          <Box sx={{ textAlign: "center", mt: "100px" }}>
            <Typography variant="h3" sx={{ maxWidth: "700px", margin: "auto" }}>
              <Editable
                translationKey={`brochure:error:content${++k}`}
                defaultText={
                  "You need at least 4 images to generate a brochure."
                }
              />
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
}
