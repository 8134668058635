import axios from "axios";
import configData from "../config.json";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryCondition: () => true,
  retryDelay: 500,
});

export const isLogged = () => {
  return (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== "null"
  );
};

export const sendData = async (url, data, method) => {
  const instance = axios.create({
    baseURL: configData["API_URL"],
    timeout: 600000,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
  try {
    return await instance({
      method: method,
      url: url,
      data: data,
    });
  } catch (error) {
    if (error.response.status === 422) {
      error.response.data.detail.forEach((element) => console.error(element));
    }
    if (error.response.status === 409) {
      console.error(error.response.data.detail);
    }
    if (error.response.status === 401 && url !== "/users/auth/token") {
      localStorage.setItem("token", null);
    }
    return error.response.status;
  }
};

export const getData = async (url) => {
  const instance = axios.create({
    baseURL: configData["API_URL"],
    timeout: 600000,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
  try {
    return await instance.get(url);
  } catch (error) {
    console.error(error);
    if (error.response.status === 401) {
      localStorage.setItem("token", null);
    }
    return error.response.status;
  }
};

export const deleteData = async (url) => {
  const instance = axios.create({
    baseURL: configData["API_URL"],
    timeout: 600000,
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
  try {
    return await instance.delete(url);
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.setItem("token", null);
    }
    return error.response.status;
  }
};

//https://api.geoapify.com/v1/geocode/autocomplete?text=Mosco&apiKey=998a01145b174e0daeeb9e83214ef1f7

export const getGeoData = async (url, params) => {
  const instance = axios.create({
    baseURL: configData["GEO_API_URL"],
    timeout: 10000,
  });
  params["apiKey"] = configData["GEO_API_KEY"];
  try {
    return await instance.get(url, { params: params });
  } catch (error) {
    return error.response.status;
  }
};

export async function getProperty(propertyId) {
  let locale = localStorage.getItem("locale");
  return await getData(`/properties/${propertyId}?locale=${locale}`).then(
    (response) => {
      return response.data;
    }
  );
}

export async function getContractor(contractorId) {
  let locale = localStorage.getItem("locale");
  return await getData(`/contractors/${contractorId}?locale=${locale}`).then(
    (response) => {
      return response.data;
    }
  );
}
