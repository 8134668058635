import React, { useState, useEffect } from "react";

function LazyBackground(props) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const { src } = props;

    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      setSrc(src);
    };

    return () => {
      imageLoader.onload = null;
    };
  }, [props.src]);

  return (
    <div
      {...props}
      style={{
        backgroundImage: `url("${src || props.placeholder}")`,
        width: props.width,
        height: props.height,
        backgroundSize: "cover",
      }}
    />
  );
}

export default LazyBackground;
