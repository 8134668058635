import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import { sendData } from "../../core/client";
import { Navigate, useSearchParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import PasswordField from "./input/PasswordField";
import { useEffect } from "react";
import Editable from "../../core/Editable";
import home from "../../static/images/home.png";

export default function ResetPassword() {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    localStorage.setItem("token", searchParams.get("token"));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {};
    for (let p of formData) {
      let name = p[0];
      data[name] = p[1];
    }
    let response = await sendData("/users/reset-password", data, "post");
    if (!isNaN(response)) {
      if (response === 401) {
        setErrorMessage("Your token has expired.");
      } else {
        setErrorMessage(
          "We have a problem. This is on our end, please try again later."
        );
      }
    } else {
      localStorage.setItem("token", null);
      setSuccess(true);
    }
  };

  let i = 0;

  return (
    <Grid container component="main" sx={{ minHeight: "83vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(" + home + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {success && <Navigate replace to="/sign-in" />}
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, mt: 5, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Editable
              translationKey={`reset-password:content${++i}`}
              defaultText={"Reset password"}
            />
          </Typography>
          <ValidationGroup>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 5, minWidth: "400px" }}
            >
              <Typography
                component="p"
                variant="body1"
                sx={{ marginBottom: "10px" }}
              >
                <Editable
                  translationKey={`reset-password:content${++i}`}
                  defaultText={"Please choose a new password to sign in with."}
                />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <PasswordField />
                </Grid>
              </Grid>
              <AutoDisabler firstDisplayErrors={true}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <Editable
                    translationKey={`reset-password:content${++i}`}
                    defaultText={"Reset my password"}
                  />
                </Button>
              </AutoDisabler>
              {errorMessage !== "" && (
                <Alert sx={{ mt: 1, mb: 2 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              {success && (
                <Alert sx={{ mt: 1, mb: 2 }} severity="info">
                  <Editable
                    translationKey={`reset-password:content${++i}`}
                    defaultText={"Your password was successfully changed."}
                  />
                </Alert>
              )}
            </Box>
          </ValidationGroup>
        </Box>
      </Grid>
    </Grid>
  );
}
