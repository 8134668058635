import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Alert, Link } from "@mui/material";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import { sendData } from "../../core/client";
import { Link as RouterLink } from "react-router-dom";
import EmailField from "./input/EmailField";
import Paper from "@mui/material/Paper";
import Editable from "../../core/Editable";
import home from "../../static/images/home.png";

export default function ForgotPassword() {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {};
    for (let p of formData) {
      let name = p[0];
      data[name] = p[1];
    }
    data["host"] = window.location.protocol + "//" + window.location.host;
    let response = await sendData("/users/forgot-password", data, "post");
    if (!isNaN(response)) {
      if (response === 404) {
        setErrorMessage("We found no user matching this email address.");
      } else {
        setErrorMessage(
          "We have a problem. This is on our end, please try again later."
        );
      }
    } else {
      console.log("User successfully created, redirected towards login.");
      setSuccess(true);
    }
  };

  let i = 0;
  let j = 0;
  return (
    <Grid container component="main" sx={{ minHeight: "83vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(" + home + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, mt: 5, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Editable
              translationKey={`forgot-password:content${++i}`}
              defaultText={"Forgotten password"}
            />
          </Typography>
          <ValidationGroup>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 5 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EmailField profile={false} />
                </Grid>
              </Grid>
              <AutoDisabler firstDisplayErrors={true}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <Editable
                    translationKey={`forgot-password:content${++i}`}
                    defaultText={"Reset my password"}
                  />
                </Button>
              </AutoDisabler>
              {errorMessage !== "" && (
                <Alert sx={{ mt: 1, mb: 2 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              {success && (
                <Alert sx={{ mt: 1, mb: 2 }} severity="info">
                  <Editable
                    translationKey={`forgot-password:content2${++j}`}
                    defaultText={
                      "We just sent you a mail so you can choose a new password."
                    }
                  />
                </Alert>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link component={RouterLink} to="/sign-up">
                    <Editable
                      translationKey={`forgot-password:content${++i}`}
                      defaultText={
                        "You do not have an account? Click here to sign up."
                      }
                    />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </ValidationGroup>
        </Box>
      </Grid>
    </Grid>
  );
}
