import * as React from 'react'
import Box from '@mui/material/Box'
import { Dropzone, FileItem } from '@dropzone-ui/react'
import ImageIcon from '@mui/icons-material/Image';

export default function MediaField ({ files, setFiles, maxFiles }) {

  const updateFiles = (incomingFiles) => {
    setFiles(incomingFiles)
  }

  return (
    <Box sx={{position:'relative'}}>
      <ImageIcon sx={{
        position: 'absolute',
        zIndex: '3',
        top: '35%',
        fontSize: '100px',
        color: 'lightGray',
        pointerEvents: 'none',
        m: 'auto',
        left: 0,
        right: 0
      }}/>
      <Dropzone
        style={{ width: '100%' }}
        onChange={updateFiles} value={files}
        maxFiles={maxFiles}
        maxFileSize={524290000}
        accept="image/*"
        uploadingMessage={'Uploading...'}
      >
        {files.map((file, index) => (
          <FileItem key={index} {...file} preview/>
        ))}
      </Dropzone>
    </Box>
  )
}