import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { getData, sendData } from "./client";

export default function Editable({ translationKey, defaultText, size }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [translationText, setTranslationText] = React.useState("");
  const [keys, setKeys] = React.useState("");
  const [locale] = React.useState(localStorage.getItem("locale"));
  const [translation, setTranslation] = React.useState("");

  if (!size) {
    size = "big";
  }

  const handleClickOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
  };

  const handleTextFieldClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const confirmValue = async (event) => {
    event.preventDefault();
    let translationText = translation;
    for (let i = 0; i < keys.length - 1; i++) {
      if (!translationText.hasOwnProperty(keys[i])) {
        translationText[keys[i]] = {};
      }
      translationText = translationText[keys[i]];
    }
    translationText[keys[keys.length - 1]] = value;
    await sendData(
      `/translations/${locale}`,
      { content: JSON.stringify(translation) },
      "post"
    );
    let timedTranslations = { value: translation, timestamp: new Date() };
    localStorage.setItem(
      `translation_${locale}`,
      JSON.stringify(timedTranslations)
    );
    window.location.reload();
  };

  useEffect(() => {
    let locale = localStorage.getItem("locale");
    let tmp = translationKey.split(":");
    setKeys(tmp);
    let savedTranslations = localStorage.getItem(`translation_${locale}`);
    if (savedTranslations) {
      let object = JSON.parse(savedTranslations),
        date = new Date(object.timestamp),
        now = new Date();
      if (
        Math.ceil((now.getTime() - date.getTime()) / (1000 * 3600 * 24)) < 2
      ) {
        setTranslation(object.value);
        let translationText = object.value;
        for (let key of tmp) {
          translationText = translationText[key];
          if (!translationText) {
            translationText = defaultText;
          }
        }
        setValue(translationText);
        setTranslationText(translationText);
        return;
      }
    }
    getData(`/translations/${locale}`).then((response) => {
      let tmp2 = response.data;
      setTranslation(response.data);
      let timedTranslations = { value: response.data, timestamp: new Date() };
      localStorage.setItem(
        `translation_${locale}`,
        JSON.stringify(timedTranslations)
      );
      let translationText = tmp2;
      try {
        for (let key of tmp) {
          translationText = translationText[key];
        }
        setValue(translationText);
        setTranslationText(translationText);
        window.location.reload();
      } catch (error) {
        console.error(`No translation found for ${translationKey}`);
      }
    });
  }, [defaultText, translationKey]);

  return (
    <>
      {!translationText && (
        <span dangerouslySetInnerHTML={{ __html: defaultText }} />
      )}
      {translationText && (
        <>
          <span dangerouslySetInnerHTML={{ __html: translationText }} />
          {localStorage.getItem("optinsms") === "true" && (
            <Box sx={{ display: "inline" }}>
              <IconButton
                color="black"
                aria-label="edit"
                component="label"
                onClick={handleClickOpen}
              >
                <EditIcon />
              </IconButton>
              <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={size === "big"}
                maxWidth={"lg"}
              >
                <DialogTitle>Edit value</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Enter the new value for this text here.
                  </DialogContentText>
                  <TextField
                    name="content"
                    required
                    fullWidth
                    id="content"
                    label="Enter your content"
                    autoFocus
                    multiline
                    value={value}
                    rows={size === "big" ? 30 : 3}
                    onChange={handleChange}
                    onClick={handleTextFieldClick}
                  />
                </DialogContent>
                <DialogActions>
                  <Button sx={{ zIndex: 5 }} onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button sx={{ zIndex: 5 }} onClick={confirmValue}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </>
      )}
    </>
  );
}
