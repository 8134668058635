import * as React from "react";
import { Validate } from "mui-validate";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import Typography from "@mui/material/Typography";
import Editable from "../../../core/Editable";

export default function AreaField({ value }) {
  const [updated, setUpdated] = React.useState(
    value ? [parseInt(value)] : [100]
  );
  const handleChange = (event) => {
    let value = event.target.value;
    if (Array.isArray(value)) {
      value = value[0];
    }
    setUpdated([parseInt(value)]);
  };
  let i = 0;
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "65%", marginRight: "10px" }}>
        <Typography>
          <Editable
            translationKey={`property:area:content${++i}`}
            defaultText={"Area"}
          />
        </Typography>
        <Slider
          name={"area"}
          getAriaLabel={() => "Area"}
          valueLabelDisplay="auto"
          color="secondary"
          min={0}
          max={5000}
          value={updated}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ width: "32%" }}>
        <Validate
          name="area"
          required
          custom={[
            (value) => parseInt(value) <= 5000,
            "Plot area cannot be higher than 5000",
          ]}
        >
          <TextField
            type="number"
            InputProps={{
              inputProps: {
                max: 5000,
                min: 0,
              },
            }}
            name="area"
            required
            fullWidth
            id="area"
            label={
              <Editable
                translationKey={`property:area:content${++i}`}
                defaultText={"Area"}
              />
            }
            value={updated}
            onChange={handleChange}
          />
        </Validate>
      </Box>
    </Box>
  );
}
