import * as React from 'react'
import { useState } from 'react'
import { Validate } from 'mui-validate'
import TextField from '@mui/material/TextField'
import Editable from '../../../core/Editable'

export default function VatField ({ profile }) {
  const [value, setValue] = useState(localStorage.getItem('VAT') ? localStorage.getItem('VAT') : '')
  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <Validate name="VAT">
      <TextField
        name="VAT"
        fullWidth
        id="VAT"
        label={
          <Editable
            translationKey={`users:fields:vat`}
            defaultText={"VAT / Tax number"}
          />}
        value={profile ? value : undefined}
        onChange={profile ? handleChange : undefined}
      />
    </Validate>
  )
}