import * as React from 'react'
import TextField from '@mui/material/TextField'
import Editable from '../../../core/Editable'

export default function LicenceNumberField ({ value }) {
  const [updated, setUpdated] = React.useState(value ? value : '')
  const handleChange = (event) => {
    setUpdated(event.target.value)
  }
  let i = 0;
  return (
    <TextField
      name="licence"
      fullWidth
      id="licence"
      label={
        <Editable
          translationKey={`property:licence_number:content${++i}`}
          defaultText={'Licence number'}
        />}
      value={updated}
      onChange={handleChange}
    />
  )
}