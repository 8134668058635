import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import logo from "../../../static/images/logo.png";
import marker from "../../../static/images/marker.png";
import robotoThin from "../../../static/fonts/Roboto-Thin.ttf";
import robotoMedium from "../../../static/fonts/Roboto-Medium.ttf";
import robotoBold from "../../../static/fonts/Roboto-Bold.ttf";
import bodoniModaMedium from "../../../static/fonts/BodoniModa-Regular.ttf";
import bodoniModaBold from "../../../static/fonts/BodoniModa-Bold.ttf";

const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    textAlign: "right",
    marginRight: 30,
    marginBottom: 30,
  },
  logo: {
    width: 70,
    height: 33,
  },
  leftImageContainer: {
    display: "flex",
    width: "65%",
    position: "relative",
    paddingRight: 10,
  },
  rightImageContainer: {
    display: "flex",
    width: "65%",
    position: "relative",
    paddingLeft: 10,
  },
  topImageContainer: {
    display: "flex",
    height: "80%",
    width: "99%",
    position: "relative",
    margin: 10,
    textAlign: "center",
  },
  fullImageContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  image: {
    objectFit: "cover",
    height: "99%",
    width: "99%",
  },
  map: {
    objectFit: "cover",
    height: "70%",
    marginTop: "10%",
    marginLeft: "5%",
    width: "90%",
  },
  marker: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-12%, -25%)",
    width: 25,
    height: 25,
  },
  rightTextContainer: {
    width: "35%",
    paddingLeft: 10,
  },
  leftTextContainer: {
    width: "35%",
    paddingRight: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    marginLeft: 10,
  },
  bullet: {
    height: "100%",
  },
});

const ListItem = ({ children }) => {
  return (
    <View style={styles.row}>
      <View style={styles.bullet}>
        <Text>{"\u2022" + " "}</Text>
      </View>
      <Text style={{ fontSize: 14 }}>{children}</Text>
    </View>
  );
};

Font.register({
  family: "Roboto",
  fonts: [
    { src: robotoThin },
    { src: robotoMedium, fontWeight: 500 },
    { src: robotoBold, fontWeight: 700 },
  ],
});

Font.register({
  family: "Bodoni",
  fonts: [{ src: bodoniModaMedium }, { src: bodoniModaBold, fontWeight: 500 }],
});

export default function BrochurePdf({
  property,
  mapImage,
  font,
  watermark,
  anonymous,
}) {
  const amenitiesByCategory = property.amenities.reduce((acc, amenity) => {
    const [category, name] = amenity.split(" - ");
    acc[category] = acc[category] ? [...acc[category], name] : [name];
    return acc;
  }, {});

  let i = 0;
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={{ fontFamily: font }}>
        <View style={styles.topImageContainer}>
          <Image
            style={styles.image}
            src={{
              uri: property.media[i++].url,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
          />
        </View>
        <Text
          style={{
            textAlign: "left",
            fontSize: 24,
            marginLeft: "30px",
            marginTop: "10px",
            fontWeight: 500,
            textTransform: "uppercase",
          }}
        >
          {!anonymous ? property.name : `${property.type} in Spain`}
        </Text>
        <Text
          style={{
            textAlign: "left",
            fontSize: 16,
            marginLeft: "30px",
            marginTop: "10px",
          }}
        >
          {!anonymous
            ? property.address
            : property.city || "Baeric islands, Spain"}
        </Text>
        {watermark && (
          <View style={styles.footer}>
            <Image style={styles.logo} src={logo} />
          </View>
        )}
      </Page>
      <Page
        size="A4"
        orientation="landscape"
        style={{ fontFamily: font, flexDirection: "row", padding: 30 }}
      >
        <View style={styles.leftImageContainer}>
          <Image
            style={styles.image}
            src={{
              uri: property.media[i++].url,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
          />
        </View>
        <View style={styles.rightTextContainer}>
          <Text
            style={{
              textAlign: "center",
              fontSize: 24,
              paddingTop: "30px",
              marginBottom: "40px",
              fontWeight: 500,
              textTransform: "uppercase",
            }}
          >
            {!anonymous ? property.name : `${property.type} in Spain`}
          </Text>
          {property.touristic_licence && (
            <>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  marginTop: "15px",
                }}
              >
                Touristic licence :{" "}
                <Text style={{ fontWeight: 500 }}>
                  {property.touristic_licence}
                </Text>
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  marginTop: "15px",
                }}
              >
                -
              </Text>
            </>
          )}
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              marginTop: "15px",
            }}
          >
            <Text style={{ fontWeight: 500 }}>{property.bedrooms_number}</Text>{" "}
            bedroom{property.bedrooms_number > 1 ? "s" : ""}
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              marginTop: "15px",
            }}
          >
            <Text style={{ fontWeight: 500 }}>{property.bathrooms_number}</Text>{" "}
            bathroom{property.bathrooms_number > 1 ? "s" : ""}
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              marginTop: "15px",
            }}
          >
            Between{" "}
            <Text style={{ fontWeight: 500 }}>{property.min_guests}</Text> and{" "}
            <Text style={{ fontWeight: 500 }}>{property.max_guests}</Text>{" "}
            guests
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              marginTop: "15px",
            }}
          >
            -
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              marginTop: "15px",
            }}
          >
            Area : <Text style={{ fontWeight: 500 }}>{property.area} m²</Text>
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              marginTop: "15px",
            }}
          >
            Plot Area :{" "}
            <Text style={{ fontWeight: 500 }}>{property.plot_area} m²</Text>
          </Text>
        </View>
        {watermark && (
          <View style={styles.footer}>
            <Image style={styles.logo} src={logo} />
          </View>
        )}
      </Page>
      {property.description && property.description !== "<p></p>\n" && (
        <Page
          size="A4"
          orientation="landscape"
          style={{ fontFamily: font, flexDirection: "row", padding: 80 }}
        >
          <View>
            <Html>{property.description}</Html>
          </View>
          {watermark && (
            <View style={styles.footer}>
              <Image style={styles.logo} src={logo} />
            </View>
          )}
        </Page>
      )}
      <Page
        size="A4"
        orientation="landscape"
        style={{ fontFamily: font, flexDirection: "row", padding: 30 }}
      >
        <View style={styles.fullImageContainer}>
          <Image style={styles.map} src={mapImage} />
          <Image style={styles.marker} src={marker} />
        </View>
        {watermark && (
          <View style={styles.footer}>
            <Image style={styles.logo} src={logo} />
          </View>
        )}
      </Page>
      {amenitiesByCategory && amenitiesByCategory.length > 1 && (
        <Page
          size="A4"
          orientation="landscape"
          style={{ fontFamily: font, flexDirection: "row", padding: 30 }}
        >
          <View style={styles.leftTextContainer}>
            {Object.keys(amenitiesByCategory).map((key) => (
              <View key={key}>
                <Text style={{ fontSize: 14, marginBottom: 10, marginTop: 20 }}>
                  {key}
                </Text>
                {amenitiesByCategory[key].map((amenity, index) => (
                  <ListItem key={index}>{amenity}</ListItem>
                ))}
              </View>
            ))}
          </View>
          <View style={styles.rightImageContainer}>
            <Image
              style={styles.image}
              src={{
                uri: property.media[i++].url,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
            />
          </View>
          {watermark && (
            <View style={styles.footer}>
              <Image style={styles.logo} src={logo} />
            </View>
          )}
        </Page>
      )}
      {property.media.map(function (object, j) {
        if (j < 4) {
          return;
        }
        return (
          <Page
            size="A4"
            orientation="landscape"
            style={{ fontFamily: font, flexDirection: "row", padding: 30 }}
          >
            <View style={styles.fullImageContainer}>
              <Image
                style={styles.image}
                src={{
                  uri: object.url,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
            </View>
            {watermark && (
              <View style={styles.footer}>
                <Image style={styles.logo} src={logo} />
              </View>
            )}
          </Page>
        );
      })}
    </Document>
  );
}
