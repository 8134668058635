import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { getData, isLogged, sendData } from "../../core/client";
import { Link, Navigate, useParams } from "react-router-dom";
import { Alert, Chip, Snackbar, Typography } from "@mui/material";
import Editable from "../../core/Editable";
import { enUS, es, fr } from "date-fns/locale";
import blurry_calendar from "../../static/images/blurry_calendar.png";
import { slugify } from "../../core/default";

LoadingButton.propTypes = {
  loadingPosition: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.element,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

const RangeColors = {
  unavailable: "#000000",
  unavailable_limit: "#000000",
  booked: "#5A5A5A",
  booked_limit: "#7C7C7C",
  available: "#007871",
  available_limit: "#007871",
};

const Calendar = ({ subscribe, forbidden, setForbidden, property }) => {
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [displayDates, setDisplayDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [numberOfMonths, setNumberOfMonths] = useState(1);
  const [rangeColors, setRangeColors] = useState([RangeColors.booked]);
  const [status, setStatus] = useState(["booked"]);
  const [active, setActive] = useState("booked");
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState([0, 0]);
  const [init, setInit] = useState(false);
  const [toSend, setToSend] = useState(0);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  let { propertyId } = useParams();
  propertyId = property ? property.id : propertyId;
  let userType = localStorage.getItem("type");
  if (localStorage.getItem("optinsms") === "true") {
    userType = "admin";
  }
  const [locale] = React.useState(localStorage.getItem("locale"));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600) {
        setNumberOfMonths(3);
      } else if (window.innerWidth > 1200) {
        setNumberOfMonths(2);
      } else if (window.innerWidth > 800) {
        setNumberOfMonths(1);
      } else {
        setNumberOfMonths(1);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    setTimeout(() => {}, 1000);
    getData(`/calendars/${propertyId}`).then((response) => {
      if (response === 403) {
        setForbidden(true);
        return;
      }
      if (response.data.length !== 0) {
        let dates = [];
        let display = [];
        let colors = [];
        let statuses = [];
        for (let i = 0; i < response.data.length; i++) {
          dates.push({
            startDate: new Date(Date.parse(response.data[i]["date_begin"])),
            endDate: new Date(Date.parse(response.data[i]["date_end"])),
            key: "selection",
          });
          display.push({
            startDate: new Date(Date.parse(response.data[i]["date_begin"])),
            endDate: new Date(Date.parse(response.data[i]["date_end"])),
            key: "selection",
          });
          display.push({
            startDate: new Date(Date.parse(response.data[i]["date_begin"])),
            endDate: new Date(Date.parse(response.data[i]["date_begin"])),
            key: "selection",
          });
          display.push({
            startDate: new Date(Date.parse(response.data[i]["date_end"])),
            endDate: new Date(Date.parse(response.data[i]["date_end"])),
            key: "selection",
          });
          statuses.push(response.data[i]["status"]);
          colors.push(RangeColors[response.data[i]["status"]]);
          colors.push(RangeColors[response.data[i]["status"] + "_limit"]);
          colors.push(RangeColors[response.data[i]["status"] + "_limit"]);
        }
        setDateRanges(dates);
        setDisplayDates(display);
        setRangeColors(colors);
        setStatus(statuses);
        setToSend(response.data.length);
        if (["owner", "admin"].includes(userType) && !property) {
          setFocus([response.data.length - 1, 0]);
        } else {
          setFocus([0, 0]);
        }
        setInit(false);
      } else {
        setInit(true);
      }
      setTimeout(() => {
        setForbidden(false);
      }, 100);
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [property, propertyId, setForbidden, userType]);

  const handleOnChange = (ranges) => {
    if (init) {
      dateRanges.pop();
      displayDates.pop();
      rangeColors.pop();
      status.pop();
      setInit(false);
    }
    if (!["owner", "admin"].includes(userType) || property) {
      setFocus([0, 0]);
      return;
    }
    if (ranges["selection"]["startDate"] === ranges["selection"]["endDate"]) {
      const { selection } = ranges;
      dateRanges.push(selection);
      displayDates.push(selection);
      setFocus([displayDates.length - 1, 1]);
      rangeColors.push(RangeColors[active]);
      status.push(active);
    } else {
      dateRanges[dateRanges.length - 1]["startDate"] =
        ranges["selection"]["startDate"];
      dateRanges[dateRanges.length - 1]["endDate"] =
        ranges["selection"]["endDate"];
      displayDates[displayDates.length - 1]["startDate"] =
        ranges["selection"]["startDate"];
      displayDates[displayDates.length - 1]["endDate"] =
        ranges["selection"]["endDate"];
      displayDates.push({
        startDate: ranges["selection"]["startDate"],
        endDate: ranges["selection"]["startDate"],
        key: "selection",
      });
      displayDates.push({
        startDate: ranges["selection"]["endDate"],
        endDate: ranges["selection"]["endDate"],
        key: "selection",
      });
      rangeColors.push(RangeColors[active + "_limit"]);
      rangeColors.push(RangeColors[active + "_limit"]);
      setFocus([dateRanges.length - 1, 0]);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let data = dateRanges.slice(toSend);
    let statuses = status.slice(toSend);
    for (let i = 0; i < data.length; i++) {
      await sendData(
        `/calendars/create/${propertyId}`,
        {
          date_begin: data[i]["startDate"],
          date_end: data[i]["endDate"],
          status: statuses[i],
        },
        "post"
      );
    }
    setSuccessMessage("Your calendar was successfully saved.");
    setOpen(true);
    setLoading(false);
  };

  let i = 0;
  let j = 0;
  let k = 0;
  let l = 0;
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "white",
        boxShadow: 2,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      {!isLogged() && (
        <Navigate
          replace
          to={`/sign-in?redirect=${encodeURIComponent(
            window.location.pathname
          )}`}
        />
      )}
      {successMessage !== "" && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            <Editable
              translationKey={`calendar:success:content${++j}`}
              defaultText={"Your calendar was successfully saved."}
            />
          </Alert>
        </Snackbar>
      )}
      <Box sx={{ my: "20px", mx: "20px", textAlign: "right" }}>
        {property && (
          <Box sx={{ textAlign: "left" }}>
            <Link
              to={`/property/${property.id}/${slugify(property.name)}`}
              key={property.id}
              style={{ color: "#003C2D" }}
            >
              <Typography variant={"h5"} gutterBottom>
                {property.name}
              </Typography>
            </Link>
            <Typography variant={"body1"}>{property.address}</Typography>
          </Box>
        )}
        <Typography
          variant={"h5"}
          sx={{ my: "10px", color: "#9C9C9C", textAlign: "left" }}
        >
          <Editable
            translationKey={`calendar:content${++i}`}
            defaultText={"Calendar"}
          />
        </Typography>
        <Typography
          variant={"body2"}
          sx={{ my: "10px", color: "#9C9C9C", textAlign: "left" }}
        >
          <Editable
            translationKey={`calendar:content${++i}`}
            defaultText={
              'Default status when there was no input is white and means "unsure".'
            }
          />
        </Typography>
        {(property || ["agent"].includes(userType)) && (
          <>
            <Typography sx={{ display: "none" }}>
              <Editable
                translationKey={`calendar:content${++i}`}
                defaultText={""}
              />
            </Typography>
            <Box sx={{ mt: "20px" }}>
              <Chip
                sx={{ width: "100%" }}
                label=<Editable
                  translationKey={`calendar:content${++i}`}
                  defaultText={"Booked"}
                />
                color="info"
              />
            </Box>
            <Box sx={{ mt: "20px" }}>
              <Chip
                sx={{ width: "100%" }}
                label=<Editable
                  translationKey={`calendar:content${++i}`}
                  defaultText={"Available"}
                />
                color="success"
              />
            </Box>
            <Box sx={{ mt: "20px" }}>
              <Chip
                sx={{ width: "100%" }}
                label=<Editable
                  translationKey={`calendar:content${++i}`}
                  defaultText={"Unavailable"}
                />
                color="error"
              />
            </Box>
          </>
        )}
        {["owner", "admin"].includes(userType) && !property && (
          <>
            <Typography
              variant={"body2"}
              sx={{ my: "10px", color: "#9C9C9C", textAlign: "left" }}
            >
              <Editable
                translationKey={`calendar:content${++i}`}
                defaultText={
                  "In order to set a new status, click on the corresponding status button then click on the " +
                  "first date then the last date for the time interval where you want to input this status."
                }
              />
            </Typography>
            <Button
              sx={{ my: "10px" }}
              fullWidth
              variant={active === "unavailable" ? "contained" : "outlined"}
              color="error"
              onClick={() => {
                setActive("unavailable");
              }}
            >
              <Editable
                translationKey={`calendar:content${++i}`}
                defaultText={"Unavailable"}
              />
            </Button>
            <Button
              sx={{ my: "10px" }}
              fullWidth
              variant={active === "booked" ? "contained" : "outlined"}
              color="info"
              onClick={() => {
                setActive("booked");
              }}
            >
              <Editable
                translationKey={`calendar:content${++i}`}
                defaultText={"Booked"}
              />
            </Button>
            <Button
              sx={{ my: "10px" }}
              fullWidth
              variant={active === "available" ? "contained" : "outlined"}
              color="success"
              onClick={() => {
                setActive("available");
              }}
            >
              <Editable
                translationKey={`calendar:content${++i}`}
                defaultText={"Available"}
              />
            </Button>
            <LoadingButton
              sx={{ my: "10px" }}
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              onClick={handleSubmit}
            >
              <Editable
                translationKey={`calendar:content${++i}`}
                defaultText={"Save"}
              />
            </LoadingButton>
          </>
        )}
      </Box>

      {!forbidden && (
        <Box sx={{ textAlign: "left" }}>
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={numberOfMonths}
            ranges={displayDates}
            direction="horizontal"
            rangeColors={rangeColors}
            focusedRange={focus}
            locale={locale === "en" ? enUS : locale === "fr" ? fr : es}
            dragSelectionEnabled={false}
            shownDate={new Date()}
          />
        </Box>
      )}
      {forbidden && (
        <Box
          sx={{
            width: "50vw",
            height: "300px",
            background: "url(" + blurry_calendar + ")",
            backgroundSize: "cover",
          }}
        >
          <Typography
            variant={"h5"}
            sx={{
              margin: "auto",
              textAlign: "center",
              color: "white",
              px: 10,
              mt: "10vh",
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
          >
            <Editable
              translationKey={`calendar:forbidden:content${++k}`}
              defaultText={
                "You cannot access a calendar without subscribing to its property."
              }
            />
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: "20px" }}
            onClick={() => {
              subscribe();
            }}
          >
            <Editable
              translationKey={`calendar:viewer:content${++l}`}
              defaultText={"Subscribe"}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
};

Calendar.propTypes = {
  onChange: PropTypes.func,
};

export default Calendar;
