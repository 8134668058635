import * as React from "react";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert, styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Typography from "@mui/material/Typography";
import NameField from "../input/NameField";
import LicenceNumberField from "../input/LicenceNumberField";
import AddressField from "../input/AddressField";
import PropertyMap from "../PropertyMap";
import GuestsField from "../input/GuestsField";
import AreaField from "../input/AreaField";
import PlotAreaField from "../input/PlotAreaField";
import Button from "@mui/material/Button";
import BedroomsNumberField from "../input/BedroomsNumberField";
import BathroomsNumberField from "../input/BathroomsNumberField";
import { Link } from "react-router-dom";
import Editable from "../../../core/Editable";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import CastleOutlinedIcon from "@mui/icons-material/CastleOutlined";

const StyledToggleButton = styled(ToggleButton)({
  "&.MuiToggleButtonGroup-grouped": {
    borderRadius: "4px !important",
    border: "none",
    textTransform: "none",
    fontWeight: "1000",
  },
});

export default function PropertyFirstStep({
  savedData,
  handlePropertyType,
  longitude,
  latitude,
  mapCallback,
  setLongitude,
  setLatitude,
  errorMessage,
  handleSubmit,
}) {
  let i = 0;
  return (
    <ValidationGroup>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ my: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" align={"center"}>
                  <Editable
                    translationKey={`property_step_1:content${++i}`}
                    defaultText={"My property is"}
                  />
                </Typography>
                <ToggleButtonGroup
                  sx={{ mt: 3, mb: 3 }}
                  value={
                    savedData.current["type"]
                      ? savedData.current["type"]
                      : "villa"
                  }
                  exclusive
                  fullWidth
                  onChange={handlePropertyType}
                  aria-label="text userType"
                >
                  <StyledToggleButton
                    sx={{ width: "33%", display: "block" }}
                    value="villa"
                    aria-label="owner"
                  >
                    <HouseOutlinedIcon sx={{ fontSize: "70px" }} />
                    <Box>
                      <Editable
                        translationKey={`property_step_1:content${++i}`}
                        defaultText={"a villa / house"}
                      />
                    </Box>
                  </StyledToggleButton>
                  <StyledToggleButton
                    sx={{ width: "33%", display: "block" }}
                    value="apartment"
                    aria-label="agent"
                  >
                    <CorporateFareOutlinedIcon sx={{ fontSize: "70px" }} />
                    <Box>
                      <Editable
                        translationKey={`property_step_1:content${++i}`}
                        defaultText={"an apartment"}
                      />
                    </Box>
                  </StyledToggleButton>
                  <StyledToggleButton
                    sx={{ width: "33%", display: "block" }}
                    value="other"
                    aria-label="agent"
                  >
                    <CastleOutlinedIcon sx={{ fontSize: "70px" }} />
                    <Box>
                      <Editable
                        translationKey={`property_step_1:content${++i}`}
                        defaultText={"something else"}
                      />
                    </Box>
                  </StyledToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NameField
                  value={
                    savedData.current["name"]
                      ? savedData.current["name"]
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LicenceNumberField
                  value={
                    savedData.current["licence"]
                      ? savedData.current["licence"]
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AddressField
                  baseValue={
                    savedData.current["address"]
                      ? savedData.current["address"]
                      : undefined
                  }
                  city={
                    savedData.current["city"]
                      ? savedData.current["city"]
                      : undefined
                  }
                  defaultLongitude={longitude}
                  defaultLatitude={latitude}
                  mapCallback={mapCallback}
                  setLongitude={setLongitude}
                  setLatitude={setLatitude}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  <PropertyMap
                    longitude={longitude}
                    latitude={latitude}
                    mapStyle={"dark"}
                    setLongitude={setLongitude}
                    setLatitude={setLatitude}
                    preserveDrawingBuffer={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: { xs: "none", sm: "block" },
              }}
            >
              <PropertyMap
                longitude={longitude}
                latitude={latitude}
                mapStyle={"dark"}
                setLongitude={setLongitude}
                setLatitude={setLatitude}
                preserveDrawingBuffer={false}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <GuestsField
                value={
                  savedData.current["guests"]
                    ? savedData.current["guests"]
                    : undefined
                }
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} md={9}>
                <Box sx={{ marginBottom: "20px" }}>
                  <AreaField
                    value={
                      savedData.current["area"]
                        ? savedData.current["area"]
                        : undefined
                    }
                  />
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <PlotAreaField
                    value={
                      savedData.current["plotArea"]
                        ? savedData.current["plotArea"]
                        : undefined
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={12}>
                    <BedroomsNumberField
                      value={
                        savedData.current["bedroomsNumber"]
                          ? savedData.current["bedroomsNumber"]
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Box sx={{ marginTop: "8px" }}>
                      <BathroomsNumberField
                        value={
                          savedData.current["bathroomsNumber"]
                            ? savedData.current["bathroomsNumber"]
                            : undefined
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ position: "relative", top: "-20px" }}>
              <Button
                component={Link}
                to={"/properties"}
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
              >
                <Editable
                  translationKey={`property_step_1:content${++i}`}
                  defaultText={"Back"}
                />
              </Button>
              <AutoDisabler firstDisplayErrors={true}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, float: "right" }}
                >
                  <Editable
                    translationKey={`property_step_1:content${++i}`}
                    defaultText={"Next"}
                  />
                </Button>
              </AutoDisabler>
            </Box>
          </Grid>
        </Grid>
        {errorMessage !== "" && (
          <Alert sx={{ mt: 1, mb: 2 }} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Box>
    </ValidationGroup>
  );
}
