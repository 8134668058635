import * as React from "react";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getData } from "../../../core/client";
import { useEffect } from "react";

export default function TypeField({ value }) {
  const [updated, setUpdated] = React.useState(value ? value : "Photographer");
  const [availableContractorTypes, setAvailableContractorTypes] =
    React.useState([]);

  const handleChange = (event) => {
    let value = event.target.value;
    setUpdated(value);
  };

  async function getContractorTypes() {
    return await getData(`/contractors/get-contractor-types?locale=en`).then(
      (response) => {
        return response.data;
      }
    );
  }

  useEffect(() => {
    getContractorTypes().then((data) => {
      setAvailableContractorTypes(data);
    });
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="contractor-type-label">Contractor type</InputLabel>
      {availableContractorTypes.length > 0 && (
        <Select
          labelId="contractor-type-label"
          id="contractor-type"
          value={updated}
          label="Contractor type"
          onChange={handleChange}
        >
          {availableContractorTypes.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      )}
      <TextField
        name="type"
        sx={{ opacity: 0 }}
        type="hidden"
        value={updated}
      />
    </FormControl>
  );
}
