import * as React from 'react'
import { Validate } from 'mui-validate'
import TextField from '@mui/material/TextField'
import Editable from '../../../core/Editable'

export default function LoginEmailField () {

  return (
    <Validate name="email" required>
      <TextField
        required
        fullWidth
        id="email"
        label={
          <Editable
            translationKey={`users:fields:login`}
            defaultText={'Email Address'}
          />}
        name="username"
        autoComplete="email"
      />
    </Validate>
  )
}