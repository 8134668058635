import { useState } from "react";
import chef from "../static/images/contractors/chef.png";
import worker from "../static/images/contractors/worker.png";
import ibiza from "../static/images/ibiza.png";
import cleaning from "../static/images/contractors/cleaning.png";
import masseur from "../static/images/contractors/masseur.png";
import photographer from "../static/images/contractors/photographer2.png";
import webdesigner from "../static/images/contractors/webdesigner.png";

export const BASE_IMAGE = ibiza;
export const BASE_CONTRACTOR_IMAGE = {
  Maintenance: worker,
  Chef: chef,
  "Cleaning services": cleaning,
  Massage: masseur,
  Photographer: photographer,
  "Website design": webdesigner,
};
export const BASE_LATITUDE = 38.906986;
export const BASE_LONGITUDE = 1.421416;

export function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}
export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
