import * as React from "react";
import Calendar from "./Calendar";

export default function Calendars({ properties }) {
  const [forbidden, setForbidden] = React.useState(true);
  return (
    <>
      {properties.map((item) => (
        <Calendar
          property={item}
          setForbidden={setForbidden}
          forbidden={forbidden}
        />
      ))}
    </>
  );
}
