import * as React from 'react'
import TextField from '@mui/material/TextField'
import { Validate } from 'mui-validate'

export default function PriceField ({ value }) {
  const [updated, setUpdated] = React.useState(value ? value : '')
  const handleChange = (event) => {
    setUpdated(event.target.value)
  }
  return (
    <Validate price="price" required>
      <TextField
        name="price"
        fullWidth
        id="price"
        label="Give a price to your contractor"
        value={updated}
        onChange={handleChange}
      />
    </Validate>
  )
}