import * as React from "react";
import { AutoDisabler } from "mui-validate";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import draftToHtml from "draftjs-to-html";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import { useState } from "react";
import Editable from "../../../core/Editable";

export default function PropertySecondStep({
  handleBack,
  handleSubmit,
  errorMessage,
  savedData,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(
          savedData.current["description"]
            ? savedData.current["description"]
            : ""
        )
      )
    )
  );

  const onEditorChange = (state) => {
    setEditorState(state);
    let raw = convertToRaw(state.getCurrentContent());
    savedData.current["description"] = draftToHtml(raw);
  };
  let i = 0;
  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mt: 3, width: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Editor
            editorStyle={{
              height: "45vh",
              background: "white",
              border: "solid thin lightGray",
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "remove",
                "history",
              ],
            }}
            editorState={editorState}
            onEditorStateChange={onEditorChange}
          />
        </Grid>
      </Grid>
      <Button variant="outlined" sx={{ mt: 3, mb: 2 }} onClick={handleBack}>
        <Editable
          translationKey={`property_step_2:content${++i}`}
          defaultText={"Back"}
        />
      </Button>
      <AutoDisabler firstDisplayErrors={true}>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, float: "right" }}
        >
          <Editable
            translationKey={`property_step_2:content${++i}`}
            defaultText={"Next"}
          />
        </Button>
      </AutoDisabler>
      {errorMessage !== "" && (
        <Alert sx={{ mt: 1, mb: 2 }} severity="error">
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
}
