import * as React from 'react'
import { useState } from 'react'
import 'react-phone-number-input/style.css'
import { MuiTelInput } from 'mui-tel-input'
import Editable from '../../../core/Editable'

export default function PhoneField ({ profile }) {
  const [value, setValue] = useState(localStorage.getItem('phone') ? localStorage.getItem('phone') : '')
  const handleChange = (newValue) => {
    setValue(newValue)
  }
  return (
    <MuiTelInput
      name="phone"
      fullWidth
      id="phone"
      label={
        <Editable
          translationKey={`users:fields:phone`}
          defaultText={"Phone number"}
        />}
      autoComplete="phone"
      value={profile ? value : undefined}
      onChange={profile ? handleChange : undefined}
    />
  )
}