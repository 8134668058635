import * as React from 'react'
import { Slider } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Editable from '../../../core/Editable'

export default function GuestsField ({ value }) {
  const [updated, setUpdated] = React.useState(value ? [parseInt(value[0]), parseInt(value[1])] : [1, 40])
  const handleChange = (event) => {
    setUpdated(event.target.value)
  }
  let i = 0;
  return (
    <Box>
      <Typography>
        <Editable
          translationKey={`property:guests:content${++i}`}
          defaultText={'Number of guests'}
        />

      </Typography>
      <Slider
        name={'guests'}
        getAriaLabel={() => 'Guests'}
        value={updated}
        valueLabelDisplay="auto"
        color="secondary"
        min={1}
        max={40}
        onChange={handleChange}
      />
    </Box>
  )
}