import * as React from "react";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import AmenitiesField from "../input/AmenitiesField";
import Editable from "../../../core/Editable";

export default function PropertyThirdStep({
  savedData,
  errorMessage,
  handleSubmit,
  handleBack,
  id,
}) {
  let i = 0;
  let j = 0;
  let k = 0;
  return (
    <ValidationGroup>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AmenitiesField
              value={
                savedData.current["amenities"]
                  ? savedData.current["amenities"]
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Button variant="outlined" sx={{ mt: 3, mb: 2 }} onClick={handleBack}>
          <Editable
            translationKey={`property_step_3:content${++i}`}
            defaultText={"Back"}
          />
        </Button>
        {id.current && (
          <AutoDisabler firstDisplayErrors={true}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, float: "right" }}
            >
              <Editable
                translationKey={`property_step_3:update:content${++k}`}
                defaultText={"Next"}
              />
            </Button>
          </AutoDisabler>
        )}
        {!id.current && (
          <AutoDisabler firstDisplayErrors={true}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, float: "right" }}
            >
              <Editable
                translationKey={`property_step_3:create:content${++j}`}
                defaultText={"Next"}
              />
            </Button>
          </AutoDisabler>
        )}
        {errorMessage !== "" && (
          <Alert sx={{ mt: 1, mb: 2 }} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Box>
    </ValidationGroup>
  );
}
