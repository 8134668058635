import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { getData, sendData } from "../../core/client";
import { Alert, Link } from "@mui/material";
import {
  Link as RouterLink,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import LoginEmailField from "./input/LoginEmailField";
import LoginPasswordField from "./input/LoginPasswordField";
import Editable from "../../core/Editable";
import home from "../../static/images/home.png";

export default function SignIn({ setToken }) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [searchParams] = useSearchParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form_data = new FormData(event.currentTarget);
    if (
      form_data.get("username").toString() === "" ||
      form_data.get("password").toString() === ""
    ) {
      return;
    }
    let response = await sendData("/users/auth/token", form_data, "post", {
      "Content-Type": "multipart/form-data",
    });
    if (!isNaN(response)) {
      if (response === 401 || response === 404) {
        setErrorMessage(
          "No user found matching this email / password combination."
        );
      } else {
        setErrorMessage(
          "We have a problem. This is on our end, please try again later."
        );
      }
    } else {
      localStorage.setItem("token", response.data["access_token"]);
      setToken(response.data["access_token"]);
      getData("/users/info").then((response) => {
        localStorage.setItem("firstname", response.data["first_name"]);
        localStorage.setItem("lastname", response.data["last_name"]);
        localStorage.setItem("email", response.data["email"]);
        localStorage.setItem("optIn", response.data["opt_in"]);
        localStorage.setItem("type", response.data["type"]);
        localStorage.setItem("id", response.data["id"]);
        localStorage.setItem("optinsms", response.data["optinsms"]);
        if (response.data["avatar_url"])
          localStorage.setItem("avatar", response.data["avatar_url"]);
        if (response.data["phone_number"])
          localStorage.setItem("phone", response.data["phone_number"]);
        if (response.data["address"])
          localStorage.setItem("address", response.data["address"]);
        if (response.data["longitude"])
          localStorage.setItem("longitude", response.data["longitude"]);
        if (response.data["latitude"])
          localStorage.setItem("latitude", response.data["latitude"]);
        if (response.data["job_title"])
          localStorage.setItem("job_title", response.data["job_title"]);
        if (response.data["company_name"])
          localStorage.setItem("company_name", response.data["company_name"]);
        if (response.data["company_registration"])
          localStorage.setItem(
            "company_registration",
            response.data["company_registration"]
          );
        if (response.data["VAT"]) {
          localStorage.setItem("VAT", response.data["VAT"]);
        }
        if (response.data["prefix"])
          localStorage.setItem("prefix", response.data["prefix"]);
        setSuccess(true);
      });
    }
  };

  let i = 0;
  let j = 0;

  return (
    <Grid container component="main" sx={{ minHeight: "83vh" }}>
      {success && (
        <Navigate
          replace
          to={
            searchParams.get("redirect") &&
            searchParams.get("redirect") !== "null"
              ? searchParams.get("redirect")
              : "/properties"
          }
        />
      )}
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(" + home + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, mt: 5, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Editable
              translationKey={`sign-in:content${++i}`}
              defaultText={"Sign in"}
            />
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 5 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <LoginEmailField />
              </Grid>
              <Grid item xs={12} sm={12}>
                <LoginPasswordField />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <Editable
                translationKey={`sign-in2:content${++j}`}
                defaultText={"Sign in"}
              />
            </Button>
            {errorMessage !== "" && (
              <Alert sx={{ mt: 1, mb: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/forgot-password">
                  <Editable
                    translationKey={`sign-in:content${++i}`}
                    defaultText={"Forgot password?"}
                  />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  component={RouterLink}
                  to={`/sign-up?redirect=${encodeURIComponent(
                    searchParams.get("redirect")
                  )}`}
                >
                  <Editable
                    translationKey={`sign-in:content${++i}`}
                    defaultText={"Don't have an account? Sign Up"}
                  />
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
