import * as React from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import configData from "../../config.json";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";
import {
  BASE_IMAGE,
  BASE_LATITUDE,
  BASE_LONGITUDE,
  slugify,
} from "../../core/default";
import { Link } from "@mui/material";

import mapboxgl from "mapbox-gl";
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function PropertyMap({
  longitude,
  latitude,
  mapStyle,
  setLongitude,
  setLatitude,
  properties,
  minLatitude,
  minLongitude,
  maxLatitude,
  maxLongitude,
  preserveDrawingBuffer,
  setMapImage,
}) {
  const mapRef = React.useRef();
  const boxRef = React.useRef();
  const [popupInfo, setPopupInfo] = React.useState(null);

  const [viewport] = React.useState({
    longitude: longitude,
    latitude: latitude,
    zoom: longitude === BASE_LONGITUDE ? 8 : preserveDrawingBuffer ? 13 : 14,
  });

  const updateMapImage = () => {
    const image = mapRef?.current?.getMap().getCanvas().toDataURL("image/png");
    setMapImage(image);
  };

  React.useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === boxRef.current) {
          mapRef.current?.resize();
        }
      }
    });

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    if (preserveDrawingBuffer) {
      setTimeout(() => {
        if (preserveDrawingBuffer) {
          updateMapImage();
        }
      }, 1000);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (minLatitude && minLatitude !== 999) {
      if (properties.length > 0) {
        let zoomTo = [
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude],
        ];
        setTimeout(() => {
          mapRef.current?.fitBounds(zoomTo, {
            maxZoom: 18,
            padding: 100,
            duration: 500,
          });
        }, 200);
      } else {
        setTimeout(() => {
          mapRef.current?.flyTo({
            center: [BASE_LONGITUDE, BASE_LATITUDE],
            duration: 1000,
            essential: true,
            zoom: 9,
          });
        }, 200);
      }
    }
  }, [minLatitude, minLongitude, maxLatitude, maxLongitude, properties]);

  React.useEffect(() => {
    if (minLatitude) {
      return;
    }
    let zoom = 14;
    if (longitude === BASE_LONGITUDE) {
      zoom = 8;
    }
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 1000,
      essential: true,
      zoom: zoom,
    });
    mapRef.current?.resize();
  }, [longitude, latitude]);

  function handleMapClick(evt) {
    if (!setLongitude) {
      return;
    }
    longitude = evt.lngLat.lng;
    latitude = evt.lngLat.lat;
    setLongitude(longitude);
    setLatitude(latitude);
  }

  let mapboxToken = configData["MAPBOX_TOKEN"];

  return (
    <>
      <Box ref={boxRef} sx={{ width: "100%", height: "100%" }}>
        {mapboxToken && (
          <Map
            preserveDrawingBuffer={preserveDrawingBuffer}
            ref={mapRef}
            initialViewState={viewport}
            mapboxAccessToken={mapboxToken}
            style={{ width: "100%", height: "100%" }}
            mapStyle={
              mapStyle === "light"
                ? "mapbox://styles/mapbox/light-v11"
                : "mapbox://styles/mapbox/streets-v12"
            }
            onClick={handleMapClick}
          >
            {longitude !== BASE_LONGITUDE && longitude && (
              <Marker longitude={longitude} latitude={latitude} />
            )}
            {properties &&
              properties.map((item) => (
                <Marker
                  style={{ cursor: "pointer" }}
                  longitude={item.longitude}
                  latitude={item.latitude}
                  anchor="bottom"
                  onClick={(e) => {
                    // If we let the click event propagates to the map, it will immediately close the popup
                    // with `closeOnClick: true`
                    e.originalEvent.stopPropagation();
                    setPopupInfo(item);
                  }}
                />
              ))}
            {popupInfo && (
              <Popup
                anchor="top"
                longitude={Number(popupInfo.longitude)}
                latitude={Number(popupInfo.latitude)}
                onClose={() => setPopupInfo(null)}
              >
                <Box>
                  <Link
                    component={RouterLink}
                    target="_blank"
                    to={`/property/${popupInfo.id}/${slugify(popupInfo.name)}`}
                  >
                    {popupInfo.name}
                  </Link>
                </Box>
                <Box>{popupInfo.address}</Box>
                <Link
                  component={RouterLink}
                  target="_blank"
                  to={`/property/${popupInfo.id}/${slugify(popupInfo.name)}`}
                >
                  <img
                    width="100%"
                    alt={"property"}
                    src={
                      popupInfo.media[0]
                        ? `${popupInfo.media[0].url}?w=248&fit=crop&auto=format`
                        : BASE_IMAGE
                    }
                  />
                </Link>
              </Popup>
            )}
          </Map>
        )}
      </Box>
    </>
  );
}
