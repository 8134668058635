import * as React from 'react'
import TextField from '@mui/material/TextField'
import { Validate } from 'mui-validate'

export default function InternalNotesField ({ value }) {
  const [updated, setUpdated] = React.useState(value ? value : '')
  const handleChange = (event) => {
    setUpdated(event.target.value)
  }
  return (
    <Validate internalNotes="internalNotes">
      <TextField
        name="internalNotes"
        fullWidth
        id="internalNotes"
        label="Internal notes"
        value={updated}
        onChange={handleChange}
        multiline
        rows={5}
      />
    </Validate>
  )
}