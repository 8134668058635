import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Alert, Link, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import { sendData } from "../../core/client";
import {
  Link as RouterLink,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import PasswordField from "./input/PasswordField";
import EmailField from "./input/EmailField";
import OptInCheckbox from "./input/OptInCheckbox";
import LastNameField from "./input/LastNameField";
import FirstNameField from "./input/FirstNameField";
import Paper from "@mui/material/Paper";
import Editable from "../../core/Editable";
import home from "../../static/images/home.png";

export default function SignUp() {
  const [userType, setUserType] = React.useState("owner");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [isOptIn, setIsOptIn] = React.useState(false);
  const [searchParams] = useSearchParams();

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {};
    for (let p of formData) {
      let name = p[0];
      data[name] = p[1];
    }
    data["type"] = userType;
    data["opt_in"] = isOptIn;
    if (
      data["first_name"] === "" ||
      data["last_name"] === "" ||
      data["email"] === "" ||
      data["password"] === ""
    ) {
      return;
    }
    let response = await sendData("/users/register", data, "post");
    if (!isNaN(response)) {
      if (response === 409) {
        setErrorMessage("A user with this email address already exists.");
      } else {
        setErrorMessage(
          "We have a problem. This is on our end, please try again later."
        );
      }
    } else {
      console.log("User successfully created, redirected towards login.");
      setSuccess(true);
      await timeout(6000);
      setRedirect(true);
    }
  };

  const handleUserType = (event, newUserType) => {
    if (newUserType === null) {
      return;
    }
    setUserType(newUserType);
  };

  const handleOptIn = (optIn) => {
    setIsOptIn(!optIn);
  };

  let i = 0;
  let j = 0;
  return (
    <Grid container component="main" sx={{ minHeight: "83vh" }}>
      {redirect && (
        <Navigate
          replace
          to={`/sign-in?redirect=${encodeURIComponent(
            searchParams.get("redirect")
          )}`}
        />
      )}
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(" + home + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <Editable
              translationKey={`user:signup:content${++i}`}
              defaultText={"Sign up"}
            />
          </Typography>
          <ValidationGroup>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3, maxWidth: "500px" }}
            >
              <Typography variant="h5" align={"center"}>
                <Editable
                  translationKey={`user:signup:content${++i}`}
                  defaultText={"I am"}
                />
              </Typography>
              <ToggleButtonGroup
                sx={{ mt: 3, mb: 3 }}
                value={userType}
                exclusive
                fullWidth
                onChange={handleUserType}
                aria-label="text userType"
              >
                <ToggleButton
                  sx={{ width: "50%" }}
                  value="owner"
                  aria-label="owner"
                >
                  <Editable
                    translationKey={`user:signup:content${++i}`}
                    defaultText={"A property owner"}
                  />
                </ToggleButton>
                <ToggleButton
                  sx={{ width: "50%" }}
                  value="agent"
                  aria-label="agent"
                >
                  <Editable
                    translationKey={`user:signup:content${++i}`}
                    defaultText={"A property viewer"}
                  />
                </ToggleButton>
              </ToggleButtonGroup>
              <Typography variant={"body1"} sx={{ mb: "30px" }}>
                {userType === "owner" && (
                  <Editable
                    translationKey={`user:signup:content:owner_description`}
                    defaultText={
                      "Owners are people who own a property and wish to rent it."
                    }
                  />
                )}
                {userType === "agent" && (
                  <Editable
                    translationKey={`user:signup:content:viewer_description`}
                    defaultText={
                      "Viewers are people who want to know when a given property is available."
                    }
                  />
                )}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FirstNameField profile={false} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LastNameField profile={false} />
                </Grid>
                <Grid item xs={12}>
                  <EmailField profile={false} />
                </Grid>
                <Grid item xs={12}>
                  <PasswordField />
                </Grid>
                <Grid item xs={12}>
                  <OptInCheckbox onOptInChange={handleOptIn} />
                </Grid>
              </Grid>
              <AutoDisabler firstDisplayErrors={true}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <Editable
                    translationKey={`user:signup:content${++i}`}
                    defaultText={"Edit"}
                  />
                </Button>
              </AutoDisabler>
              {errorMessage !== "" && (
                <Alert sx={{ mt: 1, mb: 2 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              {success && (
                <Alert sx={{ mt: 1, mb: 2 }} severity="success">
                  <Editable
                    translationKey={`user:signup:success:content${++j}`}
                    defaultText={
                      "Thank you for joining RECAL! You will now be redirected towards sign-in in order to begin your journey."
                    }
                  />
                  <Link
                    component={RouterLink}
                    to={`/sign-in?redirect=${encodeURIComponent(
                      searchParams.get("redirect")
                    )}`}
                  >
                    <Editable
                      translationKey={`user:signup:success:content${++j}`}
                      defaultText={"Redirect me now."}
                    />
                  </Link>
                </Alert>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link component={RouterLink} to="/sign-in">
                    <Editable
                      translationKey={`user:signup:content${++i}`}
                      defaultText={"Already have an account? Click here."}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </ValidationGroup>
        </Box>
      </Grid>
    </Grid>
  );
}
