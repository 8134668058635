import * as React from "react";
import { useEffect } from "react";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Link, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  alpha,
  Fab,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { getData } from "../../core/client";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { BASE_CONTRACTOR_IMAGE } from "../../core/default";
import Container from "@mui/material/Container";
import LoggingRequired from "../../core/LoggingRequired";
import Editable from "../../core/Editable";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const contractorTheme = createTheme({
  palette: {
    primary: {
      light: "#00CFC3",
      main: "#003C2D",
      dark: "#2B514C",
    },
    secondary: {
      light: "#00CFC3",
      main: "#00CFC3",
      dark: "#00CFC3",
    },
    background: {
      default: "#FCFCFC",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1400,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "251px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function Contractors() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [contractorTypes, setContractorTypes] = React.useState([]);
  const [contractors, setContractors] = React.useState([]);
  const [allContractors, setAllContractors] = React.useState([]);
  const [availableContractorTypes, setAvailableContractorTypes] =
    React.useState([]);
  let userType = localStorage.getItem("type");
  if (localStorage.getItem("optinsms") === "true") {
    userType = "admin";
  }
  const [locale] = React.useState(localStorage.getItem("locale"));
  const [search, setSearch] = React.useState("");

  async function getContractorTypes() {
    return await getData(
      `/contractors/get-contractor-types?locale=${locale}`
    ).then((response) => {
      return response.data;
    });
  }

  async function getContractors() {
    return await getData(`/contractors?locale=${locale}`).then((response) => {
      return response.data;
    });
  }

  useEffect(() => {
    getContractorTypes().then((data) => {
      setAvailableContractorTypes(data);
    });
    getContractors().then((data) => {
      setContractors(data);
      setAllContractors(data);
    });
  }, []);

  useEffect(() => {
    let types = searchParams.get("contractorTypes");
    if (types !== null) {
      setContractorTypes(types.split(","));
    }
    applyFilters();
  }, [contractorTypes, search]);

  const handleContractorTypes = (event) => {
    searchParams.set("contractorTypes", event.target.value);
    setSearchParams(searchParams);
    setContractorTypes(event.target.value);
  };

  const eraseContractorTypes = () => {
    searchParams.set("contractorTypes", "");
    setSearchParams(searchParams);
    setContractorTypes([]);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const applyFilters = () => {
    let filteredContractors = allContractors;
    let results = [];
    let i = 0;
    let filtered;
    if (search !== "") {
      for (const word of search.match(/\b(\w+)\b/g)) {
        results[i] = [];
        for (const contractor of filteredContractors) {
          searchLoop: for (const prop in contractor) {
            const searchable = contractor[prop];
            if (
              typeof searchable === "string" ||
              searchable instanceof String
            ) {
              if (searchable.toLowerCase().includes(word.toLowerCase())) {
                results[i].push(contractor);
                break;
              }
            } else if (Array.isArray(searchable)) {
              for (const searchableString of searchable) {
                if (
                  typeof searchableString === "string" ||
                  searchableString instanceof String
                ) {
                  if (
                    searchableString.toLowerCase().includes(word.toLowerCase())
                  ) {
                    results[i].push(contractor);
                    break searchLoop;
                  }
                }
              }
            }
          }
        }
        i++;
      }
      filtered = results.reduce((a, b) => a.filter((c) => b.includes(c)));
    } else {
      filtered = allContractors;
    }
    if (
      contractorTypes.length !== 0 &&
      (contractorTypes.length !== 1 || contractorTypes[0] !== "")
    ) {
      for (const tmp of filtered) {
        if (contractorTypes.includes(tmp.type)) {
          continue;
        }
        filtered = filtered.filter(function (obj) {
          return obj.id !== tmp.id;
        });
      }
    }
    setContractors(filtered);
  };

  let i = 0;
  return (
    <ThemeProvider theme={contractorTheme}>
      <LoggingRequired />
      <AppBar
        position="static"
        sx={{
          paddingTop: "30px",
          paddingBottom: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5.5} sx={{ marginTop: "0px" }}>
              <Search sx={{ width: "100%" }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{ minWidth: "500px" }}
                  name={"search"}
                  placeholder={
                    locale === "en"
                      ? "Search..."
                      : locale === "fr"
                      ? "Rechercher..."
                      : "Buscar..."
                  }
                  onChange={handleSearch}
                  value={search}
                />
              </Search>
            </Grid>
            {availableContractorTypes !== [] && (
              <Grid
                item
                xs={12}
                sm={5.5}
                sx={{ marginRight: "10px", marginTop: "0px" }}
              >
                <ThemeProvider theme={darkTheme}>
                  <CssBaseline />
                  <FormControl size="small" sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">
                      <Editable
                        translationKey={`contractors:content${++i}`}
                        defaultText={"Contractor types"}
                      />
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={contractorTypes}
                      onChange={handleContractorTypes}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {availableContractorTypes.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Tooltip title="Reset filters">
                      <IconButton
                        onClick={() => eraseContractorTypes()}
                        sx={{
                          display: "inline",
                          width: "40px",
                          height: "40px",
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormControl>
                </ThemeProvider>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="xl" sx={{ marginBottom: "20px" }}>
        <ThemeProvider theme={contractorTheme}>
          <CssBaseline />
          {(contractors.length !== 0 || ["admin"].includes(userType)) && (
            <Box
              sx={{
                minHeight: "60vh",
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                },
                [`& .${imageListItemClasses.root}`]: {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {["admin"].includes(userType) && (
                <Link to={"/add-contractor"}>
                  <ImageListItem sx={{ margin: "20px" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "27vh",
                        position: "relative",
                        border: "solid thin gray",
                      }}
                    >
                      <Fab
                        color="secondary"
                        aria-label="add"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          margin: "-60px 0 0 -33px",
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    </Box>
                    <ImageListItemBar
                      title={
                        <Editable
                          translationKey={`contractors:content${++i}`}
                          defaultText={"Add a new contractor"}
                        />
                      }
                      subtitle={
                        <Editable
                          translationKey={`contractors:content${++i}`}
                          defaultText={"Click here to add a new contractor."}
                        />
                      }
                    />
                  </ImageListItem>
                </Link>
              )}
              {contractors.map((item) => (
                <Link to={`/contractor/${item.id}`} key={item.id}>
                  <ImageListItem sx={{ margin: "20px" }}>
                    <img
                      src={
                        item.media[0]
                          ? `${item.media[0].url}?w=248&fit=crop&auto=format`
                          : BASE_CONTRACTOR_IMAGE[item.type_key]
                      }
                      srcSet={
                        item.media[0]
                          ? `${item.media[0].url}?w=248&fit=crop&auto=format&dpr=2 2x`
                          : BASE_CONTRACTOR_IMAGE[item.type_key]
                      }
                      alt={item.name}
                      style={{ width: "100%", height: "27vh" }}
                      loading="lazy"
                    />
                    {["owner", "admin"].includes(userType) && (
                      <ImageListItemBar
                        title={item.name}
                        subtitle={item.type}
                        actionIcon={
                          <Link to={`/edit-contractor/${item.id}`}>
                            <IconButton
                              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                              aria-label={`info about ${item.name}`}
                            >
                              <EditIcon />
                            </IconButton>
                          </Link>
                        }
                      />
                    )}
                    {!["owner", "admin"].includes(userType) && (
                      <ImageListItemBar
                        title={item.name}
                        subtitle={item.type}
                      />
                    )}
                  </ImageListItem>
                </Link>
              ))}
            </Box>
          )}
        </ThemeProvider>
      </Container>
    </ThemeProvider>
  );
}
