import * as React from "react";
import Box from "@mui/material/Box";
import DraggableList from "../../../core/DraggableList";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { deleteData } from "../../../core/client";
import { useForceUpdate } from "../../../core/default";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";

export default function MediaListField({ media }) {
  const forceUpdate = useForceUpdate();

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let tmp = media[toIndex];
      media[toIndex] = media[fromIndex];
      media[fromIndex] = tmp;
      forceUpdate();
    },
    nodeSelector: "li",
    handleSelector: "a",
  };

  const removeMedia = (id, position) => {
    deleteData(`/contractors/media/delete/${id}`).then(() => {
      media.splice(position, 1);
      forceUpdate();
    });
  };

  const moveTop = (index) => {
    let tmp = media[index];
    for (let i = index; i > 0; i--) {
      media[i] = media[i - 1];
    }
    media[0] = tmp;
    forceUpdate();
  };

  const moveBottom = (index) => {
    let tmp = media[index];
    for (let i = index; i < media.length; i++) {
      media[i] = media[i + 1];
    }
    media[media.length - 1] = tmp;
    forceUpdate();
  };

  const moveHigher = (index) => {
    if (index <= 0) {
      return;
    }
    let tmp = media[index - 1];
    media[index - 1] = media[index];
    media[index] = tmp;
    forceUpdate();
  };

  const moveLower = (index) => {
    if (index >= media.length - 1) {
      return;
    }
    let tmp = media[index + 1];
    media[index + 1] = media[index];
    media[index] = tmp;
    forceUpdate();
  };

  return (
    <Box>
      <Typography sx={{ textAlign: "center" }} variant={"h5"}>
        Drag images up and down to reorder them
      </Typography>
      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid item xs={12} sm={6}>
          <DraggableList {...dragProps}>
            <ol>
              {media.map((item, index) => (
                <li key={index}>
                  <Box
                    sx={{
                      width: "30px",
                      display: "inline-flex",
                      flexDirection: "column",
                      position: "relative",
                      top: "-15px",
                    }}
                  >
                    <IconButton
                      sx={{ width: "10px", height: "10px", margin: 0 }}
                      color="primary"
                      aria-label="move top"
                      component="label"
                      onClick={() => {
                        moveTop(index);
                      }}
                    >
                      <KeyboardDoubleArrowUpIcon />
                    </IconButton>
                    <IconButton
                      sx={{ width: "10px", height: "10px", margin: 0 }}
                      color="primary"
                      aria-label="move higher"
                      component="label"
                      onClick={() => {
                        moveHigher(index);
                      }}
                    >
                      <KeyboardArrowUpIcon />
                    </IconButton>
                    <IconButton
                      sx={{ width: "10px", height: "10px", margin: 0 }}
                      color="primary"
                      aria-label="move lower"
                      component="label"
                      onClick={() => {
                        moveLower(index);
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                    <IconButton
                      sx={{ width: "10px", height: "10px", margin: 0 }}
                      color="primary"
                      aria-label="move bottom"
                      component="label"
                      onClick={() => {
                        moveBottom(index);
                      }}
                    >
                      <KeyboardDoubleArrowDownIcon />
                    </IconButton>
                  </Box>
                  <a href="#">
                    <img
                      alt={"media"}
                      style={{ height: "70px" }}
                      src={item.url}
                    />
                  </a>
                  <Button
                    sx={{
                      position: "relative",
                      float: "right",
                      marginTop: "10px",
                    }}
                    variant={"contained"}
                    onClick={() => removeMedia(item.id, index)}
                  >
                    Remove media
                  </Button>
                </li>
              ))}
            </ol>
          </DraggableList>
        </Grid>
      </Grid>
    </Box>
  );
}
