import * as React from 'react'
import Container from '@mui/material/Container'
import { useParams } from 'react-router-dom'
import LoggingRequired from '../../core/LoggingRequired'
import MediaPropertyComponent from './MediaPropertyComponent'

export default function MediaProperty () {
  let { propertyId } = useParams()

  return (
    <Container component="main" maxWidth="lg" sx={{ marginBottom: '80px' }}>
      <LoggingRequired/>
      <MediaPropertyComponent propertyId={propertyId}/>
    </Container>
  )
}