import './App.css'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'

export default function Error404 () {
  const navigate = useNavigate();
  //based on https://dribbble.com/shots/3913847-404-page

  let pageX = window.innerWidth
  let pageY = window.innerHeight
  let mouseY = 0
  let mouseX = 0

  document.addEventListener('mousemove', (event) => {
    //verticalAxis
    mouseY = event.pageY
    let yAxis = (pageY / 2 - mouseY) / pageY * 300
    //horizontalAxis
    mouseX = event.pageX / -pageX
    let xAxis = -mouseX * 100 - 100

    let element = document.getElementsByClassName('box__ghost-eyes')[0]
    if(element)
      element.style.transform = 'translate(' + xAxis + '%,-' + yAxis + '%)'

  })
  return (
    <div className="box">
      <div className="box__ghost">
        <div className="symbol"></div>
        <div className="symbol"></div>
        <div className="symbol"></div>
        <div className="symbol"></div>
        <div className="symbol"></div>
        <div className="symbol"></div>

        <div className="box__ghost-container">
          <div className="box__ghost-eyes">
            <div className="box__eye-left"></div>
            <div className="box__eye-right"></div>
          </div>
          <div className="box__ghost-bottom">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="box__ghost-shadow"></div>
      </div>

      <div className="box__description">
        <div className="box__description-container">
          <div className="box__description-title">Whoops!</div>
          <div className="box__description-text">It seems like we couldn't find the page you were looking
            for
          </div>
        </div>

        <Button onClick={() => navigate(-1)} variant="contained" color={'secondary'} sx={{ margin: '30px' }}>
          Take me Back
        </Button>
      </div>
    </div>
  )
}
