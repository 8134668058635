import * as React from "react";
import { useEffect } from "react";
import { Link as RouterLink, Navigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { getContractor, sendData } from "../../core/client";
import MediaListField from "./input/MediaListField";
import MediaField from "./input/MediaField";
import Typography from "@mui/material/Typography";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Editable from "../../core/Editable";
import { resizeFile } from "../../core/Images";

export default function MediaContractorComponent(contractorId) {
  contractorId = contractorId["contractorId"];
  const [success, setSuccess] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [media, setMedia] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    loadMediaList();
  }, [contractorId]);

  const loadMediaList = () => {
    if (contractorId) {
      getContractor(contractorId).then((data) => {
        setMedia(data["media"]);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("media", media);
    await sendData(
      `/contractors/media/reorder/${contractorId}`,
      { media: media },
      "post"
    );
    setSuccess(true);
  };

  const uploadImages = async () => {
    setLoading(true);
    let progressStep = 100 / files.length;
    let tmpProgress = 0;

    for (const file of files) {
      const resizedImage = await resizeFile(file.file);

      let formData = new FormData();
      formData.append("file", resizedImage);

      await sendData(
        `/contractors/media/upload/${contractorId}`,
        formData,
        "post"
      );

      tmpProgress += progressStep;
      setProgress(tmpProgress);
    }

    setSuccessMessage("Your files were successfully uploaded.");
    setOpen(true);
    setLoaded(false);
    setFiles([]);
    loadMediaList();
    setProgress(0);
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let i = 0;
  return (
    <>
      {successMessage !== "" && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}
      {success && <Navigate replace to={`/contractor/${contractorId}`} />}
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ValidationGroup>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: "100%" }}
          >
            <Typography
              sx={{ textAlign: "center" }}
              variant={"h5"}
              gutterBottom
            >
              Upload new images if needed
            </Typography>
            <Grid container spacing={2} sx={{ mt: 7, mb: 3 }}>
              <Grid item xs={12} sm={12} md={5} sx={{ textAlign: "center" }}>
                <MediaField
                  files={files}
                  setFiles={setFiles}
                  maxFiles={30}
                  minHeight={400}
                />
                {progress !== 0 && (
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ mt: 2 }}
                  />
                )}
                <AutoDisabler firstDisplayErrors={true}>
                  <LoadingButton
                    onClick={uploadImages}
                    variant="contained"
                    sx={{ mt: 3 }}
                    loading={loading}
                  >
                    Upload
                  </LoadingButton>
                </AutoDisabler>
              </Grid>
              <Grid
                item
                sm={12}
                md={7}
                sx={{ maxHeight: "60vh", overflowY: "scroll" }}
              >
                {loaded && <MediaListField media={media} />}
              </Grid>
            </Grid>
            <Button
              component={RouterLink}
              to={`/contractor/${contractorId}`}
              variant="outlined"
            >
              <Editable
                translationKey={`contractor:media_contractor:content${++i}`}
                defaultText={"Return to contractor page"}
              />
            </Button>
            <AutoDisabler firstDisplayErrors={true}>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, float: "right" }}
              >
                Confirm
              </Button>
            </AutoDisabler>
          </Box>
        </ValidationGroup>
      </Box>
    </>
  );
}
