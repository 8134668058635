import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { AutoDisabler, Validate, ValidationGroup } from "mui-validate";
import FirstNameField from "../users/input/FirstNameField";
import LastNameField from "../users/input/LastNameField";
import EmailField from "../users/input/EmailField";
import { isLogged, sendData } from "../../core/client";
import { Alert, Snackbar } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Editable from "../../core/Editable";

export default function Contact() {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [searchParams] = useSearchParams();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {};
    for (let p of formData) {
      let name = p[0];
      data[name] = p[1];
    }
    if (
      data["first_name"] === "" ||
      data["last_name"] === "" ||
      data["email"] === "" ||
      data["message"] === ""
    ) {
      return;
    }
    let response = await sendData("/users/contact", data, "post");
    if (!isNaN(response)) {
      setErrorMessage(
        "We have a problem. This is on our end, please try again later."
      );
    } else {
      setSuccessMessage(
        "Your message was successfully sent. We will get back to you as soon as possible."
      );
    }
    setOpen(true);
  };

  let i = 0;
  let j = 0;
  let k = 0;
  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ margin: "auto", marginTop: "100px", marginBottom: "100px" }}
    >
      {errorMessage !== "" && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            <Editable
              translationKey={`contact:error:content${++j}`}
              defaultText={
                "We have a problem. This is on our end, please try again later."
              }
            />
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {successMessage !== "" && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            <Editable
              translationKey={`contact:error:content${++k}`}
              defaultText={
                "Your message was successfully sent. We will get back to you as soon as possible."
              }
            />
          </Alert>
        </Snackbar>
      )}
      <Typography
        variant="h4"
        align="center"
        component="h1"
        sx={{ marginBottom: "50px" }}
      >
        <Editable
          translationKey={`contact:content${++i}`}
          defaultText={"Contact us"}
        />
      </Typography>
      <ValidationGroup>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FirstNameField profile={isLogged()} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LastNameField profile={isLogged()} />
            </Grid>
            <Grid item xs={12}>
              <EmailField profile={isLogged()} />
            </Grid>
            <Grid item xs={12}>
              <Validate name="message" required>
                <TextField
                  name="message"
                  required
                  fullWidth
                  id="message"
                  label={
                    <Editable
                      translationKey={`contact:content${++i}`}
                      defaultText={"Enter your message"}
                    />
                  }
                  autoFocus
                  multiline
                  defaultValue={searchParams.get("message")}
                  rows={10}
                />
              </Validate>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ marginTop: 20 }}
            display="flex"
            justifyContent="flex-end"
          >
            <Grid item>
              <AutoDisabler firstDisplayErrors={true}>
                <Button type="submit" fullWidth variant="contained">
                  <Editable
                    translationKey={`contact:content${++i}`}
                    defaultText={"Submit"}
                  />
                </Button>
              </AutoDisabler>
            </Grid>
          </Grid>
        </Box>
      </ValidationGroup>
    </Container>
  );
}
