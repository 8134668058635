import * as React from "react";
import { useState } from "react";
import { Validate } from "mui-validate";
import TextField from "@mui/material/TextField";
import Editable from "../../../core/Editable";

export default function CompanyNameField({ profile }) {
  const [value, setValue] = useState(
    localStorage.getItem("company_name")
      ? localStorage.getItem("company_name")
      : ""
  );
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Validate name="company_name">
      <TextField
        name="company_name"
        fullWidth
        id="companyName"
        label={
          <Editable
            translationKey={`users:fields:company_name`}
            defaultText={"Company Name"}
          />
        }
        value={profile ? value : undefined}
        onChange={handleChange}
      />
    </Validate>
  );
}
