import * as React from "react";
import { sendData } from "../../core/client";
import TextField from "@mui/material/TextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Editable from "../../core/Editable";
import Button from "@mui/material/Button";
import { Navigate } from "react-router-dom";

export default function NewThreadDialog({ openThread, setOpenThread, userId }) {
  const [threadTopic, setThreadTopic] = React.useState("");
  const [redirectMessages, setRedirectMessages] = React.useState(false);

  const handleCloseNewThread = () => {
    setOpenThread(false);
  };

  const handleCreateNewThread = async (owner_id) => {
    await sendData(
      "/users/thread",
      { recipient_id: owner_id, subject: threadTopic },
      "post"
    );
    setOpenThread(false);
    setRedirectMessages(true);
  };

  let i = 0;
  return (
    <Dialog open={openThread} onClose={handleCloseNewThread}>
      {redirectMessages && <Navigate replace to={`/messages`} />}
      <DialogTitle>
        <Editable
          translationKey={`messages:new-thread:content${++i}`}
          defaultText={"Contact person"}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Editable
            translationKey={`messages:new-thread:content${++i}`}
            defaultText={"What do you want to talk about?"}
          />
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label=<Editable
            translationKey={`messages:new-thread:content${++i}`}
            defaultText={"Subject"}
          />
          type="text"
          fullWidth
          value={threadTopic}
          onChange={(elt) => setThreadTopic(elt.target.value)}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseNewThread}>Cancel</Button>
        <Button
          onClick={() => {
            handleCreateNewThread(userId);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
