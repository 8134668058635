import * as React from 'react'
import { Validate } from 'mui-validate'
import TextField from '@mui/material/TextField'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Editable from '../../../core/Editable'

export default function PasswordField () {
  const [showPassword, setshowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  }

  return (
    <Validate name="password"
              regex={[/(?=\D*\d)(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z]).{8,}$/,
                'Passwords must have 8 characters including an uppercase letter, ' +
                'a lowercase letter and a digit.']}>
      <TextField
        required
        fullWidth
        name="password"
        label={
          <Editable
            translationKey={`users:fields:password2`}
            defaultText={"Password"}
          />}
        id="password"
        autoComplete="new-password"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}>
                {showPassword && <Visibility/>}
                {!showPassword && <VisibilityOff/>}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Validate>
  )
}