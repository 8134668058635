import * as React from 'react'
import TextField from '@mui/material/TextField'
import { Validate } from 'mui-validate'

export default function LanguageField ({ value }) {
  const [updated, setUpdated] = React.useState(value ? value : '')
  const handleChange = (event) => {
    setUpdated(event.target.value)
  }
  return (
    <Validate language="language" required>
      <TextField
        name="languages"
        fullWidth
        id="languages"
        label="Spoken languages"
        value={updated}
        onChange={handleChange}
      />
    </Validate>
  )
}