import * as React from 'react'
import { useEffect, useRef } from 'react'
import Container from '@mui/material/Container'
import { Link as RouterLink, Navigate, useParams, useSearchParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import { Alert, Link, Snackbar, Step, StepLabel, Stepper } from '@mui/material'
import { getProperty, sendData } from '../../core/client'
import Typography from '@mui/material/Typography'
import { BASE_LATITUDE, BASE_LONGITUDE, useForceUpdate } from '../../core/default'
import LoggingRequired from '../../core/LoggingRequired'
import PropertyFirstStep from './steps/PropertyFirstStep'
import PropertyThirdStep from './steps/PropertyThirdStep'
import PropertySecondStep from './steps/PropertySecondStep'
import extractFormDataToObject from '../../core/forms'
import Editable from '../../core/Editable'
import MediaPropertyComponent from './MediaPropertyComponent'

export default function AddProperty () {
  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [latitude, setLatitude] = React.useState(BASE_LATITUDE)
  const [longitude, setLongitude] = React.useState(BASE_LONGITUDE)
  const [city, setCity] = React.useState()
  let step = useRef(0)
  const [activeStep, setActiveStep] = React.useState(step.current ? step.current : 0)
  let { propertyId } = useParams()
  let id = useRef(propertyId)
  let savedData = useRef({})
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (id.current) {
      getProperty(propertyId).then((data) => {
        savedData.current = {
          'address': data['address'],
          'area': data['area'],
          'plotArea': data['plot_area'],
          'guests': [data['min_guests'], data['max_guests']],
          'licence': data['touristic_licence'],
          'name': data['name'],
          'latitude': data['latitude'],
          'longitude': data['longitude'],
          'amenities': data['amenities'],
          'type': data['type'],
          'bedroomsNumber': data['bedrooms_number'],
          'bathroomsNumber': data['bathrooms_number'],
          'description': data['description'],
        }
        setLatitude(data['latitude'])
        setLongitude(data['longitude'])
        setRefresh(true)
      })
    }
  }, [propertyId])

  useEffect(() => {
    if(searchParams.get('step') === ''){
      setSearchParams({ 'step': '0' })
      return
    }

    if(!savedData.current['plot_area'] && searchParams.get('step') !== '0'){
      setSearchParams({ 'step': '0' })
      setActiveStep(0)
    }
  }, [])

  useEffect(() => {
    if (!refresh) {
      return
    }
    setRefresh(false)
  }, [refresh])

  useEffect(() => {
    let stepParam = searchParams.get('step')
    if (stepParam) {
      if (savedData.current === {}) {
        setActiveStep(0)
      } else {
        if (id.current || parseInt(stepParam) < 3) {
          setActiveStep(parseInt(stepParam))
        } else {
          setActiveStep(0)
        }
      }
    }
  }, [searchParams])

  const handleBack = () => {
    if (activeStep !== 0) {
      setSearchParams({ 'step': (activeStep - 1).toString() })
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const stepClick = (event, index) => {
    event.preventDefault()
    if (index <= activeStep || id.current !== undefined) {
      setSearchParams({ 'step': index.toString() })
      setActiveStep(index)
    }
  }

  const handlePropertyType = (event, newPropertyType) => {
    if (newPropertyType === null) {
      return
    }
    savedData.current['type'] = newPropertyType
    forceUpdate()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = extractFormDataToObject(event)
    savedData.current = {
      ...savedData.current,
      ...data
    }
    if (activeStep === 2) {
      savedData.current['plot_area'] = savedData.current['plotArea']
      savedData.current['bedrooms_number'] = savedData.current['bedroomsNumber']
      savedData.current['bathrooms_number'] = savedData.current['bathroomsNumber']
      savedData.current['plot_area'] = savedData.current['plotArea']
      savedData.current['min_guests'] = savedData.current['guests'][0]
      savedData.current['max_guests'] = savedData.current['guests'][1]
      savedData.current['touristic_licence'] = savedData.current['licence']
      if (!savedData.current['amenities']) {
        savedData.current['amenities'] = []
      }
      if (!savedData.current['type']) {
        savedData.current['type'] = 'villa'
      }
      let response
      if (id.current) {
        response = await sendData(`/properties/update/${id.current}`, savedData.current, 'put')
      } else {
        response = await sendData('/properties/create', savedData.current, 'post')
      }
      if (!isNaN(response)) {
        setErrorMessage('We have a problem. This is on our end, please try again later.')
      } else {
        setSuccessMessage('Property successfully created.')
      }
      setOpen(true)
      if (!id.current) {
        id.current = response.data['id']
      }
      if (propertyId) {
        setSuccess(true)
      } else {
        setSearchParams({ 'step': activeStep + 1 })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else if (activeStep === 0) {
      if (savedData.current['address'] === '' || savedData.current['name'] === '') {
        return
      }
      savedData.current['address'] = savedData.current['address'].trim()
      setSearchParams({ 'step': activeStep + 1 })
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else if (activeStep === 1) {
      setSearchParams({ 'step': activeStep + 1 })
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const mapCallback = (longitude, latitude) => {
    setLongitude(longitude)
    setLatitude(latitude)
  }

  let i = 0
  let j = 0
  let k = 0
  return (
    <Container component="main" maxWidth="lg" sx={{ p: '20px', background: 'white' }}>
      <LoggingRequired/>
      {success && <Navigate replace to="/properties"/>}
      <CssBaseline/>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>
            <Link component={RouterLink} to={`/add-property?step=0`}
                  onClick={(event) => stepClick(event, 0)}>
              {<Editable
                translationKey={`property:add_property:content${++i}`}
                defaultText={'Enter basic information'}
              />}
            </Link></StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Link component={RouterLink} to={`/add-property?step=1`}
                  onClick={(event) => stepClick(event, 1)}>
              {<Editable
                translationKey={`property:add_property:content${++i}`}
                defaultText={'Add a nice description'}
              />}
            </Link></StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Link component={RouterLink} to={`/add-property?step=2`}
                  onClick={(event) => stepClick(event, 2)}>
              {<Editable
                translationKey={`property:add_property:content${++i}`}
                defaultText={'Tag your property'}
              />}
            </Link></StepLabel>
        </Step>
        {!propertyId && <Step>
          <StepLabel optional={<Typography variant="caption"><Editable
            translationKey={`property:add_property:content${++i}`}
            defaultText={'Optional'}
          /></Typography>}>
            <Link component={RouterLink} to={`/add-property?step=3`}
                  onClick={(event) => stepClick(event, 3)}>
              {<Editable
                translationKey={`property:add_property:content${++i}`}
                defaultText={'Add media'}
              />}
            </Link></StepLabel>
        </Step>}
      </Stepper>
      {errorMessage !== '' &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            <Editable
              translationKey={`property:add_property:error:content${++j}`}
              defaultText={'We have a problem. This is on our end.'}
            />
          </Alert>
        </Snackbar>
      }
      {successMessage !== '' &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            <Editable
              translationKey={`property:add_property:success:content${++k}`}
              defaultText={'Your property was successfully created.'}
            />
          </Alert>
        </Snackbar>
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width:'100%'
        }}
      >
        {activeStep === 0 && !refresh &&
          <PropertyFirstStep
            savedData={savedData}
            handlePropertyType={handlePropertyType}
            longitude={longitude}
            city={city}
            latitude={latitude}
            mapCallback={mapCallback}
            setCity={setCity}
            setLongitude={setLongitude}
            setLatitude={setLatitude}
            errorMessage={errorMessage}
            handleSubmit={handleSubmit}
          />
        }
        {activeStep === 1 &&
          <PropertySecondStep
            savedData={savedData}
            errorMessage={errorMessage}
            handleSubmit={handleSubmit}
            handleBack={handleBack}
          />
        }
        {activeStep === 2 &&
          <PropertyThirdStep
            savedData={savedData}
            errorMessage={errorMessage}
            handleSubmit={handleSubmit}
            handleBack={handleBack}
            id={id}
          />
        }
        {activeStep === 3 &&
          <MediaPropertyComponent propertyId={id.current}/>
        }
      </Box>
    </Container>
  )
}