import Resizer from "react-image-file-resizer";

export const resizeFile = (file, maxWidth = 3000, maxHeight = 1500) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
