import * as React from "react";
import { useEffect } from "react";
import { getContractor, sendData } from "../../core/client";
import {
  Link,
  Link as RouterLink,
  Navigate,
  useParams,
} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import { BASE_CONTRACTOR_IMAGE } from "../../core/default";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import LoggingRequired from "../../core/LoggingRequired";
import Editable from "../../core/Editable";
import MediaListComponent from "../../core/components/MediaListComponent";
import LazyBackground from "../../core/LazyBackground";

function Contractor() {
  const [contractor, setContractor] = React.useState();
  let { contractorId } = useParams();
  let userType = localStorage.getItem("type");
  if (localStorage.getItem("optinsms") === "true") {
    userType = "admin";
  }
  const [successMessage, setSuccessMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [goBack, setGoBack] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [showImages, setShowImages] = React.useState(false);

  let i = 0;
  let j = 0;

  const handleConfirmDelete = () => {
    confirmContractorDelete();
    setConfirmDelete(false);
  };

  const deleteContractor = (event) => {
    setConfirmDelete(true);
  };

  const confirmContractorDelete = (event) => {
    sendData(`/contractors/delete/${contractorId}`, {}, "delete").then(
      (response) => {
        setGoBack(true);
      }
    );
  };

  const copyLinkToClipboard = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setSuccessMessage(
      "Contractor link copied to clipboard. Use paste or CTRL + V to retrieve it."
    );
    setSuccess(true);
  };

  const handleCancelConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const dismissSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  useEffect(() => {
    getContractor(contractorId).then((data) => {
      if (data.media.length === 0) {
        data.media = [
          {
            url: BASE_CONTRACTOR_IMAGE[data.type_key],
          },
        ];
      }
      setContractor(data);
    });
  }, [contractorId]);

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{ marginBottom: "80px", paddingLeft: 0 }}
    >
      <LoggingRequired />
      {goBack && <Navigate replace to={`/services`} />}
      {successMessage !== "" && (
        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={dismissSuccess}
        >
          <Alert
            onClose={dismissSuccess}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}
      {contractor && (
        <Box>
          <Grid
            container
            spacing={2}
            sx={{ margin: "auto", marginTop: "3vh", marginBottom: "7vh" }}
          >
            <Grid item xs={12} sm={12} lg={7}>
              <Box sx={{ position: "relative", m: "10px" }}>
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "55vh",
                    zIndex: 2,
                    pointerEvents: "none",
                    backgroundPosition: "center",
                    background:
                      "linear-gradient(0deg, rgba(0,0,0,0.9072361366421569) 0%, rgba(0,0,0,0.013678713672969134) 70%)",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      padding: "20px",
                      pointerEvents: "auto",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ margin: "5px", color: "white" }}
                    >
                      {contractor.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ margin: "5px", color: "white" }}
                    >
                      {contractor.type}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowImages(true);
                  }}
                >
                  <Carousel
                    navButtonsAlwaysVisible={true}
                    autoPlay={false}
                    sx={{ height: "55vh" }}
                  >
                    {contractor.media.map((image, i) => (
                      <LazyBackground
                        src={image.url}
                        key={i}
                        width={"100%"}
                        height={"55vh"}
                      />
                    ))}
                  </Carousel>
                </Box>
                <MediaListComponent
                  images={contractor.media}
                  open={showImages}
                  setOpen={setShowImages}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <Box
                sx={{
                  background: "white",
                  padding: "30px",
                  boxShadow: 2,
                  height: "100%",
                  m: "10px",
                }}
              >
                <Typography gutterBottom variant="h3" component="div">
                  {contractor.name}
                </Typography>
                <Typography variant="h4" gutterBottom color="text.secondary">
                  {contractor.type}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <b>
                    <Editable
                      translationKey={`contractor:content${++i}`}
                      defaultText={"Price:"}
                    />
                  </b>{" "}
                  {contractor.price}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <b>
                    <Editable
                      translationKey={`contractor:content${++i}`}
                      defaultText={"Language:"}
                    />
                  </b>{" "}
                  {contractor.languages}
                </Typography>
                <Box sx={{ mt: "20px" }}>
                  <Button
                    component={Link}
                    sx={{ m: "10px" }}
                    size="small"
                    to={`/contact?message=I would like to book ${contractor.name}.`}
                    variant="contained"
                    color="primary"
                  >
                    <Editable
                      translationKey={`contractor:content${++i}`}
                      defaultText={"Book"}
                    />
                  </Button>
                  <Button
                    variant={"outlined"}
                    sx={{ m: "10px" }}
                    size="small"
                    onClick={copyLinkToClipboard}
                  >
                    <Editable
                      translationKey={`contractor:content${++i}`}
                      defaultText={"Share"}
                    />
                  </Button>
                  {["admin"].includes(userType) && (
                    <>
                      <Button
                        variant={"outlined"}
                        sx={{ m: "10px" }}
                        size="small"
                        component={RouterLink}
                        to={`/edit-contractor/${contractor.id}`}
                      >
                        <Editable
                          translationKey={`contractor:content${++i}`}
                          defaultText={"Edit"}
                        />
                      </Button>
                      <Button
                        variant={"outlined"}
                        sx={{ m: "10px" }}
                        size="small"
                        component={RouterLink}
                        to={`/media-contractor/${contractor.id}`}
                      >
                        <Editable
                          translationKey={`contractor:content${++i}`}
                          defaultText={"Manage media"}
                        />
                      </Button>
                      <Button
                        variant={"outlined"}
                        sx={{ m: "10px" }}
                        size="small"
                        component={RouterLink}
                        onClick={deleteContractor}
                      >
                        <Editable
                          translationKey={`contractor:content2${++j}`}
                          defaultText={"Delete"}
                        />
                      </Button>
                    </>
                  )}

                  <Dialog
                    open={confirmDelete}
                    onClose={handleCancelConfirmDelete}
                    aria-labelledby="draggable-dialog-title"
                  >
                    <DialogTitle
                      style={{ cursor: "move" }}
                      id="draggable-dialog-title"
                    >
                      Delete contractor
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Editable
                          translationKey={`contractor:content${++i}`}
                          defaultText={
                            "Are you sure you wish to delete all data associated to this contractor?\n" +
                            "There is no turning back."
                          }
                        />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleCancelConfirmDelete}>
                        Cancel
                      </Button>
                      <Button onClick={handleConfirmDelete}>
                        <Editable
                          translationKey={`contractor:content${++i}`}
                          defaultText={"Confirm"}
                        />
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Box>
            </Grid>

            {contractor.description &&
              contractor.description !== "<p></p>\n" && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: "white",
                      padding: "30px",
                      boxShadow: 2,
                      height: "100%",
                      m: "10px",
                    }}
                  >
                    <Typography gutterBottom variant="h4" component="div">
                      <Editable
                        translationKey={`contractor:content${++i}`}
                        defaultText={"Description"}
                      />
                    </Typography>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: contractor.description,
                      }}
                    />
                  </Box>
                </Grid>
              )}

            {contractor.internal_notes && userType === "admin" && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    background: "white",
                    padding: "30px",
                    boxShadow: 2,
                    height: "100%",
                    m: "10px",
                  }}
                >
                  <Typography gutterBottom variant="h4" component="div">
                    <Editable
                      translationKey={`contractor:content${++i}`}
                      defaultText={"Internal notes"}
                    />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <b></b> {contractor.internal_notes}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Container>
  );
}

export default Contractor;
