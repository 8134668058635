import * as React from "react";
import { useEffect } from "react";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  alpha,
  Fab,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  styled,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { getData, sendData } from "../../core/client";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { BASE_IMAGE, slugify } from "../../core/default";
import Container from "@mui/material/Container";
import LoggingRequired from "../../core/LoggingRequired";
import MapIcon from "@mui/icons-material/Map";
import ListIcon from "@mui/icons-material/List";
import Tooltip from "@mui/material/Tooltip";
import PropertyMap from "./PropertyMap";
import Calendars from "./Calendars";
import Editable from "../../core/Editable";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import etv from "../../static/images/etv.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Konami from "react-konami-code";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const propertyTheme = createTheme({
  palette: {
    primary: {
      light: "#00CFC3",
      main: "#003C2D",
      dark: "#2B514C",
    },
    secondary: {
      light: "#00CFC3",
      main: "#00CFC3",
      dark: "#00CFC3",
    },
    background: {
      default: "#FCFCFC",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1400,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "251px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function Properties() {
  const [area, setArea] = React.useState([0, 10000]);
  const [plotArea, setPlotArea] = React.useState([0, 50000]);
  const [minGuests, setMinGuests] = React.useState([1, 40]);
  const [maxGuests, setMaxGuests] = React.useState([1, 40]);
  const [amenities, setAmenities] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [allProperties, setAllProperties] = React.useState([]);
  const [listView, setListView] = React.useState(true);
  const [mapView, setMapView] = React.useState(false);
  const [calendarView, setCalendarView] = React.useState(false);
  const [availableAmenities, setAvailableAmenities] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [availableDates, setAvailableDates] = React.useState([]);
  const [minLatitude, setMinLatitude] = React.useState(999);
  const [minLongitude, setMinLongitude] = React.useState(999);
  const [maxLatitude, setMaxLatitude] = React.useState(-999);
  const [maxLongitude, setMaxLongitude] = React.useState(-999);
  const [listViewClick, setListViewClick] = React.useState(0);
  const [url, setUrl] = React.useState(0);
  const [locale] = React.useState(localStorage.getItem("locale"));
  let userType = localStorage.getItem("type");
  if (localStorage.getItem("optinsms") === "true") {
    userType = "admin";
  }

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  function easterEgg() {
    getData(`/properties?locale=${locale}`).then((response) => {
      setProperties(response.data);
      setAllProperties(response.data);
    });
  }

  async function getAmenities() {
    return await getData(`/properties/get-amenities?locale=${locale}`).then(
      (response) => {
        return response.data;
      }
    );
  }

  async function getProperties() {
    let url = `/properties/owned?locale=${locale}`;
    if (userType === "agent") {
      url = `/properties/subscribed?locale=${locale}`;
    }
    return await getData(url).then((response) => {
      return response.data;
    });
  }

  const computeBoundingBox = () => {
    let tmpMinLongitude = 999,
      tmpMinLatitude = 999,
      tmpMaxLongitude = -999,
      tmpMaxLatitude = -999;
    for (let property of properties) {
      if (property["longitude"] < tmpMinLongitude) {
        tmpMinLongitude = property["longitude"];
      }
      if (property["latitude"] < tmpMinLatitude) {
        tmpMinLatitude = property["latitude"];
      }
      if (property["longitude"] > tmpMaxLongitude) {
        tmpMaxLongitude = property["longitude"];
      }
      if (property["latitude"] > tmpMaxLatitude) {
        tmpMaxLatitude = property["latitude"];
      }
    }
    setMinLongitude(tmpMinLongitude);
    setMinLatitude(tmpMinLatitude);
    setMaxLongitude(tmpMaxLongitude);
    setMaxLatitude(tmpMaxLatitude);
  };

  useEffect(() => {
    getAmenities().then((data) => {
      setAvailableAmenities(data);
    });
    getProperties().then((data) => {
      setProperties(data);
      setAllProperties(data);
    });
  }, []);

  useEffect(() => {
    computeBoundingBox();
  }, [properties]);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  useEffect(() => {
    applyFilters();
  }, [
    area,
    plotArea,
    minGuests,
    maxGuests,
    amenities,
    search,
    JSON.stringify(availableDates),
  ]);

  const handleArea = (event) => {
    setArea(event.target.value);
  };

  const handlePlotArea = (event) => {
    setPlotArea(event.target.value);
  };
  const handleMinGuests = (event) => {
    setMinGuests(event.target.value);
  };
  const handleMaxGuests = (event) => {
    setMaxGuests(event.target.value);
  };
  const handleAmenities = (event) => {
    setAmenities(event.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const duplicateProperty = async (event, id) => {
    let response = await sendData(`/properties/duplicate/${id}`, {}, "post");
    window.location.href = `/property/${response.data.id}`;
  };

  // I hope no one has to read this code ever again (including myself)
  const applyFilters = () => {
    let filteredProperties = allProperties;
    let results = [];
    let i = 0;
    let filtered;
    if (search !== "") {
      for (const word of search.match(/\b(\w+)\b/g)) {
        results[i] = [];
        for (const property of filteredProperties) {
          searchLoop: for (const prop in property) {
            const searchable = property[prop];
            if (
              typeof searchable === "string" ||
              searchable instanceof String
            ) {
              if (searchable.toLowerCase().includes(word.toLowerCase())) {
                results[i].push(property);
                break;
              }
            } else if (Array.isArray(searchable)) {
              for (const searchableString of searchable) {
                if (
                  typeof searchableString === "string" ||
                  searchableString instanceof String
                ) {
                  if (
                    searchableString.toLowerCase().includes(word.toLowerCase())
                  ) {
                    results[i].push(property);
                    break searchLoop;
                  }
                }
              }
            }
          }
        }
        i++;
      }
      filtered = results.reduce((a, b) => a.filter((c) => b.includes(c)));
    } else {
      filtered = allProperties;
    }
    for (const tmp of filtered) {
      if (!(area[0] <= tmp["area"] && tmp["area"] <= area[1])) {
        filtered = filtered.filter(function (obj) {
          return obj.id !== tmp.id;
        });
      }
      if (
        !(plotArea[0] <= tmp["plot_area"] && tmp["plot_area"] <= plotArea[1])
      ) {
        filtered = filtered.filter(function (obj) {
          return obj.id !== tmp.id;
        });
      }
      if (
        !(
          minGuests[0] <= tmp["min_guests"] && tmp["min_guests"] <= minGuests[1]
        )
      ) {
        filtered = filtered.filter(function (obj) {
          return obj.id !== tmp.id;
        });
      }
      if (
        !(
          maxGuests[0] <= tmp["max_guests"] && tmp["max_guests"] <= maxGuests[1]
        )
      ) {
        filtered = filtered.filter(function (obj) {
          return obj.id !== tmp.id;
        });
      }
      if (!amenities.every((v) => tmp.amenities.includes(v))) {
        filtered = filtered.filter(function (obj) {
          return obj.id !== tmp.id;
        });
      }
    }
    if (availableDates && availableDates.length > 0) {
      for (const tmp of filtered) {
        for (const calendar of tmp["calendar"]) {
          if (
            calendar["status"] !== "booked" &&
            calendar["status"] !== "unavailable"
          ) {
            continue;
          }
          let dateBegin = new Date(calendar["date_begin"]);
          let dateEnd = new Date(calendar["date_end"]);
          if (dateBegin >= availableDates[1]) {
            continue;
          }
          if (dateEnd <= availableDates[0]) {
            continue;
          }
          filtered = filtered.filter(function (obj) {
            return obj.id !== tmp.id;
          });
        }
      }
    }
    setProperties(filtered);
  };

  const datePickerChange = (value) => {
    setAvailableDates(value);
  };

  let i = 0;
  let j = 0;
  return (
    <>
      <Konami action={easterEgg} />
      <ThemeProvider theme={propertyTheme}>
        <LoggingRequired />
        <AppBar
          position="static"
          sx={{
            paddingTop: "30px",
            paddingBottom: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Toolbar>
            <Grid container spacing={2}>
              {availableAmenities !== [] && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  sx={{ marginTop: "10px" }}
                >
                  <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    <FormControl sx={{ width: "90%" }}>
                      <InputLabel id="demo-multiple-name-label">
                        <Editable
                          translationKey={`properties:content${++i}`}
                          defaultText={"Amenities"}
                        />
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        size={"small"}
                        value={amenities}
                        onChange={handleAmenities}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {availableAmenities.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, amenities, propertyTheme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <IconButton
                      type="submit"
                      onClick={() => {
                        setAmenities([]);
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </ThemeProvider>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                sx={{ marginTop: "10px", mb: { sm: 4 } }}
              >
                <DateRangePicker
                  style={{ width: "100%", background: "transparent" }}
                  size="lg"
                  placeholder={
                    locale === "en"
                      ? "Availability"
                      : locale === "fr"
                      ? "Disponibilités"
                      : "Disponibilidad"
                  }
                  onChange={datePickerChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                sx={{ marginTop: "10px", mb: { sm: 4 } }}
              >
                <Search sx={{ width: "100%" }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    name={"search"}
                    placeholder={
                      locale === "en"
                        ? "Search..."
                        : locale === "fr"
                        ? "Rechercher..."
                        : "Buscar..."
                    }
                    onChange={handleSearch}
                    value={search}
                  />
                </Search>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{
                  my: "10px",
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Box sx={{ mx: "30px" }}>
                  <Slider
                    getAriaLabel={() => "Area"}
                    value={area}
                    onChange={handleArea}
                    valueLabelDisplay="on"
                    color="secondary"
                    min={0}
                    max={5000}
                  />
                  <Typography variant={"body1"}>
                    <Editable
                      translationKey={`properties:content${++i}`}
                      defaultText={"Area"}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{
                  my: "10px",
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Box sx={{ mx: "30px" }}>
                  <Slider
                    getAriaLabel={() => "PlotArea"}
                    value={plotArea}
                    onChange={handlePlotArea}
                    valueLabelDisplay="on"
                    color="secondary"
                    min={0}
                    max={50000}
                  />
                  <Typography variant={"body1"}>
                    <Editable
                      translationKey={`properties:content${++i}`}
                      defaultText={"Plot Area"}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{
                  my: "10px",
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Box sx={{ mx: "30px" }}>
                  <Slider
                    getAriaLabel={() => "Maximum number of guests"}
                    value={minGuests}
                    onChange={handleMinGuests}
                    valueLabelDisplay="on"
                    color="secondary"
                    min={1}
                    max={40}
                  />
                  <Typography variant={"body1"}>
                    <Editable
                      translationKey={`properties:content${++i}`}
                      defaultText={"Min. number of guests"}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{
                  my: "10px",
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Box sx={{ mx: "30px" }}>
                  <Slider
                    getAriaLabel={() => "Minimum number of guests"}
                    value={maxGuests}
                    onChange={handleMaxGuests}
                    valueLabelDisplay="on"
                    color="secondary"
                    min={1}
                    max={40}
                  />
                  <Typography variant={"body1"}>
                    <Editable
                      translationKey={`properties:content${++i}`}
                      defaultText={"Max. number of guests"}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  right: "0",
                  top: "-30px",
                }}
              >
                <Tooltip title="Switch to list view">
                  <IconButton
                    onClick={() => {
                      setListView(true);
                      setMapView(false);
                      setCalendarView(false);
                      setListViewClick(listViewClick + 1);
                      if (listViewClick > 10) {
                        easterEgg();
                      }
                    }}
                  >
                    <ListIcon
                      sx={{ color: listView ? "#FCFCFC" : "#989898" }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Switch to map view">
                  <IconButton
                    onClick={() => {
                      setListView(false);
                      setMapView(true);
                      setCalendarView(false);
                    }}
                  >
                    <MapIcon sx={{ color: mapView ? "#FCFCFC" : "#989898" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Switch to calendar view">
                  <IconButton
                    onClick={() => {
                      setListView(false);
                      setMapView(false);
                      setCalendarView(true);
                    }}
                  >
                    <CalendarMonthIcon
                      sx={{ color: calendarView ? "#FCFCFC" : "#989898" }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Toolbar>
        </AppBar>

        <Container
          component="main"
          maxWidth="xl"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {!["owner", "admin"].includes(userType) &&
            properties.length === 0 && (
              <>
                <Typography
                  variant={"h4"}
                  color={"gray"}
                  sx={{
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "20vh",
                  }}
                >
                  <Editable
                    translationKey={`properties:empty:content${++j}`}
                    defaultText={"You haven't subscribed to a property yet."}
                  />
                </Typography>

                <TextField
                  sx={{
                    margin: "auto",
                    mt: "40px",
                    maxWidth: "400px",
                  }}
                  name="propertyUrl"
                  fullWidth
                  id="propertyUrl"
                  label={
                    <Editable
                      translationKey={`properties:empty:fields:content`}
                      defaultText={"Paste here your property URL"}
                    />
                  }
                  onChange={(event) => {
                    setUrl(event.target.value);
                  }}
                />
                <Box>
                  <Button
                    sx={{ m: "30px", marginBottom: "30vh" }}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.href = url;
                    }}
                  >
                    <Editable
                      translationKey={`properties:empty:fields:content2`}
                      defaultText={"Confirm"}
                    />
                  </Button>
                </Box>
              </>
            )}
          <ThemeProvider theme={propertyTheme}>
            <CssBaseline />
            {(properties.length !== 0 ||
              ["owner", "admin"].includes(userType)) &&
              listView && (
                <Box
                  sx={{
                    minHeight: "60vh",
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(2, 1fr)",
                      md: "repeat(3, 1fr)",
                      lg: "repeat(4, 1fr)",
                    },
                    [`& .${imageListItemClasses.root}`]: {
                      display: "flex",
                      flexDirection: "column",
                    },
                  }}
                >
                  {["owner", "admin"].includes(userType) && (
                    <Link to={"/add-property"}>
                      <ImageListItem sx={{ margin: "20px" }}>
                        <Box
                          sx={{
                            width: "100%",
                            height: "27vh",
                            position: "relative",
                            border: "solid thin gray",
                          }}
                        >
                          <Fab
                            color="secondary"
                            aria-label="add"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              margin: "-60px 0 0 -33px",
                              zIndex: 2,
                            }}
                          >
                            <AddIcon />
                          </Fab>
                        </Box>
                        <ImageListItemBar
                          title={
                            <Editable
                              translationKey={`properties:content${++i}`}
                              defaultText={"Add a new property"}
                            />
                          }
                          subtitle={
                            <Editable
                              translationKey={`properties:content${++i}`}
                              defaultText={"Click here to add a new property."}
                            />
                          }
                        />
                      </ImageListItem>
                    </Link>
                  )}
                  {properties.map((item) => (
                    <Link
                      to={`/property/${item.id}/${slugify(item.name)}`}
                      key={item.id}
                    >
                      <ImageListItem sx={{ margin: "20px" }}>
                        <img
                          src={
                            item.media[0]
                              ? `${item.media[0].url}?w=248&fit=crop&auto=format`
                              : BASE_IMAGE
                          }
                          srcSet={
                            item.media[0]
                              ? `${item.media[0].url}?w=248&fit=crop&auto=format&dpr=2 2x`
                              : BASE_IMAGE
                          }
                          alt={item.name}
                          style={{ width: "100%", height: "27vh" }}
                          loading="lazy"
                        />
                        {item.touristic_licence && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              width: "40px",
                            }}
                          >
                            <Tooltip title={item.touristic_licence}>
                              <img
                                style={{ width: "100%", height: "auto" }}
                                src={etv}
                              />
                            </Tooltip>
                          </Box>
                        )}
                        {["owner", "admin"].includes(userType) && (
                          <ImageListItemBar
                            title={item.name}
                            subtitle={item.address}
                            actionIcon={
                              <Box sx={{ width: "80px" }}>
                                <Tooltip title="Duplicate property">
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                                    aria-label={`copy ${item.name}`}
                                    onClick={(event) => {
                                      duplicateProperty(event, item.id);
                                    }}
                                  >
                                    <ContentCopyIcon />
                                  </IconButton>
                                </Tooltip>
                                <Link
                                  to={`/edit-property/${item.id}/${slugify(
                                    item.name
                                  )}`}
                                >
                                  <Tooltip title="Edit property">
                                    <IconButton
                                      sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                                      aria-label={`edit ${item.name}`}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </Box>
                            }
                          />
                        )}
                        {!["owner", "admin"].includes(userType) && (
                          <ImageListItemBar
                            title={item.name}
                            subtitle={item.address}
                          />
                        )}
                      </ImageListItem>
                    </Link>
                  ))}
                </Box>
              )}
            {mapView && (
              <Box
                sx={{
                  maxWidth: "1600px",
                  textAlign: "center",
                  height: "65vh",
                  margin: "auto",
                  marginTop: "3vh",
                }}
              >
                <PropertyMap
                  properties={properties}
                  minLatitude={minLatitude}
                  minLongitude={minLongitude}
                  maxLatitude={maxLatitude}
                  maxLongitude={maxLongitude}
                  preserveDrawingBuffer={false}
                />
              </Box>
            )}
          </ThemeProvider>
        </Container>
      </ThemeProvider>
      {calendarView && (
        <Box sx={{ margin: "auto" }}>
          <Calendars properties={properties} />
        </Box>
      )}
    </>
  );
}
