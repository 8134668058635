import * as React from "react";
import { IconButton, ImageList, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import ImageListItem from "@mui/material/ImageListItem";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function MediaListComponent({ open, images, setOpen }) {
  const handleClose = () => {
    setOpen(false);
    setOpenZoom(false);
  };
  const handleCloseZoom = () => setOpenZoom(false);
  const [openZoom, setOpenZoom] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState();

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "90vw",
            height: "90vh",
            margin: "auto",
            background: "white",
            p: "80px",
            overflowY: "auto",
            mt: "40px",
            position: "relative",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: "10px", right: "10px" }}
            size={"large"}
            aria-label="close"
            color="primary"
            onClick={handleClose}
          >
            <CloseIcon sx={{ fontSize: "32px" }} />
          </IconButton>
          <ImageList variant="masonry" cols={3} gap={8}>
            {images.map((image, key) => (
              <ImageListItem
                key={image.url}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setCurrentImage(key);
                  setOpenZoom(true);
                }}
              >
                <img
                  src={image.url}
                  srcSet={image.url}
                  alt={"contractor"}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Modal>
      <Modal
        open={openZoom}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "90vw",
            height: "90vh",
            margin: "auto",
            background: "white",
            p: "80px",
            overflowY: "auto",
            mt: "40px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: "10px", right: "10px" }}
            size={"large"}
            aria-label="close"
            color="primary"
            onClick={handleClose}
          >
            <CloseIcon sx={{ fontSize: "32px" }} />
          </IconButton>
          <IconButton
            sx={{ position: "absolute", top: "10px", left: "10px" }}
            size={"large"}
            aria-label="close"
            color="primary"
            onClick={handleCloseZoom}
          >
            <ArrowBackIcon sx={{ fontSize: "32px" }} />
          </IconButton>
          {currentImage > 0 && (
            <IconButton
              sx={{ position: "absolute", top: "50%", left: "10px" }}
              size={"large"}
              aria-label="close"
              color="primary"
              onClick={() => {
                setCurrentImage(currentImage - 1);
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: "32px" }} />
            </IconButton>
          )}
          {currentImage < images.length - 1 && (
            <IconButton
              sx={{ position: "absolute", top: "50%", right: "10px" }}
              size={"large"}
              aria-label="close"
              color="primary"
              onClick={() => {
                setCurrentImage(currentImage + 1);
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "32px" }} />
            </IconButton>
          )}
          {images[currentImage] && (
            <img
              src={images[currentImage].url}
              srcSet={images[currentImage].url}
              alt={"contractor"}
              style={{
                margin: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
              loading="lazy"
            />
          )}
        </Box>
      </Modal>
    </>
  );
}
