import './App.css'
import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { isLogged } from './client'

export default function LoggingRequired () {
  return (
    <>
      {!isLogged() &&
        <Navigate replace to={`/sign-in?redirect=${encodeURIComponent(window.location.pathname)}`}/>}
    </>
  )
}
