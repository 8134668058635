import * as React from 'react'
import { sendData } from '../../core/client'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import Editable from '../../core/Editable'

export default function DeleteProperty ({ confirmDelete, setConfirmDelete, setGoBack, propertyId }) {

  const handleCancelConfirmDelete = () => {
    setConfirmDelete(false)
  }

  const handleConfirmDelete = () => {
    confirmPropertyDelete()
    setConfirmDelete(false)
  }

  const confirmPropertyDelete = (event) => {
    sendData(`/properties/delete/${propertyId}`, {}, 'delete').then((response) => {
      setGoBack(true)
    })
  }

  let i = 0
  return (

    <Dialog
      open={confirmDelete}
      onClose={handleCancelConfirmDelete}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Editable
          translationKey={`property:delete:content${++i}`}
          defaultText={'Delete property'}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Editable
            translationKey={`property:delete:content${++i}`}
            defaultText={'Are you sure you wish to delete all data associated to this property?\nThere is no turning back.'}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancelConfirmDelete}>
          <Editable
            translationKey={`property:delete:content${++i}`}
            defaultText={'Cancel'}
          />
        </Button>
        <Button onClick={handleConfirmDelete}>
          <Editable
            translationKey={`property:delete:content${++i}`}
            defaultText={'Confirm'}
          /></Button>
      </DialogActions>
    </Dialog>
  )
}
