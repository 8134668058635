import "./App.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import photographer from "../static/images/photographer2.png";
import accountManager from "../static/images/accountmanagement2.png";
import calendar from "../static/images/calendar.png";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import RecommendIcon from "@mui/icons-material/Recommend";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import LanguageIcon from "@mui/icons-material/Language";
import MessageIcon from "@mui/icons-material/Message";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import ShareIcon from "@mui/icons-material/Share";
import Editable from "./Editable";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

function App() {
  let i = 0;
  let j = 0;
  return (
    <div className="App">
      <Grid container spacing={2}>
        <Grid item sm={12} md={5}>
          <Box
            align={"left"}
            sx={{ marginLeft: "5vw", marginRight: "5vw", marginTop: "50px" }}
          >
            <Typography
              variant="h1"
              gutterBottom
              color={"primary.dark"}
              sx={{ marginBottom: "60px", fontFamily: "Eagle" }}
            >
              <Editable
                translationKey={`homepage:content${++i}`}
                defaultText={"RECAL"}
              />
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              color={"primary.dark"}
              sx={{ marginBottom: "40px" }}
            >
              <Editable
                translationKey={`homepage:content${++i}`}
                defaultText={
                  "<b>FREE</b> tool to manage your bookings and promote your property with style!"
                }
              />
            </Typography>
            <Typography variant="body1" gutterBottom color={"primary.dark"}>
              <Editable
                translationKey={`homepage:content${++i}`}
                defaultText={"Using RECAL helps:"}
              />
            </Typography>
            <Typography variant="body1" gutterBottom color={"primary.dark"}>
              <Editable
                translationKey={`homepage:content${++i}`}
                defaultText={
                  "Property owners and property managers save time and maximize their revenue by providing them with the tools they need to effectively manage and promote their properties."
                }
              />
            </Typography>
            <Typography variant="body1" gutterBottom color={"primary.dark"}>
              <Editable
                translationKey={`homepage:content${++i}`}
                defaultText={
                  "Property viewers like real estate agencies and independent agents save time, improve communication, and provide better service to their clients."
                }
              />
            </Typography>
            <Box marginTop={"20px"} marginBottom={"20px"}>
              <Button
                component={Link}
                to="/sign-up"
                variant="contained"
                color="primary"
              >
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={"Get started"}
                />
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} md={7}>
          <Box
            sx={{
              width: { lg: "90%", md: "90%", sm: "85vw", xs: "85vw" },
              mx: { lg: "0", md: "0", sm: "5vw", xs: "vw" },
              my: { lg: "7vh", md: "20vh", sm: "5vw", xs: "vw" },
              height: { lg: "auto", md: "auto", sm: "auto", xs: "auto" },
              objectFit: "cover",
            }}
          >
            <video width="100%" height="100%" controls>
              <source
                src={
                  "https://recal-properties2.s3.eu-north-1.amazonaws.com/recal_video.mp4"
                }
                type="video/mp4"
              />
            </video>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ background: "white", padding: "60px" }}>
        <Box maxWidth="lg" margin={"auto"}>
          <Typography variant={"h4"} sx={{ marginBottom: "50px" }}>
            <Editable
              translationKey={`homepage:content${++i}`}
              defaultText={
                "<b>R</b>eal <b>E</b>state <b>C</b>alendar <b>A</b>vailability <b>L</b>ink"
              }
            />
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ textAlign: "center", marginBottom: "50px" }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <HistoryToggleOffIcon />
              </Box>
              <Typography variant={"h6"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={"Time Efficiency"}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <ThumbUpOffAltIcon />
              </Box>
              <Typography variant={"h6"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={"Good Management"}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <RecordVoiceOverIcon />
              </Box>
              <Typography variant={"h6"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={"Clear Communication"}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ padding: "40px", background: "#003C2D", color: "white" }}>
        <Box maxWidth="lg" margin={"auto"}>
          <Box sx={{ textAlign: "left", marginBottom: "50px" }}>
            <Grid
              container
              spacing={2}
              sx={{ textAlign: "left", marginBottom: "50px" }}
            >
              <Grid item lg={6}>
                <Typography
                  variant={"h1"}
                  sx={{ marginBottom: "30px", fontWeight: 700 }}
                >
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"Calendar"}
                  />
                </Typography>
                <Typography variant={"h4"} sx={{ marginBottom: "30px" }}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"Never miss a booking!"}
                  />
                </Typography>
                <Typography variant={"body1"}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={
                      "Managing bookings can be a headache, but with our easy-to-use Calendar section, you can stay on top of your property's availability with just a glance. Our Calendar section allows you to:"
                    }
                  />
                  <List sx={{ listStyleType: "disc" }}>
                    <ListItem
                      sx={{
                        display: "list-item",
                        marginLeft: "20px",
                        padding: "0",
                      }}
                    >
                      <Editable
                        translationKey={`homepage:content${++i}`}
                        defaultText={
                          "Stay organized: Keep track of all your bookings in one place, with real-time updates that show you when your property is available, booked, or pending."
                        }
                      />
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        marginLeft: "20px",
                        padding: "0",
                      }}
                    >
                      <Editable
                        translationKey={`homepage:content${++i}`}
                        defaultText={
                          "Maximize revenue: By knowing exactly when your property is available, you can make the most of your rental income by filling vacancies quickly and easily."
                        }
                      />
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        marginLeft: "20px",
                        padding: "0",
                      }}
                    >
                      <Editable
                        translationKey={`homepage:content${++i}`}
                        defaultText={
                          "Save time: With our intuitive, easy-to-use display, you can manage your bookings with just a few clicks - no more back-and-forth emails or phone calls."
                        }
                      />
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        marginLeft: "20px",
                        padding: "0",
                      }}
                    >
                      <Editable
                        translationKey={`homepage-calendar:content${++i}`}
                        defaultText={
                          "Save time: With our intuitive, easy-to-use display, you can manage your bookings with just a few clicks - no more back-and-forth emails or phone calls."
                        }
                      />
                    </ListItem>
                  </List>
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <img
                  alt={"calendar"}
                  style={{
                    width: "100%",
                    maxWidth: "700px",
                    height: "auto",
                    marginTop: "30px",
                    marginBottom: "15px",
                    marginLeft: "3vw",
                  }}
                  src={calendar}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "50px", padding: "40px", background: "white" }}>
        <Box maxWidth="lg" margin={"auto"}>
          <Grid
            container
            spacing={2}
            sx={{ textAlign: "left", marginBottom: "50px" }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <img
                alt={"recal"}
                style={{
                  width: "100%",
                  height: "auto",
                  marginTop: "50px",
                  marginBottom: "15px",
                  marginRight: "3vw",
                }}
                src={photographer}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ padding: "30px" }}>
                <Typography variant={"h2"}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"Tools & services"}
                  />
                </Typography>
                <Typography variant={"h4"} sx={{ marginTop: "30px" }}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"Promote your property like a pro!"}
                  />
                </Typography>
                <Typography variant={"body1"} sx={{ marginTop: "20px" }}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={
                      "Our comprehensive toolkit includes everything you need to manage your property more effectively, all in one place. With our toolkit, you can:"
                    }
                  />
                </Typography>
                <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={
                      "With all the tools you need in one place, you can focus on what really matters; promoting and managing your property with style! Sign up for our platform today and start streamlining your property!"
                    }
                  />
                </Typography>
                <Button
                  sx={{ marginTop: "30px" }}
                  component={Link}
                  to="/sign-up"
                  variant="contained"
                  color="primary"
                >
                  <Editable
                    translationKey={`homepage:content:added${++j}`}
                    defaultText={"Register here"}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "50px", padding: "40px" }}>
        <Box maxWidth="lg" margin={"auto"}>
          <Grid
            container
            spacing={2}
            sx={{ textAlign: "left", marginBottom: "50px" }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ padding: "30px" }}>
                <Typography variant={"h2"}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"Account management"}
                  />
                </Typography>
                <Typography variant={"h4"} sx={{ marginTop: "30px" }}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"Always stay on top!"}
                  />
                </Typography>
                <Typography variant={"body1"} sx={{ marginTop: "20px" }}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={
                      "Managing your property can be overwhelming, especially when it comes to keeping your online calendar up to date. That's where our Property Manager comes in!"
                    }
                  />
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={{ marginTop: "20px", display: "none" }}
                >
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"With our Property Manager, you can:"}
                  />
                </Typography>
                <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={
                      "Our Property Manager is designed to make property management easier and more efficient. With our help, you can rest assured that your online calendar is in good hands, allowing you to focus on other important tasks."
                    }
                  />
                </Typography>
                <Button
                  sx={{ marginTop: "30px" }}
                  component={Link}
                  to="/contact?message=I am interested in your product and would love a direct contact with an account manager."
                  variant="contained"
                  color="primary"
                >
                  <Editable
                    translationKey={`homepage:content${++i}`}
                    defaultText={"Book an account manager"}
                  />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <img
                alt={"account manager"}
                style={{
                  width: "100%",
                  height: "auto",
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "3vw",
                }}
                src={accountManager}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "50px", padding: "40px", background: "white" }}>
        <Box maxWidth="lg" margin={"auto"}>
          <Grid
            container
            spacing={10}
            sx={{ textAlign: "center", marginBottom: "50px" }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <RecommendIcon />
              </Box>
              <Typography variant={"body1"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={
                    "Experience the ease of a user-friendly platform that simplifies your tasks."
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <MobileFriendlyIcon />
              </Box>
              <Typography variant={"body1"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={
                    "Our website is designed to provide a seamless experience, no matter what device you're using."
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <LanguageIcon />
              </Box>
              <Typography variant={"body1"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={
                    "We are pleased to offer our multilingual platform in English, French, and Spanish!"
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <MessageIcon />
              </Box>
              <Typography variant={"body1"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={
                    "Contact and exchange with your prospects and partners directly within the platform."
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <PermMediaIcon />
              </Box>
              <Typography variant={"body1"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={
                    "Store your property images in one place, without any quality loss or book a photographer!"
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "solid thin gray",
                  lineHeight: "60px",
                  margin: "auto",
                  marginBottom: "20px",
                }}
              >
                <ShareIcon />
              </Box>
              <Typography variant={"body1"}>
                <Editable
                  translationKey={`homepage:content${++i}`}
                  defaultText={
                    "Easily share your property information instantly with anyone in the world."
                  }
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default App;
