import * as React from 'react'
import { useEffect, useRef } from 'react'
import Container from '@mui/material/Container'
import { Link as RouterLink, Navigate, useParams, useSearchParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import { Alert, Link, Snackbar, Step, StepLabel, Stepper } from '@mui/material'
import { getContractor, sendData } from '../../core/client'
import Typography from '@mui/material/Typography'
import LoggingRequired from '../../core/LoggingRequired'
import extractFormDataToObject from '../../core/forms'
import ContractorFirstStep from './steps/ContractorFirstStep'
import MediaContractorComponent from './MediaContractorComponent'

export default function AddContractor () {
  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  let step = useRef(0)
  const [activeStep, setActiveStep] = React.useState(step.current ? step.current : 0)
  let { contractorId } = useParams()
  let id = useRef(contractorId)
  let savedData = useRef({})

  useEffect(() => {
    if (id.current) {
      getContractor(contractorId).then((data) => {
        savedData.current = {
          'type': data['type'],
          'name': data['name'],
          'avatar_url': data['avatar_url'],
          'internalNotes': data['internal_notes'],
          'price': data['price'],
          'languages': data['languages'],
          'description': data['description'],
        }
        setRefresh(true)
      })
    }
  }, [contractorId])

  useEffect(() => {
    if (!refresh) {
      return
    }
    setRefresh(false)
  }, [refresh])

  useEffect(() => {
    let stepParam = searchParams.get('step')
    if (stepParam) {
      if (savedData.current === {}) {
        setActiveStep(0)
      } else {
        if (id.current || parseInt(stepParam) < 3) {
          setActiveStep(parseInt(stepParam))
        } else {
          setActiveStep(0)
        }
      }
    }
  }, [searchParams])

  const stepClick = (event, index) => {
    event.preventDefault()
    if (index <= activeStep || id.current !== undefined) {
      setSearchParams({ 'step': index.toString() })
      setActiveStep(index)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = extractFormDataToObject(event)
    savedData.current = {
      ...savedData.current,
      ...data
    }
    if (activeStep === 0) {
      if (savedData.current['name'] === '') {
        return
      }
      if(!savedData.current['description']) {
        savedData.current['description'] = ''
      }
      savedData.current['internal_notes'] = savedData.current['internalNotes']
      let response
      if (id.current) {
        response = await sendData(`/contractors/update/${id.current}`, savedData.current, 'put')
      } else {
        response = await sendData('/contractors/create', savedData.current, 'post')
      }
      if (!isNaN(response)) {
        setErrorMessage('We have a problem. This is on our end, please try again later.')
      } else {
        setSuccessMessage('Contractor successfully created.')
      }
      setOpen(true)
      if (!id.current) {
        id.current = response.data['id']
      }
      if (contractorId) {
        setSuccess(true)
      } else {
        setSearchParams({ 'step': activeStep + 1 })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Container component="main" maxWidth="lg" sx={{ marginBottom: '30px' }}>
      <LoggingRequired/>
      {success && <Navigate replace to="/services"/>}
      <CssBaseline/>
      <Stepper activeStep={activeStep} sx={{ marginTop: '30px', marginBottom: '30px' }}>
        <Step>
          <StepLabel>
            <Link component={RouterLink} to={`/add-contractor?step=0`}
                  onClick={(event) => stepClick(event, 0)}>
              {'Enter basic information'}
            </Link></StepLabel>
        </Step>
        {!contractorId && <Step>
          <StepLabel optional={<Typography variant="caption">Optional</Typography>}>
            <Link component={RouterLink} to={`/add-contractor?step=1`}
                  onClick={(event) => stepClick(event, 1)}>
              {'Add media'}
            </Link></StepLabel>
        </Step>}
      </Stepper>
      {errorMessage !== '' &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      }
      {successMessage !== '' &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {activeStep === 0 && !refresh &&
          <ContractorFirstStep
            savedData={savedData}
            errorMessage={errorMessage}
            handleSubmit={handleSubmit}
          />
        }
        {activeStep === 1 &&
          <MediaContractorComponent contractorId={id.current}/>
        }
      </Box>
    </Container>
  )
}