import { Box, Typography } from '@mui/material'
import '../static/css/loader.css'

export default function FullLoader ({ message }) {
  return (
    <Box sx={{
      background: 'white',
      height: '300vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      zIndex: '10',
      textAlign: 'center'
    }}>
      <div className="spinner" style={{ marginTop: '20vh' }}>
        <div className="circle one"></div>
        <div className="circle two"></div>
        <div className="circle three"></div>
      </div>
      <Typography sx={{ m: 'auto', width: '70vw' }} variant={'h5'}>{message}</Typography>
    </Box>
  )
}