import * as React from "react";
import { useEffect } from "react";
import { getData, getProperty, sendData } from "../../core/client";
import FullLoader from "../../core/FullLoader";
import { Link as RouterLink, Navigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import { BASE_IMAGE, slugify } from "../../core/default";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PropertyMap from "./PropertyMap";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ImageList,
  Snackbar,
} from "@mui/material";
import LoggingRequired from "../../core/LoggingRequired";
import Calendar from "./Calendar";
import Editable from "../../core/Editable";
import LazyBackground from "../../core/LazyBackground";
import DeleteProperty from "./DeleteProperty";
import TextField from "@mui/material/TextField";
import NewThreadDialog from "../users/NewThreadDialog";
import MediaListComponent from "../../core/components/MediaListComponent";
import ImageListItem, {
  imageListItemClasses,
} from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import cleaningMattress from "../../static/images/tips/cleaning-mattress.png";
import cleaningMold from "../../static/images/tips/cleaning-mold.png";
import cleaningStains from "../../static/images/tips/cleaning-stains.png";
import cleaningWashingMachine from "../../static/images/tips/cleaning-washing-machine.png";
import cleaningWoodScratches from "../../static/images/tips/cleaning-wood-scratches.png";
import maintenanceClosing from "../../static/images/tips/maintenance-closing.png";
import maintenanceOpening from "../../static/images/tips/maintenance-opening.png";
import maintenancePreventive from "../../static/images/tips/maintenance-preventive.png";
import maintenancePreventive2 from "../../static/images/tips/maintenance-preventive2.png";
import maintenanceRoutine from "../../static/images/tips/maintenance-routine.png";
import maintenanceSeason from "../../static/images/tips/maintenance-season.png";
import maintenanceSeason2 from "../../static/images/tips/maintenance-season2.png";

function Property() {
  const [property, setProperty] = React.useState();
  const [subscribed, setSubscribed] = React.useState([]);
  let { propertyId } = useParams();
  let { propertyName } = useParams();
  let userType = localStorage.getItem("type");
  if (localStorage.getItem("optinsms") === "true") {
    userType = "admin";
  }
  const [success, setSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [confirm, setConfirm] = React.useState(false);
  const [maxProperties, setMaxProperties] = React.useState(1);
  const [goBack, setGoBack] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [openThread, setOpenThread] = React.useState(false);
  const [forbidden, setForbidden] = React.useState(true);
  const [notes, setNotes] = React.useState();
  const [userId] = React.useState(parseInt(localStorage.getItem("id")));
  const [showImages, setShowImages] = React.useState(false);

  const handleConfirm = () => {
    sendSubscription();
    setConfirm(false);
  };

  const deleteProperty = (event) => {
    setConfirmDelete(true);
  };

  const updateNotes = (event) => {
    setNotes(event.target.value);
  };

  const saveNotes = async () => {
    await sendData(
      `/properties/comment/${propertyId}`,
      { comment: notes },
      "post"
    );
    setSuccessMessage("notes");
    setSuccess(true);
  };

  const sendSubscription = () => {
    sendData(`/properties/subscribe/${propertyId}`, {}, "post").then(
      (response) => {
        if (response === 412) {
          setErrorMessage(
            "You cannot remove a subscription created in the last week."
          );
          setError(true);
          return;
        }
        window.location.reload();
      }
    );
  };

  const handleUpgrade = () => {
    window.open(
      "/contact?message=" +
        encodeURIComponent(
          "I would like to upgrade my maximum number of subscriptions."
        ),
      "_blank"
    );
  };

  const copyLinkToClipboard = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setSuccessMessage("share");
    setSuccess(true);
  };

  const handleCancelConfirm = () => {
    setConfirm(false);
  };

  const dismissSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const dismissError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  useEffect(() => {
    getProperty(propertyId).then((data) => {
      if (data.media.length === 0) {
        data.media = [
          {
            url: BASE_IMAGE,
          },
        ];
      }
      let property = data;
      getData(`/users/info/${property.owner_id}`).then((response) => {
        property.owner = response.data;
        setProperty(property);
      });
    });
    getData(`/properties/comment/${propertyId}`).then((response) => {
      if (response.data) {
        setNotes(response.data.comment);
      }
    });
    if (userType === "agent") {
      getData("/properties/subscribed").then((response) => {
        let data = response.data.map((a) => a.id);
        setSubscribed(data);
      });
      getData("/users/subscription-plan").then((response) => {
        let data = response.data;
        setMaxProperties(data["subscription_limit"]);
      });
    }
  }, [propertyId]);

  const subscribe = () => {
    if (subscribed.length >= maxProperties) {
      setConfirm(true);
    } else {
      sendSubscription();
    }
  };

  const unsubscribe = () => {
    sendData(`/properties/unsubscribe/${propertyId}`, {}, "post").then(() => {
      window.location.reload();
    });
  };

  let i = 0;
  let j = 0;
  let k = 0;
  let l = 0;
  let m = 0;
  let n = 0;
  let o = 0;
  let p = 0;
  return (
    <Container component="main" maxWidth="xl" sx={{ marginBottom: "80px" }}>
      <LoggingRequired />
      {goBack && <Navigate replace to={`/properties`} />}
      {property && propertyName !== slugify(property.name) && (
        <Navigate replace to={`/properties`} />
      )}
      {successMessage !== "" && (
        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={dismissSuccess}
        >
          <Alert
            onClose={dismissSuccess}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage === "notes" && (
              <Editable
                translationKey={`property:success:notes:content${++l}`}
                defaultText={"Your notes were successfully saved."}
              />
            )}
            {successMessage === "share" && (
              <Editable
                translationKey={`property:success:share:content${++m}`}
                defaultText={
                  "Property link copied to clipboard. Use paste or CTRL + V to retrieve it."
                }
              />
            )}
          </Alert>
        </Snackbar>
      )}
      {errorMessage !== "" && (
        <Snackbar open={error} autoHideDuration={6000} onClose={dismissError}>
          <Alert onClose={dismissError} severity="error" sx={{ width: "100%" }}>
            <Editable
              translationKey={`property:error:content${++n}`}
              defaultText={
                "Property link copied to clipboard. Use paste or CTRL + V to retrieve it."
              }
            />
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {!property && <FullLoader />}
      {property && (
        <Box>
          <Grid
            container
            sx={{ margin: "auto", marginTop: "3vh", marginBottom: "7vh" }}
          >
            <Grid item xs={12} sm={12} md={7}>
              <Box sx={{ position: "relative", m: "10px" }}>
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "55vh",
                    zIndex: 2,
                    pointerEvents: "none",
                    backgroundPosition: "center",
                    background:
                      "linear-gradient(0deg, rgba(0,0,0,0.9072361366421569) 0%, rgba(0,0,0,0.013678713672969134) 70%)",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      padding: "20px",
                      pointerEvents: "auto",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ margin: "5px", color: "white" }}
                    >
                      {property.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ margin: "5px", color: "white" }}
                    >
                      {property.address}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowImages(true);
                  }}
                >
                  <Carousel
                    navButtonsAlwaysVisible={true}
                    autoPlay={false}
                    sx={{ height: "55vh" }}
                  >
                    {property.media.map((image, i) => (
                      <LazyBackground
                        src={image.url}
                        key={i}
                        width={"100%"}
                        height={"55vh"}
                      />
                    ))}
                  </Carousel>
                </Box>
                <MediaListComponent
                  images={property.media}
                  open={showImages}
                  setOpen={setShowImages}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Box
                sx={{
                  height: { lg: "95%", md: "95%", sm: "300px", xs: "200px" },
                  m: "10px",
                }}
              >
                <PropertyMap
                  longitude={property.longitude}
                  latitude={property.latitude}
                  mapStyle={"street"}
                  preserveDrawingBuffer={false}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ m: "10px" }}>
                <Calendar
                  subscribe={subscribe}
                  forbidden={forbidden}
                  setForbidden={setForbidden}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background: "white",
                  padding: "30px",
                  boxShadow: 2,
                  height: "100%",
                  m: "10px",
                }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {property.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {property.address}
                </Typography>
                {property.touristic_licence && (
                  <Typography variant="body2" color="text.secondary">
                    <Editable
                      translationKey={`property:content${++i}`}
                      defaultText={"Licence:"}
                    />{" "}
                    {property.touristic_licence}
                  </Typography>
                )}
                {!property.touristic_licence && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: "none" }}
                  >
                    <Editable
                      translationKey={`property:content${++i}`}
                      defaultText={""}
                    />
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                  {property.bedrooms_number}{" "}
                  <Editable
                    translationKey={`property:content${++i}`}
                    defaultText={"bedroom"}
                  />{" "}
                  | {property.bathrooms_number}{" "}
                  <Editable
                    translationKey={`property:content${++i}`}
                    defaultText={"bathroom"}
                  />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Editable
                    translationKey={`property:content${++i}`}
                    defaultText={"Area:"}
                  />{" "}
                  {property.area}m² |{" "}
                  <Editable
                    translationKey={`property:content${++i}`}
                    defaultText={"Plot Area:"}
                  />{" "}
                  {property.plot_area}m²
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Editable
                    translationKey={`property:content${++i}`}
                    defaultText={"Between"}
                  />{" "}
                  {property.min_guests}{" "}
                  <Editable
                    translationKey={`property:content${++i}`}
                    defaultText={"and"}
                  />{" "}
                  {property.max_guests}{" "}
                  <Editable
                    translationKey={`property:content${++i}`}
                    defaultText={"guests"}
                  />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {property.amenities.map((name, index) => {
                    return (
                      <b key={index}>
                        {name.split(" - ")[1]}
                        {index !== property.amenities.length - 1
                          ? " - "
                          : ""}{" "}
                      </b>
                    );
                  })}
                </Typography>
                <Box sx={{ mt: "10px" }}>
                  <Button
                    variant={"outlined"}
                    sx={{ mr: "10px", mt: "10px" }}
                    size="small"
                    onClick={copyLinkToClipboard}
                  >
                    <Editable
                      translationKey={`property:content${++i}`}
                      defaultText={"Share"}
                    />
                  </Button>

                  <Button
                    variant={"outlined"}
                    sx={{ mr: "10px", mt: "10px" }}
                    size="small"
                    component={RouterLink}
                    to={`/brochure/${property.id}/${slugify(property.name)}`}
                  >
                    <Editable
                      translationKey={`property:content${++i}`}
                      defaultText={"Generate brochure"}
                    />
                  </Button>
                  {["agent", "admin"].includes(userType) &&
                    !subscribed.includes(parseInt(propertyId)) && (
                      <Button
                        variant={"outlined"}
                        sx={{ mr: "10px", mt: "10px" }}
                        size="small"
                        onClick={() => {
                          subscribe();
                        }}
                      >
                        <Editable
                          translationKey={`property-agent:content${++p}`}
                          defaultText={"Subscribe"}
                        />
                      </Button>
                    )}
                  {["agent", "admin"].includes(userType) &&
                    subscribed.includes(parseInt(propertyId)) && (
                      <Button
                        variant={"outlined"}
                        sx={{ mr: "10px", mt: "10px" }}
                        size="small"
                        onClick={() => {
                          unsubscribe();
                        }}
                      >
                        <Editable
                          translationKey={`property-agent:content:unsub${++p}`}
                          defaultText={"Unsubscribe"}
                        />
                      </Button>
                    )}
                  {["agent", "admin"].includes(userType) && (
                    <>
                      <Button
                        variant={"outlined"}
                        sx={{ mr: "10px", mt: "10px", display: "none" }}
                        size="small"
                        onClick={() => {
                          setOpenThread(true);
                        }}
                      >
                        <Editable
                          translationKey={`property-agent:content:sub${++j}`}
                          defaultText={"Contact owner"}
                        />
                      </Button>
                      <NewThreadDialog
                        openThread={openThread}
                        setOpenThread={setOpenThread}
                        userId={property.owner.id}
                      />
                    </>
                  )}
                  {(userType === "admin" ||
                    (userType === "owner" && property.owner.id === userId)) && (
                    <>
                      <Button
                        variant={"outlined"}
                        sx={{ mr: "10px", mt: "10px" }}
                        size="small"
                        component={RouterLink}
                        to={`/edit-property/${property.id}/${slugify(
                          property.name
                        )}`}
                      >
                        <Editable
                          translationKey={`property-owner:content${++k}`}
                          defaultText={"Edit"}
                        />
                      </Button>
                      <Button
                        variant={"outlined"}
                        sx={{ mr: "10px", mt: "10px" }}
                        size="small"
                        component={RouterLink}
                        to={`/media-property/${property.id}/${slugify(
                          property.name
                        )}`}
                      >
                        <Editable
                          translationKey={`property-owner:content${++k}`}
                          defaultText={"Manage media"}
                        />
                      </Button>
                      <Button
                        variant={"outlined"}
                        sx={{ mr: "10px", mt: "10px" }}
                        size="small"
                        component={RouterLink}
                        onClick={deleteProperty}
                      >
                        <Editable
                          translationKey={`property-owner:content${++k}`}
                          defaultText={"Delete"}
                        />
                      </Button>
                      <DeleteProperty
                        confirmDelete={confirmDelete}
                        setConfirmDelete={setConfirmDelete}
                        setGoBack={setGoBack}
                        propertyId={propertyId}
                      />
                    </>
                  )}

                  <Dialog
                    open={confirm}
                    onClose={handleCancelConfirm}
                    aria-labelledby="draggable-dialog-title"
                  >
                    <DialogTitle
                      style={{ cursor: "move" }}
                      id="draggable-dialog-title"
                    >
                      <Editable
                        translationKey={`property:content${++i}`}
                        defaultText={"Subscribe"}
                      />
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Editable
                          translationKey={`property:content${++i}`}
                          defaultText={
                            "Your current plan only allows you to subscribe to"
                          }
                        />{" "}
                        {maxProperties}{" "}
                        {maxProperties < 2 ? "property" : "properties"}{" "}
                        <Editable
                          translationKey={`property:content${++i}`}
                          defaultText={
                            "and you are already at max capacity.\nSubscribing to this property will remove the oldest among your previous subscriptions."
                          }
                        />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleCancelConfirm}>
                        <Editable
                          translationKey={`property:content${++i}`}
                          defaultText={"Cancel"}
                        />
                      </Button>
                      <Button onClick={handleUpgrade}>
                        <Editable
                          translationKey={`property:content${++i}`}
                          defaultText={"Upgrade my plan"}
                        />
                      </Button>
                      <Button onClick={handleConfirm}>
                        <Editable
                          translationKey={`property:content${++i}`}
                          defaultText={"Confirm"}
                        />
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Box>
            </Grid>

            {(userType === "admin" ||
              (userType === "owner" && property.owner.id === userId)) && (
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    background: "white",
                    padding: "30px",
                    boxShadow: 2,
                    height: "100%",
                    m: "10px",
                  }}
                >
                  <Typography gutterBottom variant={"h5"}>
                    <Editable
                      translationKey={`property-owner:content${++k}`}
                      defaultText={"Custom notes"}
                    />
                  </Typography>
                  <Typography
                    gutterBottom
                    variant={"body1"}
                    sx={{ color: "gray" }}
                  >
                    <Editable
                      translationKey={`property-owner:content${++k}`}
                      defaultText={"Only you can see those notes."}
                    />
                  </Typography>
                  <TextField
                    name="notes"
                    fullWidth
                    id="notes"
                    label={
                      <Editable
                        translationKey={`property-owner:content${++k}`}
                        defaultText={"Enter your notes"}
                      />
                    }
                    multiline
                    onChange={updateNotes}
                    value={notes}
                    rows={8}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={saveNotes}
                    sx={{ mt: "20px", float: "right" }}
                  >
                    <Editable
                      translationKey={`property-owner:content${++k}`}
                      defaultText={"Update notes"}
                    />
                  </Button>
                </Box>
              </Grid>
            )}

            {property.description && property.description !== "<p></p>\n" && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    background: "white",
                    padding: "30px",
                    boxShadow: 2,
                    height: "100%",
                    m: "10px",
                    mt: "30px",
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    <Editable
                      translationKey={`property:content${++i}`}
                      defaultText={"Description"}
                    />
                  </Typography>
                  <span
                    dangerouslySetInnerHTML={{ __html: property.description }}
                  />
                </Box>
              </Grid>
            )}

            {(userType === "admin" ||
              (userType === "owner" && property.owner.id === userId)) && (
              <Grid item md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    background: "white",
                    padding: "30px",
                    boxShadow: 2,
                    height: "100vh",
                    overflowY: "auto",
                    m: "10px",
                    mt: "50px",
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    <Editable
                      translationKey={`property:content:tips${++o}`}
                      defaultText={"Cleaning tips"}
                    />
                  </Typography>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(1, 1fr)",
                        md: "repeat(1, 1fr)",
                        lg: "repeat(1, 1fr)",
                      },
                      [`& .${imageListItemClasses.root}`]: {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <ImageListItem
                      key={cleaningMattress}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={cleaningMattress}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={cleaningMattress}
                          src={cleaningMattress}
                          alt={"cleaning mattress"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Mattress"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Easily clean your mattresses"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={cleaningMold}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a target={"_blank"} href={cleaningMold} rel="noreferrer">
                        <img
                          srcSet={cleaningMold}
                          src={cleaningMold}
                          alt={"cleaning mold"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Mold"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"How to clean mold"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={cleaningStains}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={cleaningStains}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={cleaningStains}
                          src={cleaningStains}
                          alt={"cleaning stains"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Stains"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Efficiently remove stains"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={cleaningWashingMachine}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={cleaningWashingMachine}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={cleaningWashingMachine}
                          src={cleaningWashingMachine}
                          alt={"cleaning washing machine"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Washing machine"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={
                              "Effortlessly clean your washing machine"
                            }
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={cleaningWoodScratches}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={cleaningWoodScratches}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={cleaningWoodScratches}
                          src={cleaningWoodScratches}
                          alt={"cleaning wood scratches"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Washing machine"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={
                              "Effortlessly clean your washing machine"
                            }
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                  </Box>
                </Box>
              </Grid>
            )}

            {(userType === "admin" ||
              (userType === "owner" && property.owner.id === userId)) && (
              <Grid item md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    background: "white",
                    padding: "30px",
                    boxShadow: 2,
                    height: "100vh",
                    overflowY: "auto",
                    m: "10px",
                    mt: "50px",
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    <Editable
                      translationKey={`property:content:tips${++o}`}
                      defaultText={"Maintenance tips"}
                    />
                  </Typography>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(1, 1fr)",
                        md: "repeat(1, 1fr)",
                        lg: "repeat(1, 1fr)",
                      },
                      [`& .${imageListItemClasses.root}`]: {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <ImageListItem
                      key={maintenanceOpening}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={maintenanceOpening}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={maintenanceOpening}
                          src={maintenanceOpening}
                          alt={"opening property"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Opening your property"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Open your property the right way"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={maintenanceClosing}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={maintenanceClosing}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={maintenanceClosing}
                          src={maintenanceClosing}
                          alt={"close property"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Closing your property"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={
                              "Do not forget anything on closing time"
                            }
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={maintenancePreventive}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={maintenancePreventive}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={maintenancePreventive}
                          src={maintenancePreventive}
                          alt={"maintenance preventive"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Prevention"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Prevent any damage or accident"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={maintenancePreventive2}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={maintenancePreventive2}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={maintenancePreventive2}
                          src={maintenancePreventive2}
                          alt={"maintenance preventive"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Prevention bis"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Prevent any problem"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={maintenanceRoutine}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={maintenanceRoutine}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={maintenanceRoutine}
                          src={maintenanceRoutine}
                          alt={"maintenance routine"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Routine"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Adopt an optimal routine"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={maintenanceSeason}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={maintenanceSeason}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={maintenanceSeason}
                          src={maintenanceSeason}
                          alt={"maintenance season"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Season"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Adapt your checks to the season"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                    <ImageListItem
                      key={maintenanceSeason2}
                      sx={{ m: 1, width: "90%" }}
                    >
                      <a
                        target={"_blank"}
                        href={maintenanceSeason2}
                        rel="noreferrer"
                      >
                        <img
                          srcSet={maintenanceSeason2}
                          src={maintenanceSeason2}
                          alt={"maintenance season 2"}
                          loading="lazy"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                      <ImageListItemBar
                        title={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Season bis"}
                          />
                        }
                        subtitle={
                          <Editable
                            translationKey={`property:content:tips${++o}`}
                            defaultText={"Make sure you are prepared"}
                          />
                        }
                        position="below"
                      />
                    </ImageListItem>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Container>
  );
}

export default Property;
