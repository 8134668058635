import * as React from "react";
import demo from "../../static/images/recal-demo.gif";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export default function Demo() {
  return (
    <Box
      sx={{
        border: "solid thin gray",
        margin: "auto",
        marginTop: "50px",
        marginBottom: "50px",
        width: "80vw",
      }}
    >
      <img style={{ width: "100%", height: "auto" }} src={demo} />
    </Box>
  );
}
