import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import StarIcon from '@mui/icons-material/StarBorder'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Link } from 'react-router-dom'
import Editable from '../../core/Editable'

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '1 calendar subscription',
      'Cannot send messages',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonLink: '/sign-up',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '30',
    description: [
      '20 calendar subscriptions',
      'Message partners',
      'Dedicated account manager',
    ],
    buttonText: 'Contact us',
    buttonLink: '/contact',
    buttonVariant: 'outlined',
  },
  {
    title: 'Enterprise',
    price: '50+',
    description: [
      '100 subscriptions then 50cts per additional subscription',
      'Message everyone',
      'Dedicated account manager',
    ],
    buttonText: 'Contact us',
    buttonLink: '/contact',
    buttonVariant: 'outlined',
  },
]

export default function Pricing () {
  let i = 0
  return (
    <Container maxWidth="md" component="main" sx={{ marginTop: '60px' }}>
      <Typography variant="h2" align="center" component="h1" sx={{ marginBottom: '30px' }}>
        <Editable
          translationKey={`pricing:content${++i}`}
          defaultText={'Pricing'}
        />
      </Typography>
      <Typography variant="h4" align="center" sx={{ marginBottom: '20px' }}>
        <Editable
          translationKey={`pricing:content${++i}`}
          defaultText={'You are a property owner? Then Recal is completely free to use for you.'}
        />
      </Typography>
      <Grid container spacing={5} alignItems="flex-end">
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
            sx={{ marginBottom: '50px' }}
          >
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: 'center' }}
                action={tier.title === 'Pro' ? <StarIcon/> : null}
                subheaderTypographyProps={{
                  align: 'center',
                }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700],
                }}
              />
              <CardContent sx={{ padding: '30px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    {tier.price}€
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    /mo
                  </Typography>
                </Box>
                <ul>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                      gutterBottom
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button component={Link} to={tier.buttonLink} fullWidth variant={tier.buttonVariant}>
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
