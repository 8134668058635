import React from 'react'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

export const MessageLeft = ({ message, timestamp, saveMessage }) => {
  const date = new Date(Date.parse(timestamp))
  const dateDisplayed = date.toLocaleDateString() + ' ' + date.toLocaleTimeString()

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <div style={{
            position: 'relative',
            marginLeft: '20px',
            marginBottom: '20px',
            padding: '20px',
            minWidth: '300px',
            paddingBottom: '20px',
            backgroundColor: '#EDEDED',
            width: '60%',
            textAlign: 'left',
            font: '400 .9em \'Open Sans\', sans-serif',
            '&:after': {
              content: '\'\'',
              position: 'absolute',
              width: '0',
              height: '0',
              borderTop: '15px solid #A8DDFD',
              borderLeft: '15px solid transparent',
              borderRight: '15px solid transparent',
              top: '0',
              left: '-15px'
            },
            '&:before': {
              content: '\'\'',
              position: 'absolute',
              width: '0',
              height: '0',
              borderTop: '17px solid #97C6E3',
              borderLeft: '16px solid transparent',
              borderRight: '16px solid transparent',
              top: '-1px',
              left: '-17px'
            }
          }}>
            <div>
              <p style={{ padding: 0, margin: 0 }}>{message}</p>
            </div>
            <div style={{
              position: 'absolute',
              fontSize: '.7em',
              color: '#7C7C7C',
              fontWeight: '300',
              marginTop: '10px',
              bottom: '-12px',
              right: '7px'
            }}>{dateDisplayed}</div>
            <Tooltip title="Add message to notes">
              <IconButton
                onClick={() => saveMessage(message)}
                sx={{
                  position: 'absolute',
                  fontSize: '.7em',
                  color: 'gray',
                  fontWeight: '300',
                  marginTop: '10px',
                  bottom: '0px',
                  right: '0px'
                }}>
                <SaveAltIcon/>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  )
}
export const MessageRight = ({ message, timestamp, saveMessage }) => {
  const date = new Date(Date.parse(timestamp))
  const dateDisplayed = date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
      <div style={{
        position: 'relative',
        marginRight: '20px',
        marginBottom: '20px',
        padding: '20px',
        minWidth: '150px',
        paddingBottom: '20px',
        backgroundColor: '#007871',
        color: 'white',
        width: '60%',
        textAlign: 'left',
        font: '400 .9em \'Open Sans\', sans-serif',
        '&:after': {
          content: '\'\'',
          position: 'absolute',
          width: '0',
          height: '0',
          borderTop: '15px solid #f8e896',
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          top: '0',
          right: '-15px'
        },
        '&:before': {
          content: '\'\'',
          position: 'absolute',
          width: '0',
          height: '0',
          borderTop: '17px solid #dfd087',
          borderLeft: '16px solid transparent',
          borderRight: '16px solid transparent',
          top: '-1px',
          right: '-17px'
        }
      }}>
        <p style={{
          padding: 0,
          margin: 0
        }}>{message}</p>
        <div style={{
          position: 'absolute',
          fontSize: '.7em',
          color: '#7C7C7C',
          fontWeight: '300',
          marginTop: '10px',
          bottom: '-12px',
          right: '7px'
        }}>{dateDisplayed}</div>
        <Tooltip title="Add message to notes">
          <IconButton
            onClick={() => saveMessage(message)}
            sx={{
              position: 'absolute',
              fontSize: '.7em',
              color: 'white',
              fontWeight: '300',
              marginTop: '10px',
              bottom: '0px',
              right: '0px'
            }}>
            <SaveAltIcon/>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
