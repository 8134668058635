import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import logo from "../static/images/logo.png";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useIntl } from "react-intl";
import { getData } from "./client";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const [searchParams] = useSearchParams();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState("Home");
  const [locale, setLocale] = React.useState(localStorage.getItem("locale"));
  const [token, setToken] = useState();
  const [pages, setPages] = useState([]);
  const [settings, setSettings] = useState([]);
  const intl = useIntl();
  const history = useNavigate();

  const retrieveTranslations = async () => {
    let response = await getData(`/translations/en`);
    let timedTranslations = { value: response.data, timestamp: new Date() };
    localStorage.setItem(`translation_en`, JSON.stringify(timedTranslations));
    response = await getData(`/translations/fr`);
    timedTranslations = { value: response.data, timestamp: new Date() };
    localStorage.setItem(`translation_fr`, JSON.stringify(timedTranslations));
    response = await getData(`/translations/es`);
    timedTranslations = { value: response.data, timestamp: new Date() };
    localStorage.setItem(`translation_es`, JSON.stringify(timedTranslations));
    window.location.reload();
  };

  if (
    !localStorage.getItem("translation_en") ||
    !localStorage.getItem("translation_fr") ||
    !localStorage.getItem("translation_es")
  ) {
    retrieveTranslations();
  }

  useEffect(() => {
    let locale = localStorage.getItem("locale");
    if (!locale) {
      locale = intl.locale;
      if (!["en", "es", "fr"].includes(locale)) {
        locale = "en";
      }
      localStorage.setItem("locale", locale);
      setLocale(locale);
    }
  }, [intl.locale]);

  useEffect(() => {
    if (
      localStorage.getItem("token") === "null" ||
      !localStorage.getItem("token")
    ) {
      logOut();
    } else {
      setToken(localStorage.getItem("token"));
    }
  }, [history]);

  useEffect(() => {
    if (!token) {
      setPages([
        { name: { en: "Home", fr: "Accueil", es: "Inicio" }, url: "/" },
        { name: { en: "Demo", fr: "Demo", es: "Demostración" }, url: "/demo" },
        {
          name: {
            en: "Functionalities",
            fr: "Fonctionnalités",
            es: "Funcionalidades",
          },
          url: "/functionalities",
        },
        // { 'name': { 'en': 'Pricing', 'fr': 'Prix', 'es': 'Precios' }, 'url': '/pricing' },
        {
          name: { en: "Contact", fr: "Contact", es: "Contacto" },
          url: "/contact",
        },
      ]);
      setSettings([
        {
          name: { en: "Sign in", fr: "Connexion", es: "Iniciar sesión" },
          url: "/sign-in",
        },
        {
          name: { en: "Sign up", fr: "Inscription", es: "Inscribirse" },
          url: `/sign-up?redirect=${encodeURIComponent(
            searchParams.get("redirect")
          )}`,
        },
      ]);
    } else {
      setPages([
        {
          name: { en: "Properties", fr: "Propriétés", es: "Propiedades" },
          url: "/properties",
        },
        {
          name: { en: "Services", fr: "Services", es: "Servicios" },
          url: "/services",
        },
        {
          name: { en: "Messages", fr: "Messages", es: "Mensajes" },
          url: "/messages",
        },
        {
          name: { en: "Contact", fr: "Contact", es: "Contacto" },
          url: "/contact",
        },
      ]);
      setSettings([
        {
          name: { en: "Profile", fr: "Profil", es: "Perfil" },
          url: "/profile",
        },
        {
          name: { en: "Log out", fr: "Se déconnecter", es: "Cerrar sesión" },
          url: "/sign-in",
        },
      ]);
    }
  }, [token]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const changeLocale = (locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
    window.location.reload();
  };

  const logOut = () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("firstname");
    localStorage.removeItem("id");
    localStorage.removeItem("lastname");
    localStorage.removeItem("email");
    localStorage.removeItem("type");
    localStorage.removeItem("avatar");
    localStorage.removeItem("optinsms");
    localStorage.removeItem("optin");
    localStorage.removeItem("civility");
    localStorage.removeItem("phone");
    localStorage.removeItem("address");
    localStorage.removeItem("longitude");
    localStorage.removeItem("latitude");
    localStorage.removeItem("job_title");
    localStorage.removeItem("company_name");
    localStorage.removeItem("company_registration");
    localStorage.removeItem("VAT");
    localStorage.removeItem("prefix");
  };

  return (
    <AppBar
      elevation={0}
      position="static"
      color="default"
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        paddingLeft: "35px",
        paddingRight: "35px",
        background: "white",
      }}
    >
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <img
            src={logo}
            alt={"RECAL logo"}
            style={{ width: "auto", height: "50px" }}
          />
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem
                key={page.name[locale]}
                component={RouterLink}
                to={page.url}
                color={"primary"}
                onClick={handleCloseNavMenu}
              >
                <Typography textAlign="center">{page.name[locale]}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <img
            src={logo}
            alt={"RECAL logo"}
            style={{ width: "auto", height: "50px" }}
          />
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              component={RouterLink}
              to={page.url}
              key={page.name[locale]}
              onClick={() => {
                handleCloseNavMenu();
                setCurrentPage(page.name[locale]);
              }}
              sx={{
                my: 2,
                mx: { lg: 4, md: 2 },
                display: "block",
                color: currentPage === page.name[locale] ? "primary" : "black",
              }}
            >
              {page.name[locale]}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            margin: "10px",
            fontSize: "16px",
            color: "lightGray",
            mx: "30px",
            display: { xs: "none", sm: "block" },
          }}
        >
          <IconButton
            onClick={() => {
              changeLocale("en");
            }}
            sx={{
              fontSize: "16px",
              color: locale === "en" ? "black" : "lightGray",
            }}
          >
            EN
          </IconButton>{" "}
          |
          <IconButton
            onClick={() => {
              changeLocale("es");
            }}
            sx={{
              fontSize: "16px",
              color: locale === "es" ? "black" : "lightGray",
            }}
          >
            ES
          </IconButton>{" "}
          |
          <IconButton
            onClick={() => {
              changeLocale("fr");
            }}
            sx={{
              fontSize: "16px",
              color: locale === "fr" ? "black" : "lightGray",
            }}
          >
            FR
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="avatar"
                sx={{ background: "#003C2D" }}
                src={
                  localStorage.getItem("avatar") !== null
                    ? localStorage.getItem("avatar")
                    : undefined
                }
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              sx={{ display: { xs: "block", sm: "none" } }}
              onClick={() => {
                changeLocale("en");
              }}
            >
              English
            </MenuItem>
            <MenuItem
              sx={{ display: { xs: "block", sm: "none" } }}
              onClick={() => {
                changeLocale("es");
              }}
            >
              Espanol
            </MenuItem>
            <MenuItem
              sx={{ display: { xs: "block", sm: "none" } }}
              onClick={() => {
                changeLocale("fr");
              }}
            >
              Français
            </MenuItem>
            {settings.map((setting) => (
              <MenuItem
                key={setting.name[locale]}
                onClick={() => {
                  handleCloseUserMenu();
                  setCurrentPage(null);
                  if (setting.name["en"] === "Log out") {
                    logOut();
                  }
                }}
                component={RouterLink}
                to={setting.url}
              >
                <Typography textAlign="center">
                  {setting.name[locale]}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
