import * as React from 'react'
import { Validate } from 'mui-validate'
import TextField from '@mui/material/TextField'
import Editable from '../../../core/Editable'

export default function LoginPasswordField () {

  return (
    <Validate name="password" required>
      <TextField
        required
        fullWidth
        name="password"
        label={
          <Editable
            translationKey={`users:fields:password`}
            defaultText={'Password'}
          />}
        id="password"
        autoComplete="new-password"
        type="password"
      />
    </Validate>
  )
}