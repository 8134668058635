import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@mui/material";
import Editable from "../../core/Editable";
import recalendar from "../../static/images/recalendar.png";
import search from "../../static/images/search.png";
import messaging from "../../static/images/messaging.png";
import pay from "../../static/images/pay.png";
import brochurevilla from "../../static/images/brochurevilla.png";
import manager from "../../static/images/accountmanagement.png";
import photographer from "../../static/images/photographer.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`functionalities-tabpanel-${index}`}
      aria-labelledby={`functionalities-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `functionalities-tab-${index}`,
    "aria-controls": `functionalities-tabpanel-${index}`,
  };
}

export default function Functionalities() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let i = 0;

  return (
    <Box sx={{ background: "white" }}>
      <Box
        sx={{
          paddingTop: "50px",
          paddingBottom: "50px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Container component="main" maxWidth="lg">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  <Editable
                    translationKey={`functionalities:content${++i}`}
                    defaultText={"Property owners"}
                  />
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Editable
                    translationKey={`functionalities:content${++i}`}
                    defaultText={"Property viewers"}
                  />
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Container>
        <TabPanel value={value} index={0}>
          <Box sx={{ background: "white", mt: 5 }}>
            <Container component="main" maxWidth="lg">
              <Typography variant={"h1"} sx={{ marginBottom: "30px" }}>
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={"Availability in Real-Time"}
                />
              </Typography>
              <Typography variant={"h4"} sx={{ marginBottom: "10px" }}>
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={
                    "Never Miss a Booking with Our Easy-to-Use Calendar"
                  }
                />
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", marginBottom: "50px" }}
              >
                <Grid item mdup md={4}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "50px",
                        marginBottom: "15px",
                      }}
                      src={recalendar}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Box sx={{ padding: "30px", mt: 7 }}>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Our easy-to-use Calendar section simplifies booking management, allowing you to:<br/><br/>" +
                          "<ul>" +
                          "<li>• Stay organized: Keep track of all bookings in one place, with real-time updates on availability.</li><br/>" +
                          "<li>• Maximize revenue: Fill vacancies quickly by knowing exactly when your property is available.</li><br/>" +
                          "<li>• Save time: Manage bookings with just a few clicks thanks to our intuitive display.</li><br/>" +
                          "<li>• Improve customer experience: Allow potential renters or buyers to view availability in real-time and book a viewing at their convenience.</li><br/>" +
                          "</ul>" +
                          "Our Calendar section streamlines booking management for stress-free property management. With real-time updates and an intuitive display, you can maximize revenue and improve customer experience."
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ background: "#F8F8F8", pt: 5 }}>
            <Container component="main" maxWidth="lg">
              <Typography variant={"h2"} sx={{ marginBottom: "30px" }}>
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={"Streamline Your Property Management"}
                />
              </Typography>
              <Typography variant={"h4"} sx={{ marginBottom: "30px" }}>
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={
                    "Manage Your Property Effectively with Our Toolkit"
                  }
                />
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", marginBottom: "50px" }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <Box sx={{ padding: "30px" }}>
                    <Typography variant={"body1"} sx={{ marginTop: "60px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Our comprehensive toolkit streamlines property management by providing all the tools you need in one place. With our toolkit, you can:<br/><br/>" +
                          "<ul>" +
                          "<li>• Download a brochure: Promote your property with ease by creating a brochure complete with photos and useful information.</li><br/>" +
                          "<li>• Create a welcome book: Make guests feel at home with a personalized welcome book featuring WiFi passwords, house rules, and local recommendations.</li><br/>" +
                          "<li>• Access tips and maintenance checklists: Keep your property in top condition with customizable checklists to track tasks and delegate responsibilities.</li><br/>" +
                          "</ul>" +
                          "Our toolkit saves you time and simplifies property management. Focus on what really matters - promoting and managing your property - and leave the rest to us."
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item mdup md={6}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                      src={brochurevilla}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ background: "white", mt: 2 }}>
            <Container component="main" maxWidth="lg">
              <Typography
                variant={"h2"}
                sx={{ marginBottom: "30px", textAlign: "center" }}
              >
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={
                    "Elevate Your Property with Our Professional Services\n"
                  }
                />
              </Typography>
              <Typography
                variant={"h4"}
                sx={{ marginBottom: "30px", textAlign: "center" }}
              >
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={
                    "Discover the Convenience of Booking good Maintenance, Design, and Hospitality Services"
                  }
                />
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", marginBottom: "50px" }}
              >
                <Grid item mdup md={6}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "20px",
                        marginBottom: "15px",
                      }}
                      src={photographer}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box sx={{ padding: "30px" }}>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Maintaining a property can be a lot of work, but with Recal, you can easily book the professional services you need to keep your property looking and functioning its best.<br/>" +
                          "<br/>" +
                          "Whether you need a photographer to capture stunning images of your property, tips and maintenance services to keep it in top shape, a personal chef to provide gourmet meals, a massage therapist for your clients, or even a website designer to help showcase your property online, our platform has you covered.<br/>" +
                          "<br/>" +
                          "Give your property the care and attention it deserves - book your services today!"
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ background: "#F8F8F8" }}>
            <Container component="main" maxWidth="lg">
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", marginBottom: "50px" }}
              >
                <Box
                  sx={{
                    paddingTop: "30px",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant={"h2"} sx={{ marginBottom: "30px" }}>
                    <Editable
                      translationKey={`functionalities:content${++i}`}
                      defaultText={"Always Up to Date"}
                    />
                  </Typography>
                  <Typography variant={"h4"} sx={{ marginBottom: "30px" }}>
                    <Editable
                      translationKey={`functionalities:content${++i}`}
                      defaultText={
                        "Let Our Experts Manage Your Online Calendar"
                      }
                    />
                  </Typography>
                </Box>
                <Grid item xs={12} sm={12} md={6}>
                  <Box sx={{ padding: "30px" }}>
                    <Typography variant={"body1"} sx={{ marginTop: "60px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Managing your property can be overwhelming, especially when it comes to keeping your online calendar up to date. That's where our Property Manager comes in - our team of experts will manage your online calendar for you, ensuring that your property is always available for viewings.<br/>" +
                          "<br/>" +
                          "With our Property Manager, you can:<br/>" +
                          "<br/>" +
                          "• Save time: Managing an online calendar can be time-consuming, especially if you have multiple properties. Let us handle it for you so you can focus on other aspects of property management.<br/>" +
                          "<br/>" +
                          "• Ensure accuracy: Our experts will update your calendar regularly to ensure it's always accurate and up to date. This can help prevent scheduling conflicts and ensure that potential buyers or renters are able to view your property at their convenience.<br/>" +
                          "<br/>" +
                          "• Maximize exposure: By keeping your calendar up to date, you can increase your property's exposure to potential renters. Our experts will ensure that your property is visible and accessible to those who are interested in viewing it.<br/>" +
                          "<br/>" +
                          "Our Property Manager is designed to make property management easier and more efficient. With our help, you can rest assured that your online calendar is in good hands, allowing you to focus on other important tasks."
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item mdup md={6}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                      src={manager}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ background: "white", mt: 5 }}>
            <Container component="main" maxWidth="lg">
              <Typography variant={"h1"} sx={{ marginBottom: "30px" }}>
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={"Stay up to date"}
                />
              </Typography>
              <Typography variant={"h4"} sx={{ marginBottom: "10px" }}>
                <Editable
                  translationKey={`functionalities:content${++i}`}
                  defaultText={"Never miss a booking again"}
                />
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", marginBottom: "50px" }}
              >
                <Grid item mdup md={4}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "50px",
                        marginBottom: "15px",
                      }}
                      src={recalendar}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Box sx={{ padding: "30px", mt: 7 }}>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Our calendar feature also allows you to save time by viewing multiple property calendars in one place, eliminating the need to switch between different websites or applications. You can trust that our platform provides the most accurate and up-to-date information to help you make the right booking decisions."
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          'We understand the frustration of missing a booking due to outdated calendars, which is why we\'ve added an "unsure" part in the legend to indicate if the property owner is active or not. Also, we mention when the property owner was last connected. This ensures that you can rely on the information displayed on our platform and avoid any confusion or misunderstandings.'
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Don't let outdated calendars ruin your booking experience. Try our calendar feature today and stay on top of your property management game!"
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ background: "#F8F8F8" }}>
            <Container component="main" maxWidth="lg">
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", marginBottom: "50px" }}
              >
                <Grid item xs={12} sm={12} md={7}>
                  <Box sx={{ padding: "30px" }}>
                    <Typography variant={"h2"} sx={{ marginBottom: "30px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={"Advanced Search Engine"}
                      />
                    </Typography>
                    <Typography variant={"h4"} sx={{ marginBottom: "30px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Available Properties Based on Your Preferences"
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Our search engine feature allows you to quickly and easily find the perfect property that meets your specific needs. Whether you're looking for a swimming pool, sea view or AC, our advanced search engine provides you with all the options you need to make the right choice."
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "With just a few clicks, you can filter properties based on your desired criteria such as dates, number of guests, and number of bedrooms. Our platform instantly generates a list of available properties that match your preferences, saving you time and effort in your search."
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "So why waste your time scrolling through endless listings? Try our search enginefeature today and find your dream property in just seconds!"
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item mdup md={5}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "50px",
                        marginBottom: "15px",
                      }}
                      src={search}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ background: "white", mt: 2 }}>
            <Container component="main" maxWidth="lg">
              <Grid
                container
                spacing={2}
                sx={{ textAlign: "left", marginBottom: "50px" }}
              >
                <Grid item mdup md={6}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "80px",
                        marginBottom: "15px",
                      }}
                      src={messaging}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box sx={{ padding: "30px" }}>
                    <Typography
                      variant={"h2"}
                      sx={{
                        marginBottom: "30px",
                        textAlign: { xs: "center", sm: "right" },
                      }}
                    >
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={"Instant Messaging Inbox"}
                      />
                    </Typography>
                    <Typography
                      variant={"h4"}
                      sx={{
                        marginBottom: "30px",
                        textAlign: { xs: "center", sm: "right" },
                      }}
                    >
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={"Never miss a Critical Detail Again"}
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "At RECAL, we understand how important communication is, that's why we've added an instant messaging inbox to our platform, making it easier than ever to connect with property owners. But we didn't stop there - we also added a message-saving feature to make communication even more streamlined."
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "With our message-saving feature, you can easily save important messages from your conversations with property owners. No more sifting through old messages to find critical details - it helps you keep everything organized and easily accessible."
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "So why wait? Sign up for our platform today and discover the power of instant messaging with the added convenience of message-saving. With our platform, you can stay on top of your real estate business and communicate more efficiently than ever before."
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ background: "#F8F8F8" }}>
            <Container component="main" maxWidth="lg">
              <Grid
                container
                spacing={2}
                sx={{
                  textAlign: { xs: "center", sm: "left" },
                  marginBottom: "50px",
                }}
              >
                <Grid item xs={12} sm={12} md={7}>
                  <Box sx={{ padding: "30px" }}>
                    <Typography variant={"h2"} sx={{ marginBottom: "30px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={"Pay Only for What You Need"}
                      />
                    </Typography>
                    <Typography variant={"h4"} sx={{ marginBottom: "30px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Follow Multiple Property Calendars Without Breaking the Bank"
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "At RECAL, we understand that everyone's needs are different when it comes to real estate. That's why we've created a subscription/follow system that allows you to choose the properties you want to follow and pay only for what you need."
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "You can easily follow multiple property owners' calendars without breaking the bank. Our first subscription is free, so you can try out our platform and see how it works before committing to anything. And if you find that you need to follow more calendars, you can easily upgrade to a paid subscription that fits your needs."
                        }
                      />
                    </Typography>
                    <Typography variant={"body1"} sx={{ marginTop: "10px" }}>
                      <Editable
                        translationKey={`functionalities:content${++i}`}
                        defaultText={
                          "Our system is designed to be flexible and convenient, so you can focus on the properties you care about most. Whether you're a real estate agency,   an independant, or simply someone looking for your dream home, our platform makes it easy to stay up-to-date on the latest property listings and availability."
                        }
                      />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item mdup md={5}>
                  <Box display={{ xs: "none", sm: "none", md: "block" }}>
                    <img
                      alt={"recal"}
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "100px",
                        marginBottom: "15px",
                      }}
                      src={pay}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}
