import * as React from 'react'
import TextField from '@mui/material/TextField'
import { FormControl, InputLabel, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Editable from '../../../core/Editable'

export default function BathroomsNumberField ({ value }) {
  const [updated, setUpdated] = React.useState(value ? [parseInt(value)] : [2])
  const handleChange = (event) => {
    let value = event.target.value
    setUpdated(parseInt(value))
  }
  let i = 0;
  return (
    <FormControl fullWidth>
      <InputLabel id="bathrooms-label"><Editable
        translationKey={`property:bathrooms:content${++i}`}
        defaultText={'Bathrooms Number'}
      /></InputLabel>
      <Select
        labelId="bathrooms-label"
        id="bathrooms"
        value={updated}
        label={
          <Editable
            translationKey={`property:bathrooms:content${++i}`}
            defaultText={'Bathrooms Number'}
          />}
        onChange={handleChange}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={7}>7</MenuItem>
        <MenuItem value={8}>8</MenuItem>
        <MenuItem value={9}>9</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={11}>11</MenuItem>
        <MenuItem value={12}>12</MenuItem>
        <MenuItem value={13}>13</MenuItem>
        <MenuItem value={14}>14</MenuItem>
        <MenuItem value={15}>15</MenuItem>
        <MenuItem value={16}>16</MenuItem>
        <MenuItem value={17}>17</MenuItem>
        <MenuItem value={18}>18</MenuItem>
        <MenuItem value={19}>19</MenuItem>
        <MenuItem value={20}>20</MenuItem>
      </Select>
      <TextField
        name="bathroomsNumber"
        sx={{opacity:0}}
        type="hidden"
        value={updated}
      />
    </FormControl>
  )
}