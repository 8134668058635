import * as React from 'react'
import { useEffect, useRef } from 'react'
import { sendData } from '../../core/client'
import Box from '@mui/material/Box'
import { Validate, ValidationGroup } from 'mui-validate'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { MessageLeft, MessageRight } from './ChatMessage'
import { IconButton } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import Editable from '../../core/Editable'

export default function Thread ({ thread, saveMessage }) {
  const [newMessage, setNewMessage] = React.useState([])
  const [subject, setSubject] = React.useState()
  const userId = parseInt(localStorage.getItem('id'))
  const messagesEnd = useRef(null)

  useEffect(() => {
    messagesEnd.current.scrollIntoView()
  }, [JSON.stringify(thread.messages)])

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (newMessage === '') {
      return
    }
    const data = { content: newMessage, recipient_id: userId === thread.author_id ? thread.author_id: thread.recipient_id }
    if (subject) {
      data['subject'] = subject
    }
    if (thread) {
      data.thread_id = thread.id
    }
    await sendData('/users/messages', data, 'post')
    let date = new Date()
    thread.messages.push({
      content: data.content,
      author_id: userId,
      created_at: date.toLocaleString(),
      avatar_url: localStorage.getItem('avatar') !== null ? localStorage.getItem('avatar') : undefined,
      first_name: localStorage.getItem('firstname') !== null ? localStorage.getItem('firstname') : undefined,
      last_name: localStorage.getItem('lastname') !== null ? localStorage.getItem('lastname') : undefined,

    })
    setNewMessage('')
  }

  let i = 0
  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ overflow: 'auto', height: '80%' }}>
        {
          thread.messages.map((message, i) => {
            if (userId === message.author_id) {
              return <MessageRight
                key={i}
                message={message.content}
                timestamp={message.created_at}
                saveMessage={saveMessage}
              />
            }
            return <MessageLeft
              key={i}
              message={message.content}
              timestamp={message.created_at}
              saveMessage={saveMessage}
            />
          })
        }

        <div ref={messagesEnd}></div>
      </Box>
      <ValidationGroup>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Grid container spacing={2} sx={{ position: 'relative' }}>
            {!thread && <Grid item xs={12}>
              <Validate name="subject" required>
                <TextField
                  name="subject"
                  required
                  fullWidth
                  id="subject"
                  label={<Editable
                    translationKey={`thread:content${++i}`}
                    defaultText={"Enter a subject for your conversation"}
                  />}
                  autoFocus
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value)
                  }}
                />
              </Validate>
            </Grid>}
            <Grid item xs={12} sx={{
              position: thread ? 'absolute' : 'default',
              top: thread ? '0px' : 'default',
              zIndex: 2,
              width: '100%',
              display: 'flex'
            }}>
              <TextField
                name="message"
                required
                fullWidth
                id="message"
                label={<Editable
                  translationKey={`thread:content${++i}`}
                  defaultText={"Enter your message"}
                />}
                autoFocus
                multiline
                rows={3}
                value={newMessage}
                onChange={(e) => {
                  setNewMessage(e.target.value)
                }}
              />
              <IconButton
                type="submit"
                sx={{
                  width: '40px',
                  height: '40px',
                  m: 2,
                  mt:5
                }}
              >
                <SendIcon
                  sx={{
                    width: '40px',
                    height: '40px',
                    color: '#007871'
                  }}/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </ValidationGroup>
    </Box>
  )
}