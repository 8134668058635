import * as React from "react";
import { useEffect } from "react";
import { getData } from "../../../core/client";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Editable from "../../../core/Editable";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import TvOutlinedIcon from "@mui/icons-material/TvOutlined";
import DeckOutlinedIcon from "@mui/icons-material/DeckOutlined";
import PoolOutlinedIcon from "@mui/icons-material/PoolOutlined";

export default function AmenitiesField({ value }) {
  const [availableAmenities, setAvailableAmenities] = React.useState();
  const [pickedAmenities, setPickedAmenities] = React.useState(
    value ? value : []
  );
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  async function getAmenities() {
    let locale = localStorage.getItem("locale");
    return await getData(`/properties/get-amenities?locale=${locale}`).then(
      (response) => {
        return response.data;
      }
    );
  }

  useEffect(() => {
    getAmenities().then((data) => {
      let amenities = {};
      for (let elt of data) {
        let amenityWithCategory = elt.split(" - ");
        let category = amenityWithCategory[0];
        let amenityName = amenityWithCategory[1];
        if (amenities.hasOwnProperty(category)) {
          amenities[category].push({ label: amenityName, name: elt });
        } else {
          amenities[category] = [{ label: amenityName, name: elt }];
        }
      }
      setAvailableAmenities(amenities);
    });
  }, []);

  const handleChange = (event) => {
    let choice = event.target.name.substring(
      event.target.name.indexOf("[") + 1,
      event.target.name.lastIndexOf("]")
    );
    if (!pickedAmenities) {
      setPickedAmenities([choice]);
    } else {
      let tmp = pickedAmenities;
      if (event.target.checked) {
        tmp.push(choice);
      } else {
        const index = tmp.indexOf(choice);
        tmp.splice(index, 1);
      }
      setPickedAmenities(tmp);
    }
    forceUpdate();
  };
  let i = 0;
  return (
    <FormGroup>
      <Grid container spacing={2}>
        {availableAmenities &&
          Object.keys(availableAmenities).map((elt) => (
            <Grid item xs={12} sm={6} md={3} key={elt}>
              <Box>
                <Typography sx={{ my: "30px" }} variant="h5" component="div">
                  {["House", "Casa", "Maison"].includes(elt) && (
                    <CottageOutlinedIcon
                      sx={{
                        fontSize: "32px",
                        position: "relative",
                        top: "8px",
                        right: "8px",
                      }}
                    />
                  )}
                  {["Equipment", "Equipement", "Equipamiento"].includes(
                    elt
                  ) && (
                    <TvOutlinedIcon
                      sx={{
                        fontSize: "32px",
                        position: "relative",
                        top: "8px",
                        right: "8px",
                      }}
                    />
                  )}
                  {["Garden", "Jardin", "Jardín"].includes(elt) && (
                    <DeckOutlinedIcon
                      sx={{
                        fontSize: "32px",
                        position: "relative",
                        top: "8px",
                        right: "8px",
                      }}
                    />
                  )}
                  {[
                    "Pool & wellness",
                    "Piscine & Bien-être",
                    "Piscina y Bienestar",
                  ].includes(elt) && (
                    <PoolOutlinedIcon
                      sx={{
                        fontSize: "32px",
                        position: "relative",
                        top: "8px",
                        right: "8px",
                      }}
                    />
                  )}
                  {elt}
                </Typography>
                {availableAmenities[elt].map((amenity) => (
                  <Box key={amenity.name}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={true}
                          checked={pickedAmenities.includes(amenity.name)}
                          name={`amenities[${amenity.name}]`}
                          onChange={handleChange}
                        />
                      }
                      label={amenity.label}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
      </Grid>
    </FormGroup>
  );
}
