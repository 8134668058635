import * as React from "react";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TypeField from "../input/TypeField";
import NameField from "../input/NameField";
import PriceField from "../input/PriceField";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { useState } from "react";
import draftToHtml from "draftjs-to-html";
import { Link } from "react-router-dom";
import LanguageField from "../input/LanguageField";
import InternalNotesField from "../input/InternalNotesField";

export default function ContractorFirstStep({
  savedData,
  errorMessage,
  handleSubmit,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(
          savedData.current["description"]
            ? savedData.current["description"]
            : ""
        )
      )
    )
  );

  const onEditorChange = (state) => {
    setEditorState(state);
    let raw = convertToRaw(state.getCurrentContent());
    savedData.current["description"] = draftToHtml(raw);
  };
  return (
    <ValidationGroup>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <TypeField
              value={
                savedData.current["type_key"]
                  ? savedData.current["type_key"]
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NameField
              value={
                savedData.current["name"]
                  ? savedData.current["name"]
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <PriceField
              value={
                savedData.current["price"]
                  ? savedData.current["price"]
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <LanguageField
              value={
                savedData.current["languages"]
                  ? savedData.current["languages"]
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InternalNotesField
              value={
                savedData.current["internalNotes"]
                  ? savedData.current["internalNotes"]
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Description</Typography>
            <Editor
              editorStyle={{
                height: "45vh",
                background: "white",
                border: "solid thin lightGray",
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={onEditorChange}
            />
          </Grid>
        </Grid>
        <Button
          component={Link}
          to={"/contractors"}
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Back
        </Button>
        <AutoDisabler firstDisplayErrors={true}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, float: "right" }}
          >
            Confirm
          </Button>
        </AutoDisabler>
        {errorMessage !== "" && (
          <Alert sx={{ mt: 1, mb: 2 }} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Box>
    </ValidationGroup>
  );
}
