import * as React from "react";
import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Alert,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { AutoDisabler, ValidationGroup } from "mui-validate";
import { getData, sendData } from "../../core/client";
import EmailField from "./input/EmailField";
import OptInCheckbox from "./input/OptInCheckbox";
import LastNameField from "./input/LastNameField";
import FirstNameField from "./input/FirstNameField";
import MediaField from "../properties/input/MediaField";
import LoggingRequired from "../../core/LoggingRequired";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import AddressField from "../properties/input/AddressField";
import { BASE_LATITUDE, BASE_LONGITUDE } from "../../core/default";
import PropertyMap from "../properties/PropertyMap";
import PhoneField from "./input/PhoneField";
import CompanyNameField from "./input/CompanyNameField";
import CompanyRegistrationField from "./input/CompanyRegistrationField";
import VatField from "./input/VatField";
import JobTitleField from "./input/JobTitleField";
import Editable from "../../core/Editable";
import NewThreadDialog from "./NewThreadDialog";
import Tooltip from "@mui/material/Tooltip";
import { resizeFile } from "../../core/Images";

export default function Profile() {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [civility, setCivility] = React.useState(
    localStorage.getItem("prefix") ? localStorage.getItem("prefix") : "Mr"
  );
  const [avatarUploadDisplay, setAvatarUploadDisplay] = React.useState(false);
  const [isOptIn, setIsOptIn] = React.useState(
    JSON.parse(localStorage.getItem("optIn"))
  );
  const [files, setFiles] = React.useState([]);
  const [avatarUrl, setAvatarUrl] = React.useState(
    localStorage.getItem("avatar")
  );
  const [subscribers, setSubscribers] = React.useState([]);
  const [latitude, setLatitude] = React.useState(
    localStorage.getItem("latitude")
      ? localStorage.getItem("latitude")
      : BASE_LATITUDE
  );
  const [longitude, setLongitude] = React.useState(
    localStorage.getItem("longitude")
      ? localStorage.getItem("longitude")
      : BASE_LONGITUDE
  );
  const [openThread, setOpenThread] = React.useState(false);
  let userType = localStorage.getItem("type");

  useEffect(() => {
    getData(`/properties/subscribers`).then((response) => {
      let data = response.data;
      setSubscribers(data);
    });
  }, []);

  const editAvatar = () => {
    setAvatarUploadDisplay(!avatarUploadDisplay);
  };

  const uploadAvatar = async () => {
    let formData = new FormData();
    const resizedImage = await resizeFile(files[0].file, 800, 800);
    formData.append("file", resizedImage);
    let response = await sendData(
      `/users/media/upload/avatar`,
      formData,
      "post"
    );
    setAvatarUrl(response["data"]["url"]);
    localStorage.setItem("avatar", response["data"]["url"]);
    setAvatarUploadDisplay(false);
    setFiles([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {};
    for (let p of formData) {
      let name = p[0];
      data[name] = p[1];
    }
    data["opt_in"] = isOptIn;
    data["phone_number"] = data["phone"];
    data["prefix"] = civility;
    let response = await sendData("/users/profile", data, "put");
    if (!isNaN(response)) {
      setErrorMessage(
        "We have a problem. This is on our end, please try again later."
      );
    } else {
      localStorage.setItem("firstname", data["first_name"]);
      localStorage.setItem("lastname", data["last_name"]);
      localStorage.setItem("email", data["email"]);
      localStorage.setItem("phone", data["phone"]);
      localStorage.setItem("address", data["address"]);
      localStorage.setItem("longitude", data["longitude"]);
      localStorage.setItem("latitude", data["latitude"]);
      localStorage.setItem("job_title", data["job_title"]);
      localStorage.setItem("company_name", data["company_name"]);
      localStorage.setItem(
        "company_registration",
        data["company_registration"]
      );
      localStorage.setItem("VAT", data["VAT"]);
      localStorage.setItem("prefix", civility);
      setSuccess(true);
    }
  };

  const handleOptIn = (optIn) => {
    setIsOptIn(!optIn);
    localStorage.setItem("optIn", "false");
  };

  const mapCallback = (longitude, latitude) => {
    setLongitude(longitude);
    setLatitude(latitude);
  };

  let i = 0;
  let j = 0;
  let k = 0;
  return (
    <Container component="main" maxWidth="md" sx={{ marginBottom: "80px" }}>
      <LoggingRequired />
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton onClick={editAvatar}>
          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", width: 256, height: 256 }}
            src={avatarUrl}
          />
        </IconButton>
        {avatarUploadDisplay && (
          <Box sx={{ width: "400px" }}>
            <MediaField
              files={files}
              setFiles={setFiles}
              maxFiles={1}
              minHeight={200}
            />
            <Button
              type="submit"
              variant="contained"
              onClick={uploadAvatar}
              sx={{ mt: 3, mb: 2, float: "right" }}
            >
              <Editable
                translationKey={`profile:content:avatar${++k}`}
                defaultText={"Upload file"}
              />
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setAvatarUploadDisplay(false);
              }}
              sx={{ mt: 3, mb: 2, float: "left" }}
            >
              <Editable
                translationKey={`profile:content:avatar${++k}`}
                defaultText={"Cancel"}
              />
            </Button>
          </Box>
        )}
        <ValidationGroup>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  color="primary"
                  value={civility}
                  exclusive
                  fullWidth
                  onChange={(event) => setCivility(event.target.value)}
                  aria-label="Platform"
                >
                  <ToggleButton value="Mr">
                    <Editable
                      translationKey={`profile:content${++i}`}
                      defaultText={"Mr"}
                    />
                  </ToggleButton>
                  <ToggleButton value="Mrs">
                    <Editable
                      translationKey={`profile:content${++i}`}
                      defaultText={"Mrs"}
                    />
                  </ToggleButton>
                  <ToggleButton value="Ms">
                    <Editable
                      translationKey={`profile:content${++i}`}
                      defaultText={"Ms"}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FirstNameField profile={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LastNameField profile={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EmailField profile={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneField profile={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <JobTitleField profile={true} />
              </Grid>
              {["agent"].includes(userType) && (
                <Grid item xs={12} sm={6}>
                  <CompanyNameField profile={true} />
                </Grid>
              )}
              {["agent"].includes(userType) && (
                <Grid item xs={12} sm={6}>
                  <CompanyRegistrationField profile={true} />
                </Grid>
              )}
              {["agent"].includes(userType) && (
                <Grid item xs={12} sm={6}>
                  <VatField profile={true} />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <AddressField
                  baseValue={
                    localStorage.getItem("address")
                      ? localStorage.getItem("address")
                      : undefined
                  }
                  defaultLongitude={longitude}
                  defaultLatitude={latitude}
                  mapCallback={mapCallback}
                  setLongitude={setLongitude}
                  setLatitude={setLatitude}
                  required={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "100%", height: "100%", minHeight: "300px" }}>
                  <PropertyMap
                    longitude={longitude}
                    latitude={latitude}
                    mapStyle={"dark"}
                    setLongitude={setLongitude}
                    setLatitude={setLatitude}
                    preserveDrawingBuffer={false}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <OptInCheckbox onOptInChange={handleOptIn} />
              </Grid>
            </Grid>
            <AutoDisabler firstDisplayErrors={true}>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, float: "right" }}
              >
                <Editable
                  translationKey={`profile:content${++i}`}
                  defaultText={"Save changes"}
                />
              </Button>
            </AutoDisabler>
          </Box>
          {success && (
            <Alert sx={{ mt: 1, mb: 2 }} severity="info">
              <Editable
                translationKey={`profile:success:content${++j}`}
                defaultText={"Your profile was saved."}
              />
            </Alert>
          )}
          {errorMessage !== "" && (
            <Alert sx={{ mt: 1, mb: 2 }} severity="error">
              {errorMessage}
            </Alert>
          )}
        </ValidationGroup>
      </Box>
      {subscribers.length !== 0 && (
        <Box>
          <Typography variant={"h4"}>
            <Editable
              translationKey={`profile:content${++i}`}
              defaultText={"My subscribers"}
            />
          </Typography>
          {subscribers.map((subscriber) => (
            <ListItem alignItems="flex-start" key={subscriber.id}>
              <NewThreadDialog
                openThread={openThread}
                setOpenThread={setOpenThread}
                userId={subscriber.id}
              />
              <Tooltip title={"Contact user"}>
                <ListItemAvatar
                  onClick={() => setOpenThread(true)}
                  sx={{ cursor: "pointer" }}
                >
                  <Avatar alt="Avatar" src={subscriber.avatar_url} />
                </ListItemAvatar>
              </Tooltip>
              <ListItemText
                primary={subscriber.first_name + " " + subscriber.last_name}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline", marginRight: "10px" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <Editable
                        translationKey={`profile:content${++i}`}
                        defaultText={"Subscribed to properties"}
                      />{" "}
                      {subscriber.subscriptions}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </Box>
      )}
    </Container>
  );
}
