import * as React from "react";
import { useState } from "react";
import { Validate } from "mui-validate";
import TextField from "@mui/material/TextField";
import Editable from "../../../core/Editable";

export default function EmailField({ profile }) {
  const [value, setValue] = useState(localStorage.getItem("email"));
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Validate
      name="email"
      regex={[/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please enter a valid email."]}
    >
      <TextField
        name="email"
        required
        fullWidth
        id="email"
        label={
          <Editable
            translationKey={`users:fields:email`}
            defaultText={"Email Address"}
          />
        }
        autoComplete="email"
        value={profile ? value : undefined}
        onChange={handleChange}
      />
    </Validate>
  );
}
