import * as React from "react";
import { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { getData, isLogged, sendData } from "../../core/client";
import Container from "@mui/material/Container";
import { Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Thread from "./Thread";
import { Alert, IconButton, Link, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import Editable from "../../core/Editable";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";

function Messages() {
  const [threads, setThreads] = React.useState([]);
  const [currentThread, setCurrentThread] = React.useState();
  const [notes, setNotes] = React.useState("");
  const userId = parseInt(localStorage.getItem("id"));
  const [success, setSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [openDetails, setOpenDetails] = React.useState(false);
  let userType = localStorage.getItem("type");
  if (localStorage.getItem("optinsms") === "true") {
    userType = "admin";
  }

  useEffect(() => {
    getData("/users/messages").then((response) => {
      let data = response.data;
      if (data.length === 0) {
        return;
      }
      setThreads(data);
      setCurrentThread(data[0]);
      let notes =
        data[0].author_id === userId
          ? data[0].author_comment
          : data[0].recipient_comment;
      setNotes(notes);
    });
  }, []);

  const switchThread = (newThreadId) => {
    for (let thread of threads) {
      if (thread.id === newThreadId) {
        let notes =
          thread.author_id === userId
            ? thread.author_comment
            : thread.recipient_comment;
        if (notes === null) {
          notes = "";
        }
        setNotes(notes);
        setCurrentThread(thread);
      }
    }
    setOpenDetails(true);
  };

  const updateNotes = (elt) => {
    setNotes(elt.target.value);
  };

  const saveNotes = async () => {
    await sendData(
      `/users/messages/comment/${currentThread.id}`,
      { content: notes },
      "post"
    );
    setSuccessMessage("Your notes have been saved.");
    setSuccess(true);
  };

  const saveMessage = (message) => {
    if (notes) {
      setNotes(notes + "\n" + message);
    } else {
      setNotes(message);
    }
  };

  const deleteThread = async (threadId) => {
    await sendData(`/users/thread/${threadId}`, {}, "delete");
    setSuccessMessage("This messages thread has been successfully deleted.");
    setSuccess(true);
    let tmpThreads = threads.filter(function (obj) {
      return obj.id !== threadId;
    });
    setThreads(tmpThreads);
    setCurrentThread(tmpThreads[0]);
  };

  const dismissSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  let i = 0;
  let j = 0;
  let k = 0;
  let l = 0;
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{ height: "80vh", bgcolor: "white", mt: 2 }}
    >
      {!isLogged() && (
        <Navigate
          replace
          to={`/sign-in?redirect=${encodeURIComponent(
            window.location.pathname
          )}`}
        />
      )}
      <IconButton
        sx={{
          display: { xs: openDetails ? "block" : "none", sm: "none" },
          position: "fixed",
          top: "8px",
          left: "8px",
        }}
        onClick={() => setOpenDetails(false)}
      >
        <ArrowBackIcon />
      </IconButton>
      {threads.length === 0 && (
        <Box
          sx={{
            margin: "auto",
            textAlign: "center",
            paddingTop: "30vh",
            paddingBottom: "30vh",
          }}
        >
          <Typography variant={"h5"} color={"gray"}>
            <Editable
              translationKey={`messages:empty:content${++j}`}
              defaultText={"You have not sent or received messages yet"}
            />
          </Typography>
          {["admin", "owner"].includes(userType) && (
            <Typography
              variant={"body1"}
              color={"gray"}
              sx={{ margin: "auto", textAlign: "center", marginTop: "20px" }}
            >
              <Editable
                translationKey={`messages:empty:owner:content${++l}`}
                defaultText={
                  "You can send messages to subscribers to your properties from you profile page."
                }
              />
            </Typography>
          )}
          {["agent", "admin"].includes(userType) && (
            <Typography
              variant={"body1"}
              color={"gray"}
              sx={{ margin: "auto", textAlign: "center", marginTop: "20px" }}
            >
              <Editable
                translationKey={`messages:empty:agent:content${++k}`}
                defaultText={
                  "You can send messages to owners by visiting their property page and subscribing to their property."
                }
              />
            </Typography>
          )}
        </Box>
      )}
      {threads.length !== 0 && (
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              borderRight: "solid thin lightGray",
              height: "100%",
              overflowY: "auto",
              display: { xs: openDetails ? "none" : "block", sm: "block" },
            }}
          >
            <List>
              {threads.map((thread) => (
                <Link
                  onClick={() => switchThread(thread.id)}
                  sx={{ cursor: "pointer" }}
                  key={thread.id}
                >
                  <ListItem
                    alignItems="flex-start"
                    key={thread.subject}
                    sx={{
                      background:
                        thread.id === currentThread.id ? "#E7E7E7" : "white",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt="Avatar"
                        src={
                          thread.author_id === userId
                            ? thread.recipient_avatar
                            : thread.author_avatar
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        (thread.author_id === userId
                          ? thread.recipient_first_name
                          : thread.author_first_name) +
                        " " +
                        (thread.author_id === userId
                          ? thread.recipient_last_name
                          : thread.author_last_name)
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", marginRight: "10px" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {thread.subject}
                          </Typography>
                          <Tooltip
                            title="Be careful as it will delete the whole thread for the recipient too."
                            sx={{ position: "absolute", right: 0, top: "17px" }}
                          >
                            <IconButton onClick={() => deleteThread(thread.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Grid>
          {currentThread && (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                height: "100%",
                display: { xs: openDetails ? "block" : "none", sm: "block" },
              }}
            >
              <Thread thread={currentThread} saveMessage={saveMessage}></Thread>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              borderLeft: "solid thin lightGray",
              textAlign: "center",
              height: "100%",
              display: { xs: "none", sm: "block" },
            }}
          >
            <TextField
              name="notes"
              fullWidth
              id="notes"
              label={
                <Editable
                  translationKey={`messages:content${++i}`}
                  defaultText={"Enter your notes"}
                />
              }
              autoFocus
              multiline
              onChange={updateNotes}
              value={notes}
              rows={20}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ m: 4 }}
              onClick={saveNotes}
            >
              <Editable
                translationKey={`messages:content${++i}`}
                defaultText={"Update notes"}
              />
            </Button>
            {successMessage !== "" && (
              <Snackbar
                open={success}
                autoHideDuration={6000}
                onClose={dismissSuccess}
              >
                <Alert
                  onClose={dismissSuccess}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {successMessage}
                </Alert>
              </Snackbar>
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default Messages;
