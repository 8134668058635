import * as React from "react";
import { useState } from "react";
import { Validate } from "mui-validate";
import TextField from "@mui/material/TextField";
import Editable from "../../../core/Editable";

export default function LastNameField({ profile }) {
  const [value, setValue] = useState(localStorage.getItem("lastname"));
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Validate name="lastname">
      <TextField
        name="last_name"
        required
        fullWidth
        id="lastname"
        label={
          <Editable
            translationKey={`users:fields:lastname`}
            defaultText={"Last Name"}
          />
        }
        autoComplete="family-name"
        value={profile ? value : undefined}
        onChange={handleChange}
      />
    </Validate>
  );
}
