import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";
import { getGeoData } from "../../../core/client";
import { Validate } from "mui-validate";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";
import { BASE_LATITUDE, BASE_LONGITUDE } from "../../../core/default";
import Editable from "../../../core/Editable";

export default function AddressField({
  baseValue,
  defaultLongitude,
  defaultLatitude,
  mapCallback,
  setLongitude,
  setLatitude,
  required,
  city,
}) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [value, setValue] = React.useState();
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [showCoordinates, setShowCoordinates] = React.useState(false);
  const [showCity, setShowCity] = React.useState(false);
  const [cityValue, setCityValue] = React.useState(city);
  const [longitudeValue, setLongitudeValue] = React.useState(defaultLongitude);
  const [latitudeValue, setLatitudeValue] = React.useState(defaultLatitude);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        getGeoData("/v1/geocode/autocomplete", { text: request["input"] }).then(
          (response) => {
            let features = [];
            for (const feature of response["data"]["features"]) {
              features.push(feature["properties"]);
            }
            features.push({
              address_line1: request["input"],
              address_line2: "",
            });
            callback(features);
          }
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    setCityValue(city);
    setLongitudeValue(defaultLongitude);
    setLatitudeValue(defaultLatitude);
  }, [defaultLongitude, defaultLatitude, city]);

  const customRequired = (validationValue) => {
    if (!required) {
      return true;
    }
    return validationValue || value || baseValue;
  };

  const handleAddressSelect = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    if (!newValue["lon"]) {
      if (newValue["address_line1"].trim() !== baseValue) {
        newValue["lon"] = BASE_LONGITUDE;
        newValue["lat"] = BASE_LATITUDE;
        setCityValue("");
      } else {
        newValue["lon"] = defaultLongitude;
        newValue["lat"] = defaultLatitude;
        setCityValue(city);
      }
      setShowCoordinates(true);
      setShowCity(true);
      setErrorMessage(
        "We could not find your address but you can zoom in and click on the map to locate it, or fill in the coordinates."
      );
    } else {
      setCityValue(newValue["city"]);
      setShowCity(false);
      setShowCoordinates(false);
      setErrorMessage("");
    }
    mapCallback(newValue["lon"], newValue["lat"]);
    setValue(newValue);
  };

  const handleCityChange = (event) => {
    let newCity = event.target.value;
    setCityValue(newCity);
  };

  const handleLongitudeChange = (event) => {
    let longitude = event.target.value;
    setLongitudeValue(longitude);
    setLongitude(longitude);
    mapCallback(longitude, latitudeValue);
  };

  const handleLatitudeChange = (event) => {
    let latitude = event.target.value;
    setLatitudeValue(latitude);
    setLatitude(latitude);
    mapCallback(longitudeValue, latitude);
  };
  let i = 0;
  let j = 0;
  return (
    <Box>
      {errorMessage !== "" && (
        <Alert sx={{ mt: 1, mb: 2 }} severity="error">
          <Editable
            translationKey={`property:address:error:content${++j}`}
            defaultText={
              "We could not find your address but you can zoom in and click on the map " +
              "to locate it, or fill in the coordinates."
            }
          />
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Validate
            name="address"
            custom={[customRequired, "Please fill in your address."]}
          >
            <Autocomplete
              id="address-autocomplete"
              fullWidth
              options={options}
              noOptionsText="No address found (you need to pick an address to create your property)"
              filterOptions={(x) => x}
              includeInputInList
              inputValue={inputValue}
              onChange={handleAddressSelect}
              getOptionLabel={(feature) =>
                `${feature["address_line1"]} ${feature["address_line2"]}`
              }
              isOptionEqualToValue={(option, value) =>
                option["place_id"] === value["place_id"]
              }
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              defaultValue={
                baseValue
                  ? { address_line1: baseValue.trim(), address_line2: "" }
                  : undefined
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="address"
                  id="address"
                  label={
                    <Editable
                      translationKey={`property:address:content${++i}`}
                      defaultText={"Address"}
                    />
                  }
                />
              )}
            />
          </Validate>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="city"
            fullWidth
            sx={{ display: !showCity ? "none" : "default" }}
            id="city"
            label={
              <Editable
                translationKey={`property:address:content${++i}`}
                defaultText={"City"}
              />
            }
            onChange={handleCityChange}
            value={cityValue}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="longitude"
            fullWidth
            sx={{ display: !showCoordinates ? "none" : "default" }}
            id="longitude"
            label={
              <Editable
                translationKey={`property:address:content${++i}`}
                defaultText={"Longitude"}
              />
            }
            onChange={handleLongitudeChange}
            value={longitudeValue}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="latitude"
            fullWidth
            sx={{ display: !showCoordinates ? "none" : "default" }}
            id="latitude"
            label={
              <Editable
                translationKey={`property:address:content${++i}`}
                defaultText={"Latitude"}
              />
            }
            onChange={handleLatitudeChange}
            value={latitudeValue}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
